import React, { useEffect, useState } from "react";
import { Container, Typography, Card, Box, Grid, CardContent, IconButton, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import Axios from "../../../config/axios";
import { UrlConstant } from "../../../commom/UrlConstant";

const UpcomingStockDetails: React.FC = () => {
    const location = useLocation();
    const topUp = location.state;
    const [topupDetails, setTopupDetails] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchTopupDetails = async () => {
            if (topUp?.topUpId) {
                try {
                    const apiUrl = UrlConstant.AutoTopup.getUpcomingTopupDetails.replace("{0}", topUp.topUpId);
                    const response = await Axios.get(apiUrl);
                    setTopupDetails(response.data.data);
                } catch (error) {
                    console.error("Error fetching top-up details:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.error("No topUpId found in location state.");
                setLoading(false);
            }
        };

        fetchTopupDetails();
    }, [topUp]);

    // Loading spinner overlay
    if (loading) {
        return (
            <Container
                maxWidth={false}
                style={{
                    height: "100vh", // Full height
                    width: "100%", // Full width
                    position: "relative", // Set position to relative to enable absolute positioning of spinner
                }}
            >
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%" // Full screen overlay
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                        backgroundColor: "bg-color", // Optional: dim background
                        zIndex: 10, // Ensure spinner is above other content
                    }}
                >
                    <CircularProgress style={{ color: "#F2674A" }} />
                </Box>
            </Container>
        );
    }

    // No data available
    if (!topupDetails || topupDetails.length === 0) {
        return (
            <Container>
                <Typography>No data available</Typography>
            </Container>
        );
    }

    return (
        <Container
            maxWidth={false}
            style={{
                maxHeight: "61.8vh",
                padding: "30px",
                overflow: "auto",
                height: '100vh',
                width: '100%',
                position: 'relative',
            }}
        >
            <Box display="flex" gap="20px" flexWrap="wrap" justifyContent="space-between">
                {topupDetails.map((item, index) => {
                    const scheduledDate = new Date(topUp.scheduled_date);
                    return (
                        <Box key={index} width="48%" overflow="auto">
                            <Card
                                sx={{
                                    marginBottom: "20px",
                                    padding: "20px 30px",
                                    height: "113px",
                                    border: "1px solid #FFFFFF2A",
                                    bgcolor: "#FFFFFF2A",
                                    borderRadius: "20px",
                                }}
                            >
                                <IconButton sx={{ width: "100%", cursor: "pointer", padding: "0px", margin: "0px" }}>
                                    <CardContent sx={{ width: "100%", padding: "0px" }}>
                                        <Grid width="100%" container alignItems="center">
                                            {/* Left Section - Date */}
                                            <Grid
                                                item
                                                sx={{
                                                    marginRight: "10px",
                                                    height: "73px",
                                                    padding: "14px 0px",
                                                    textAlign: "center",
                                                    width: "73px",
                                                    borderRadius: "50px",
                                                    bgcolor: "#FFFFFF1A",
                                                }}
                                            >
                                                <Typography fontSize="24px" lineHeight="24px" fontWeight="550" sx={{ color: "#F2674A" }}>
                                                    {scheduledDate ? scheduledDate.getDate() : "N/A"}
                                                </Typography>
                                                <Typography sx={{ color: "white" }}>
                                                    {scheduledDate ? scheduledDate.toLocaleString("default", { month: "short" }) : "N/A"}
                                                </Typography>
                                            </Grid>
                                            {/* Middle Section - Name and Details */}
                                            <Grid item xs={8} sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                                <Typography fontSize="24px" className="text-white" fontWeight="550">
                                                    {item?.topUp?.name || "No Name"}
                                                </Typography>
                                                <Typography fontSize="16px" className="text-white">
                                                    Topup {item?.transactionNumber || "Unknown"} of{" "}
                                                    {item?.topUp?.total_installments || "Unknown"} |{" "}
                                                    <span style={{ color: '#4A7AF2' }}>
                                                        {scheduledDate.toLocaleDateString("en-US", { weekday: "long" })}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            {/* Right Section - Amount */}
                                            <Grid
                                                item
                                                xs={2}
                                                color="#4EDF6D"
                                                textAlign="end"
                                                fontWeight="bold"
                                                sx={{ fontSize: "28px" }}
                                            >
                                                {item?.amount ? `$${item.amount.toFixed(2)}` : "N/A"}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </IconButton>
                            </Card>
                        </Box>
                    );
                })}
            </Box>
        </Container>
    );
};

export default UpcomingStockDetails;
