import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { login, logout } from "../../Redux/authSlice";
import { getUserFromLocalStorage } from "../../Utils/utils";
import { UrlConstant } from "../../commom/UrlConstant";
import Axios from "../../config/axios";
import "../../style/style.css";
import LeftSide from "../common/Layout/LeftSide";
import Successpopup from "../common/SharedComponent/Successpopup";

function Loginwithpin() {
  const user = getUserFromLocalStorage();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>(user?.email || "");
  const [otp, setOtp] = useState<string>(""); // OTP input value
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility




  const handleOtpChange = (value: string) => {
    setOtp(value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (otp.length === 4) {
      loginByPin();
    } else {
      // Swal.fire("Please enter a 4-digit PIN");
      setheading('Authantication Faild')
      setShowPopup(true)
      setmsg('Please enter a 4-digit PIN')
      // alert("H")
    }
  };

  const loginByPin = async () => {
    const payLoad = { pin: otp };

    try {
      const response = await Axios.post(UrlConstant.Auth.LoginWithPin, payLoad);
      const resData = response.data;

      if (resData.result === true) {
        sessionStorage.setItem("pinVerified", "true");
        dispatch(login(resData.data));
        setTimeout(() => {
          navigate("/", { state: resData.data });
        }, 1000);
      } else {
        // Swal.fire(resData.msg ?? "Invalid PIN");
        setheading("Authantication Faild")
        setShowPopup(true)
        setmsg(resData.msg)
        setOtp(""); // Reset OTP on failure
      }
    } catch (error: any) {
      // Swal.fire(error.response?.data?.msg ?? "Authentication Failed");
      setheading('Authantication Faild')
      setShowPopup(true)
      setmsg(error.response?.data?.msg)
      setOtp(""); // Reset OTP on error
    }
  };

  const onForgotPin = () => {
    dispatch(logout());
    navigate("/login");
  };
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };
  return (
    <Container
      maxWidth={false}
      className="container_new"
      sx={{ maxWidth: "100%", margin: "0px" }}
    >
      {/* <LeftSide /> */}

      <RightSection className="RightSection bg-color">
        <Typography width="100%">
          <Typography
            className="Typography fw-700 mb-10 fs-24 heading"
            width="100%"
          >
            Welcome back to WolfPack
          </Typography>
          <Typography
            className="Typography fs-18 mb-50 text-secondary"
            variant="body1"
            sx={{ mb: { xs: 10, sm: 10 } }}
          >
            Your world awaits—let’s pick up where you left off.
          </Typography>
        </Typography>

        <Box width="100%">
          <FormControl fullWidth margin="normal" className="mb-30">
            <TextField
              name="EmailId"
              label={
                <span>
                  Email ID{" "}
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                </span>
              }
              variant="outlined"
              className="text-secondary "
              sx={{
                " & .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${!email ? "red !important" : ""} `,
                },
              }}
              slotProps={{
                input: {
                  startAdornment: (
                    <IconButton sx={{ background: "transparent" }}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 20.5C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5H7Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                  ),
                },
              }}
              InputLabelProps={{
                shrink: false,
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          <FormControl fullWidth margin="normal" className="mb-20">
            <FormLabel className="text-secondary">
              <span>
                Pin{" "}
                <sup>
                  <i
                    className="fa-solid fa-star-of-life"
                    style={{ color: "#fa0000", fontSize: "0.5rem" }}
                  ></i>
                </sup>
              </span>
            </FormLabel>
            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <OTPInput
                value={otp}
                onChange={handleOtpChange}
                numInputs={4}
                containerStyle={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
                inputStyle={{
                  width: "100%",
                }}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="otp"
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      } else if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== "Backspace"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Box>
            <Typography
              onClick={onForgotPin}
              className="mb-10 link"
              style={{ textAlign: "end", marginTop: "10px", cursor: "pointer" }}
            >
              Forgot PIN?
            </Typography>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="btn-primary fw-700 btn mb-10"
            sx={{ fontSize: "16px", paddingY: "12px" }}
            endIcon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.76001 12.0601H19.93"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            Log In
          </Button>
        </Box>

        <Typography onClick={onForgotPin} className="link">
          Login with Password
        </Typography>
      </RightSection>
      {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}
    </Container>
  );
}

const RightSection = styled(Box)(({ theme }) => ({
  width: "100%",
  // padding: "4rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "70px 120px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("sm")]: {
    padding: "40px 40px",
  },
}));

export default Loginwithpin;
