import React, { useEffect, useState } from 'react'
import { Container, Typography, Box, Card, Divider, Button, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { AddOutlined, StarBorder, StarOutlineRounded, StarRateOutlined, StarRounded, StartRounded } from '@mui/icons-material'
import { getUserFromLocalStorage } from '../../../Utils/utils'
import Axios from '../../../config/axios'
import { UrlConstant } from '../../../commom/UrlConstant'
import { usePlaidLink } from 'react-plaid-link'
import Swal from 'sweetalert2'
import Successpopup from '../SharedComponent/Successpopup'

function LinkBankAccount() {
    const navigation = useNavigate()
    const user = getUserFromLocalStorage();

    const [loading, setLoading] = useState<boolean>()
    const [approvedAccounts, setApprovedAccounts] = useState([]);
    const [pendingAccounts, setPendingAccounts] = useState([]);
    const [cancelledAccounts, setCancelledAccounts] = useState([]);
    const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
    const [msg, setmsg] = useState(''); // State for controlling popup visibility
    const [Success, setSuccess] = useState(false); // State for controlling popup visibility
    const [heading, setheading] = useState(''); // State for controlling popup visibility





    const [linkToken, setLinkToken] = useState("");

    const fetchLinkToken = async () => {
        try {
            const accountId = user?.tradingblock_application_id;
            const response = await Axios.get(
                UrlConstant.BankAccount.CreateLinkToken.replace("{0}", accountId)
            );
            const resData = response.data;
            if (resData.status === true) {
                setLinkToken(resData.data.result.Payload.linkToken);
            } else {
                throw new Error("Failed to fetch link token");
            }
        } catch (error) {
            console.error("Error fetching link token:", error);
            // Swal.fire("Error", "Failed to generate link token. Please try again.", "error");
            setheading('Error')
            setShowPopup(true)
            setmsg('Failed to generate link token. Please try again.')

        } finally {
        }
    };

    const onSetDefault = async (id) => {
        try {
            const response = await Axios.get(
                UrlConstant.BankAccount.SetDefaultAccount.replace("{0}", id)
            );
            const resData = response.data;
            if (resData.result === true) {
                getLinkedAccounts();
                // Swal.fire("Success", `${resData.data.bankName} Added as a Defult Account.`, "success");
                setheading("Success")
                setSuccess(true)
                setShowPopup(true)
                setmsg(`${resData.data.bankName} Added as a Defult Account.`)

            } else {
                throw new Error("Failed to fetch link token");
            }
        } catch (error) {
            console.error("Erro in Set Default:", { error });
            // Swal.fire("Error", "Failed to set default account.", "error");
            setheading("Error")
            setShowPopup(true)
            setmsg('Failed to set default account')

        } finally {
        }
    };

    useEffect(() => {
        fetchLinkToken(); // Fetch link token on component mount
    }, []);

    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: async (public_token, metadata: any) => {
            console.log("success", public_token, metadata);
            const data = {
                accountId: user?.tradingblock_application_id,
                bankAccountType: metadata?.account?.subtype,
                bankName: metadata?.institution?.name,
                nickName: metadata?.account?.name,
                plaidAccountId: metadata?.account?.id,
                publicToken: public_token,
            };
            try {
                const response = await Axios.post(
                    UrlConstant.BankAccount.CompleteLink,
                    data
                );
                const resData = response.data;
                if (resData.status === true) {
                    getLinkedAccounts()
                    // Swal.fire({
                    //     title: "Linked!",
                    //     text: "Your account was linked successfully!",
                    //     icon: "success",
                    // });
                    setheading('Linked!')
                    setSuccess(true)
                    setShowPopup(true)
                    setmsg('Your account was linked successfully!')
                }
            } catch (error: any) {
                console.error("Error linking account:", error);
                // Swal.fire("Error", error.response?.data?.message[0] ?? "Linking failed", "error");
                setheading('Error')
                setShowPopup(true)
                setmsg(error.response?.data?.message[0] ?? "Linking failed")

            }
        },
        onExit: (error, metadata) => {
            if (error?.error_code === "INVALID_LINK_TOKEN") {
                // Swal.fire("Error", "Your session expired. Generating a new link token.", "info");
                setheading('Error')
                setShowPopup(true)
                setmsg('Your session expired. Generating a new link token.')
                fetchLinkToken(); // Re-fetch the link token if it has expired
            }
        },
    });


    const getLinkedAccounts = async () => {
        setLoading(true);
        const data = {
            "accountId": user?.tradingblock_application_id,
        };

        try {
            const response = await Axios.post(
                UrlConstant.BankAccount.LinkedList,
                data
            );
            const resData = response.data;

            if (resData.status === true) {
                const accountList = resData.data ?? [];

                // Filter data based on the status
                const approvedAccounts = accountList.filter(
                    (account) => account.status === "Approved"
                );

                const pendingAccounts = accountList.filter(
                    (account) => account.status === "Pending"
                );

                const cancelledAccounts = accountList.filter(
                    (account) => account.status === "Cancelled"
                );

                // Set the states accordingly
                setApprovedAccounts(approvedAccounts);
                setPendingAccounts(pendingAccounts);
                setCancelledAccounts(cancelledAccounts);
            }
        } catch (error) {
            console.error("Error: ", error);
            // Swal.fire("Error", "An error occurred while fetching linked accounts.", "error");
            setheading('Error')
            setShowPopup(true)
            setmsg('An error occurred while fetching linked accounts.')

        } finally {
            setLoading(false);
        }
    };

    const onUnlinkAccount = async (account) => {
        const data = {
            "accountId": user?.tradingblock_application_id,
            "relationshipId": account,
        };
        try {
            const response = await Axios.post(
                UrlConstant.BankAccount.UnlinkAccount,
                data
            );
            const resData = response.data;
            if (resData.status === true) {
                // Swal.fire("Success", "Account unlinked successfully.", "success");
                setheading('Success')
                setSuccess(true)
                setShowPopup(true)
                setmsg('Account unlinked successfully.')
                getLinkedAccounts(); // Refresh linked accounts
            } else {
                // Swal.fire("Error", "Failed to unlink account.", "error");
                setheading('Error')
                setShowPopup(true)
                setmsg("Failed to unlink account.")
            }
        } catch (error: any) {
            console.error("ERRR : ", error);
            // Swal.fire("Error", error.response?.data?.message[0] ?? "An error occurred while unlinking the account.", "error");
            setheading('Error')
            setShowPopup(true)
            setmsg(error.response?.data?.message[0] ?? "An error occurred while unlinking the account.")

        } finally {
        }
    };


    useEffect(() => {
        getLinkedAccounts()
    }, [])
    const closePopup = () => {
        setShowPopup(false); // Function to close the popup
    };

    return (
        <Box sx={{ minHeight: 'calc(100vh - 71px - 143px )', display: 'flex', flexDirection: 'column' }} className='bg-dak'>
            <Box sx={{ padding: '30px', flexGrow: 1 }}>
                <Typography
                    className="text-secondary"
                    sx={{ marginBottom: "20px" }}
                >
                    {" "}
                    <span
                        onClick={() => navigation(-1)}
                        style={{ color: "#4A7AF2", cursor: "pointer" }}
                    >
                        More Options{" "}
                    </span>{" "}
                    / LinkBankAccount
                </Typography>

                {loading && (
                    <Box
                        position="fixed"
                        top={0}
                        left={0}
                        width="100vw"
                        height="100vh"
                        bgcolor="rgba(0, 0, 0, 0.5)" // Semi-transparent black background
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            backdropFilter: "blur(5px)", // Blur effect
                            zIndex: 9999, // Ensures the loader stays above all elements
                        }}
                    >
                        <CircularProgress color="primary" />
                    </Box>
                )}

                {/* ----------------------------------------------------------Link Bank Account ---------------------------------------------------- */}
                <Box width='100%' mb='50px'>
                    <Box display='flex' alignItems='center' mb='20px' justifyContent='space-between'>
                        <Typography variant='h5' className='text-white'>Linked Bank Account</Typography>
                        <Button
                            sx={{ padding: "5px 10px" }}
                            className="btn-primary btn"
                            startIcon={<AddOutlined />}
                            onClick={() => (open())} // Fetch new token if the current one is invalid
                            disabled={!ready || loading}
                        >
                            {loading ? "Loading..." : "Add Bank Account"}
                        </Button>
                        {/* <Button sx={{ padding: '5px 10px' }} className='btn-primary btn'><AddOutlined /> Add Bank Account</Button> */}
                    </Box>
                    <Box display='flex' style={{ gap: '20px' }}>
                        {approvedAccounts.length ?
                            approvedAccounts.map((account: any) => {
                                return (
                                    <Box width='49%'>
                                        <Card sx={{ height: "113px", width: '100% !important' }} className='card'>
                                            <Box display='flex' height='100%' width='100%'>

                                                <Box width='15%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                                                    {account.isDefault ?
                                                        <StarRounded sx={{ height: '40px', width: '40px', color: '#F2674A' }} />
                                                        :
                                                        <StarOutlineRounded sx={{ height: '40px', width: '40px', cursor: "pointer" }} onClick={() => onSetDefault(account.id)} />
                                                    }
                                                </Box>
                                                <Divider sx={{ border: '1px solid #FFFFFF33' }}></Divider>
                                                <Box display='flex' width='85%' padding='20px 25px' alignItems='center' justifyContent='space-between'>
                                                    <Box display='flex' gap='20px' alignItems='center'>
                                                        <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="36.5" cy="36.5" r="36.5" fill="#4A7AF2" fill-opacity="0.1" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.131 49.1642V31.7734H25.8166V49.1642H23.131Z" fill="white" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1056 53.0625H54.9137V55.6711H19.1056V53.0625Z" fill="white" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8944 47.8477H53.1217V50.4563H20.8944V47.8477Z" fill="white" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6603 49.1642V31.7734H38.3459V49.1642H35.6603Z" fill="white" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.1958 49.1681V31.7773H50.8814V49.1681H48.1958Z" fill="white" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.0064 21.1826L23.3623 30.4597H50.6506L37.0064 21.1826ZM36.0824 18.6267C36.6373 18.2494 37.3756 18.2494 37.9305 18.6267L54.9827 30.2209C56.2732 31.0985 55.634 33.0683 54.0586 33.0683H19.9544C18.3789 33.0683 17.7396 31.0985 19.0303 30.2209L36.0824 18.6267Z" fill="white" />
                                                        </svg>
                                                        <Box>
                                                            <Typography variant='h5' className='text-white'>{account.bankName}</Typography>
                                                            <Typography variant='h6' sx={{ color: '#4A7AF2' }}>{account.bankAccountType}</Typography>
                                                        </Box>
                                                    </Box>
                                                    {
                                                        account.status !== "Cancelled" ?
                                                            <Button sx={{ bgcolor: "#FF2A2A", borderRadius: '50px', textTransform: 'none', padding: '10px 20px', '.hover': { bgcolor: "#FF2A2A" } }} className='text-white' onClick={() => { onUnlinkAccount(account.id) }} >
                                                                Unlink Account
                                                            </Button>
                                                            : null
                                                    }
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Box >
                                )
                            })
                            : <Typography className='text-white' >No Linked Account</Typography>}
                    </Box>
                </Box>
                {/* ----------------------------------------------------------Pending Bank Account ---------------------------------------------------- */}

                {pendingAccounts.length ?
                    <Box width='100%'>
                        <Typography variant='h5' mb='20px' className='text-white'>Pending Accounts</Typography>

                        <Box display='flex' style={{ gap: '20px' }}>
                            {pendingAccounts.map((account: any) => {
                                return (
                                    <Box width='49%'>
                                        <Card sx={{ height: "113px", width: '100% !important' }} className='card'>
                                            <Box display='flex' width='100%' padding='20px 25px' alignItems='center' justifyContent='space-between'>
                                                <Box display='flex' gap='20px' alignItems='center'>
                                                    <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="36.5" cy="36.5" r="36.5" fill="#4A7AF2" fill-opacity="0.1" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.131 49.1642V31.7734H25.8166V49.1642H23.131Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1056 53.0625H54.9137V55.6711H19.1056V53.0625Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8944 47.8477H53.1217V50.4563H20.8944V47.8477Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6603 49.1642V31.7734H38.3459V49.1642H35.6603Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M48.1958 49.1681V31.7773H50.8814V49.1681H48.1958Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M37.0064 21.1826L23.3623 30.4597H50.6506L37.0064 21.1826ZM36.0824 18.6267C36.6373 18.2494 37.3756 18.2494 37.9305 18.6267L54.9827 30.2209C56.2732 31.0985 55.634 33.0683 54.0586 33.0683H19.9544C18.3789 33.0683 17.7396 31.0985 19.0303 30.2209L36.0824 18.6267Z" fill="white" />
                                                    </svg>
                                                    <Box>
                                                        <Typography variant='h5' className='text-white'>{account.bankName}</Typography>
                                                        <Typography variant='h6' sx={{ color: '#4A7AF2' }}>{account.bankAccountType}</Typography>
                                                    </Box>
                                                </Box>
                                                {
                                                    account.status !== "Cancelled" ?
                                                        <Button sx={{ bgcolor: "#FF2A2A", borderRadius: '50px', textTransform: 'none', padding: '10px 20px', '.hover': { bgcolor: "#FF2A2A" } }} className='text-white' onClick={() => { onUnlinkAccount(account.id) }} >

                                                            Unlink Account
                                                        </Button>
                                                        : null
                                                }
                                            </Box>
                                        </Card>
                                    </Box >
                                )
                            })}
                        </Box>
                    </Box>
                    :
                    null}
                {/* ----------------------------------------------------------Cancel Bank Account ---------------------------------------------------- */}

                {cancelledAccounts.length ?
                    <Box width='100%'>
                        <Typography variant='h5' mt='50px' mb='20px' className='text-white'>Cancelled Accounts</Typography>

                        <Box display='flex' width='100%' flexWrap='wrap' style={{ gap: '20px' }}>
                            {cancelledAccounts.map((account: any) => {
                                return (
                                    <Box width='49%'>
                                        <Card sx={{ height: "113px", width: '100% !important' }} className='card'>
                                            <Box display='flex' width='100%' padding='20px 25px' alignItems='center' justifyContent='space-between'>
                                                <Box display='flex' gap='20px' alignItems='center'>
                                                    <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="36.5" cy="36.5" r="36.5" fill="#4A7AF2" fill-opacity="0.1" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.131 49.1642V31.7734H25.8166V49.1642H23.131Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1056 53.0625H54.9137V55.6711H19.1056V53.0625Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8944 47.8477H53.1217V50.4563H20.8944V47.8477Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6603 49.1642V31.7734H38.3459V49.1642H35.6603Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M48.1958 49.1681V31.7773H50.8814V49.1681H48.1958Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M37.0064 21.1826L23.3623 30.4597H50.6506L37.0064 21.1826ZM36.0824 18.6267C36.6373 18.2494 37.3756 18.2494 37.9305 18.6267L54.9827 30.2209C56.2732 31.0985 55.634 33.0683 54.0586 33.0683H19.9544C18.3789 33.0683 17.7396 31.0985 19.0303 30.2209L36.0824 18.6267Z" fill="white" />
                                                    </svg>
                                                    <Box>
                                                        <Typography variant='h5' className='text-white'>{account.bankName}</Typography>
                                                        <Typography variant='h6' sx={{ color: '#4A7AF2' }}>{account.bankAccountType}</Typography>
                                                    </Box>
                                                </Box>
                                                <Button
                                                    sx={{ bgcolor: "#4A7AF2", borderRadius: '50px', textTransform: 'none', padding: '10px 20px', '.hover': { bgcolor: "#FF2A2A" } }}
                                                    className='text-white'

                                                    onClick={() => (open())} // Fetch new token if the current one is invalid
                                                    disabled={!ready || loading}>
                                                    Link Account
                                                </Button>
                                            </Box>
                                        </Card>
                                    </Box >
                                )
                            })}
                        </Box>
                    </Box>
                    :
                    null}
            </Box>
            <Box className='footer' width='100%'>
                <Divider sx={{ border: '1px solid #FFFFFF33' }} />
                <Typography my='20px' className='text-white' textAlign='center'>Securities Products and Services are provided by ABC Inc. Member FINRA | SIPC.</Typography>
            </Box>
            {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}


        </Box>
    )
}

export default LinkBankAccount
