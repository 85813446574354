import { KeyboardArrowRight } from '@mui/icons-material'
import { Paper, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Avatar, Typography, IconButton, CircularProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Axios from '../../../config/axios'
import { UrlConstant } from '../../../commom/UrlConstant'
import { getUserFromLocalStorage } from '../../../Utils/utils'
interface StockData {
    company: string;
    symbol: string;
    price: number;
    onedprice: number;
    onedpercentage: number;
    Buy_sell: string;
}
interface userProfileProps {
    type: 'Stock' | 'ETF';
    id: string;
}
const UserStocks: React.FC<any> = ({ type, id }) => {
    const [data, setData] = useState<StockData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        const fetchData = async () => {
            if (!id) {
                console.error("User ID is undefined.");
                setLoading(false);
                return;
            }
            setData([]);
            setLoading(true);

            try {
                // Determine the URL based on the 'type' ('Stock' or 'ETF')
                const url = type === 'Stock'
                    ? UrlConstant.Profile.getTopPickStock.replace("{0}", id)
                    : UrlConstant.Profile.getTopPickETF.replace("{0}", id);

                // Fetch data using Axios
                const response = await Axios.post(url);

                // For the 'Stock' type, handle response based on the expected data structure
                const newArr = [...response.data.data.mostPopular.buy, ...response.data.data.mostPopular.sell];
                console.log("New Array", newArr);

                // Map the data to the required format
                const transformedData: StockData[] = newArr.map((stock: any) => {
                    return {
                        company: stock.displayName,
                        symbol: stock.branding?.logo_url || "", // Default to empty string if logo is missing
                        price: parseFloat(stock.stockPrice),
                        onedprice: stock.changeAmount,
                        onedpercentage: stock.changePercentage,
                        Buy_sell: stock.recommedationType === 'BUY' ? 'Buy' : 'Sell',
                    };
                });

                // Set the transformed data to state
                setData(transformedData);
                setLoading(false);

            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id, type]);
    return (
        <>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="calc( 100vh - 71px - 143px) ">
                    <CircularProgress style={{ color: '#FFA500' }} /> {/* Orange spinner */}
                </Box>
            ) : data.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="calc( 100vh - 71px - 143px) ">
                    <Typography variant="h6" style={{ color: 'white' }}>No Data Available</Typography>
                </Box>
            )
                : (
                    <TableContainer component={Paper}
                        style={{
                            height: 'calc(100vh - 131px - 240px)',
                            color: 'white', background: 'transparent',
                            borderRadius: '20px', border: '2px solid #FFFFFF1A',
                            position: 'relative'
                        }}
                    >
                        <Table stickyHeader sx={{ height: '100%' }}>
                            <TableHead >
                                <TableRow sx={{ '& th': { height: '20%', borderColor: '#FFFFFF1A !important', backgroundColor: '#FFFFFF1A !important', zIndex: '99' } }}>
                                    <TableCell style={{ fontSize: '12px', color: 'white' }}>Company</TableCell>
                                    <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>Market Price</TableCell>
                                    <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>1D Price</TableCell>
                                    <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>1D Percentage</TableCell>
                                    <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>Buy/Sell</TableCell>
                                    <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ height: '80%', overflow: 'hidden' }}>
                                {data.map((row, index) => (
                                    <TableRow key={index} sx={{ '& td': { borderBottom: 'none !important' } }}>
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <Avatar
                                                    src={row.symbol || ''} // Provide a fallback image
                                                    alt={row.company}
                                                    style={{ marginRight: '8px', height: '36px', width: '36px' }}
                                                />
                                                <Typography style={{ fontSize: '14px', color: 'white' }}>{row.company}</Typography>
                                            </Box>
                                        </TableCell>

                                        <TableCell sx={{ fontSize: "16px", color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>
                                            {`$ ${row.price.toFixed(2)}`}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "16px", color: row.onedprice > 0 ? '#4EDF6D' : '#FF2A2A', textWrap: 'nowrap', textAlign: 'end' }}>{row.onedprice > 0 ? "$" : "-$"}{Math.abs(row.onedprice)?.toFixed(2)}</TableCell>
                                        <TableCell sx={{ fontSize: "16px", color: row.onedpercentage > 0 ? '#4EDF6D' : '#FF2A2A', textWrap: 'nowrap', textAlign: 'end' }}>{`(${row.onedpercentage}%)`}</TableCell>
                                        <TableCell sx={{ fontSize: "16px", textAlign: 'end' }}>
                                            <Button sx={{
                                                border: 'none',
                                                backgroundColor: row.Buy_sell === 'Buy' ? '#4EDF6D33' : '#FF2A2A33',
                                                color: row.Buy_sell === 'Buy' ? '#4EDF6D' : '#FF2A2A',
                                                padding: '5px 24px',
                                                borderRadius: '50px'
                                            }}>
                                                {row.Buy_sell}
                                            </Button>
                                        </TableCell>
                                        <TableCell style={{ fontSize: "16px", color: 'white', textAlign: 'end' }}>
                                            <IconButton sx={{ padding: '8px', borderRadius: '10px', backgroundColor: "#4A7AF2" }}>
                                                <KeyboardArrowRight />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
        </>
    )
}

export default UserStocks
