import React from 'react'

function Correcticon() {
  return (
    <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3874 28.0003C19.2287 28.0003 24.7747 22.6036 24.7747 15.9464C24.7747 9.28927 19.2287 3.89258 12.3874 3.89258C5.54601 3.89258 0 9.28927 0 15.9464C0 22.6036 5.54601 28.0003 12.3874 28.0003Z" fill="#4EDF6D" fill-opacity="0.2"/>
    <path d="M12.5274 25.2628L12.3703 25.0023C9.97026 21.0233 3.59258 12.5792 3.52816 12.4944L3.43616 12.3727L5.609 10.2832L12.487 14.9566C16.8176 9.48835 20.8578 5.73248 23.4931 3.5392C26.376 1.13997 28.2526 0.0354406 28.2715 0.0248563L28.3142 0H31.9999L31.6479 0.305102C22.5932 8.15294 12.7789 24.8328 12.6811 25.0003L12.5274 25.2628Z" fill="#4EDF6D"/>
    </svg>
    
  )
}

export default Correcticon
