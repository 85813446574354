import { Ba } from "react-flags-select";
import ReferFriend from "../components/common/MainComponents/ReferFriend";


export const EnvironmentConfig = {
  DEV: 'https://devapi.wolfpack.com', // Development API
  STAGE: 'https://stageapi.wolfpack.com', // Staging API
  PROD: 'https://api.wolfpack.com', // Production API
};
export let REVENUECAT_BILLING_PUBLIC_API_KEY = 'rcb_sb_lxVgrwmIvRsrFeRbDOFuLpGfG'

export function getBaseUrl(): string {
  const environment = (localStorage.getItem('selectedEnvironment') || 'DEV').toUpperCase();
  if (environment === 'DEV') {
    REVENUECAT_BILLING_PUBLIC_API_KEY = 'rcb_sb_lxVgrwmIvRsrFeRbDOFuLpGfG'
  } else {
    REVENUECAT_BILLING_PUBLIC_API_KEY = 'rcb_VkJLMobzrkunUmDmaXTDWCjMnSZg'
  }
  return EnvironmentConfig[environment] || EnvironmentConfig.DEV; // Default to DEV

}


// Dynamic BASE_URL
export const BASE_URL = getBaseUrl();

export const UrlConstant = {
  Common: {
    CountryList: `${BASE_URL}/api/v1/countryDetails`,
    StateList: `${BASE_URL}/api/v1/trading-block/get-states`
  },

  Auth: {
    SignUp: `${BASE_URL}/api/v1/signup`,
    VerifyEmailByOTP: `${BASE_URL}/api/v1/user/verifyEmail`,
    ResendOTP: `${BASE_URL}/api/v1/user/resendOTPVerifyEmail`,
    Login: `${BASE_URL}/api/v1/login`,
    ForgotPassword: `${BASE_URL}/api/v1/forgotPassword`,
    ResetPassword: `${BASE_URL}/api/v1/resetPassword`,
    AcceptDisclosure: `${BASE_URL}/api/v1/user/acceptProductDisclosure`,
    CreatePin: `${BASE_URL}/api/v1/user/createPin`,
    LoginWithPin: `${BASE_URL}/api/v1/user/loginWithPin`,
    UploadProfilePic: `${BASE_URL}/api/v1/user/updateUserProfilePic`,
  },
  TradingAccount: {
    GetSecurityQuestion: `${BASE_URL}/api/v1/trading-block/getSecurityQuestions`,
    CreateUser: `${BASE_URL}/api/v1/trading-block/create-user`,
    CheckAccountStatus: `${BASE_URL}/api/v1/trading-block/get-account-status`,
    CreateAccount: `${BASE_URL}/api/v1/trading-block/create-account`,
    GetSectionAnswer: `${BASE_URL}/api/v1/trading-block/get-answers`,
    SubmitAnswer: `${BASE_URL}/api/v1/trading-block/submit-answer`,
    SubmitForm: `${BASE_URL}/api/v1/trading-block/submit-form`,
    GetInvestigation: `${BASE_URL}/api/v1/trading-block/get-investigation`,
    SubmitDocument: `${BASE_URL}/api/v1/trading-block/submit-document`,
  },
  WachList: {
    GetWatchList: `${BASE_URL}/api/v1/watchlist/list`,
    CreateWatchList: `${BASE_URL}/api/v1/watchlist/create`,
    RemoveWatchList: `${BASE_URL}/api/v1/watchlist/remove/{0}`,
  },
  AiPicks: {
    GetAiPicks: `${BASE_URL}/api/v1/top-picks`,
    GetAiPicksOfFollowing: `${BASE_URL}/api/v1/user/topPicksOfFollowing?type={0}`,
  },
  MyPicks: {
    GetMyPicksLive: `${BASE_URL}/api/v1/recommendation/list?type={0}`,
    GetMyPicksHistory: `${BASE_URL}/api/v1/recommendation/history?type={0}`,
  },
  Stock: {
    GetStockDetail: `/api/v1/stockdata/{0}`,
    GetTiker: `/api/v1/trading-block/delayed-ticker?ticker={0}`,
  },
  Discovery: {
    preferences: `${BASE_URL}/api/v1/preference`,
    updateActivation: `${BASE_URL}/api/v1/preference/updateActivation`,
    createPreference: `${BASE_URL}/api/v1/preference/createPreference`,
    getPreferenceDetails: `${BASE_URL}/api/v1/preference/getPreferenceDetails?preferenceId={0}`,
    getPreferenceFields: `${BASE_URL}/api/v1/field/getFieldList?type={0}`,
    updatePreferenceData: `${BASE_URL}/api/v1/preference/updatePreferenceData`,
    deletePreferenceData: `${BASE_URL}/api/v1/preference/deletePreference`,
  },
  MarketMovers: {
    GetMarketMovers: `${BASE_URL}/api/v1//trading-block/market-movers?type={0}`,
  },
  BuySell: {
    AvailbleQty: `${BASE_URL}/api/v1/trading-block/getQuantity/{symbol}`,
    GetTradingBalance: `${BASE_URL}/api/v1/trading-block/check-trading-balance`,
    PlaceBuyOrderFake: `${BASE_URL}/api/v1/trading-block/place-order-fake`,
    PlaceBuyOrder: `${BASE_URL}/api/v1/trading-block/place-order`,
  },
  BoosterLoan: {
    CheckBoosterEligibility: `${BASE_URL}/api/v1/booster-loan/check-loan-eligibility`,
    CheckBoosterLoan: `${BASE_URL}/api/v1/booster-loan/check-loan-for-stock?symbol={0}`,
    GetLoanAmount: `${BASE_URL}/api/v1/booster-loan/get-loan-amount?amount={0}`,
    GetPaymentMethod: `${BASE_URL}/api/v1/booster-loan/get-loan-payment-method`,
    UpdatePaymentMethod: `${BASE_URL}/api/v1/booster-loan/update-loan-payment-method?loanId={0}&customerPaymentProfileId={1}`,
    GetLoanDetails: `${BASE_URL}/api/v1/booster-loan/loan-draft?symbol={0}&price={1}`,
    GetBoosterSummary: `${BASE_URL}/api/v1/booster-loan/loan-home`,
    GetBoosterLoanRepayment: `${BASE_URL}/api/v1/booster-loan/loan-repayment`,
    GetBoosterLoanActive: `${BASE_URL}/api/v1/booster-loan/user-loan?isActive={0}`,
    GetBoosterLoanInstallment: `${BASE_URL}/api/v1/booster-loan/loan-installment?loanId={0}`,
    BoosterLending:`${BASE_URL}/api/v1/booster-loan/registerIntrestForBooster`
  },
  Market: {
    GetMarketSignalsFilters: `${BASE_URL}/api/v1/premium/get-filters`,
    GetMarketSignalsFiltersData: `${BASE_URL}/api/v1/premium/get-filter-data`,
    GetMArketSignalCategories: `${BASE_URL}/api/v1/premium/get-categories`,
    GetMarketSignalByCategory: `${BASE_URL}/api/v1/premium/get-by-signal-category?signalName={0}&category={1}`,
  },
  Portfolio: {
    GetPortfolio: `${BASE_URL}/api/v1/trading-block/portfolio`,
    TransactionHistory: `${BASE_URL}/api/v1/trading-block/order-transaction-history`,
  },
  Stepper3: {
    getPreferenceDetails: `${BASE_URL}/api/v1/preference/getPreferenceDetails?preferenceId=6052ea74669996bc33e6acb8`,
  },
  AutoTopup: {
    CreateAutoTopupPopup: `${BASE_URL}/api/v1/top-ups/create`,
    UpcomingAutotopup: `${BASE_URL}/api/v1/top-ups/upcomming?offset=0&limit=20`,
    HistoryTopup: `${BASE_URL}/api/v1/top-ups/history?offset=0&limit=20`,
    getUpcomingTopupDetails: `${BASE_URL}/api/v1/top-ups/upcomming/{0}?offset=0&limit=20`,
    getHistoryTopupDetails: `${BASE_URL}/api/v1/top-ups/history/{0}?offset=0&limit=20`,
    deletestock: `${BASE_URL}/api/v1/top-ups/delete`
  },
  Order: {
    orderList: `${BASE_URL}/api/v1/trading-block/order-list`,
    cancelOrderList: `${BASE_URL}/api/v1/trading-block/order-cancel`
  },
  StockIcon: {
    baseUrl: 'https://logos-api.extend.apexclearing.com/logos/',
    fileType: '.png',
    apiKey: '?api_key=4RJeGywzAE&api_secret=isUE1ZzPNi5IzXbs9Qg0S01eaFUcxX09c4U88R9JBiJgJ6WxXHrXz3VtEima'
  },
  Transfer: {
    GetTransferList: `${BASE_URL}/api/v1/trading-block/transaction-list?direction={dir}&offset=0&limit=30`,
    TransactionHistory: `${BASE_URL}/api/v1/trading-block/order-transaction-history`,
    GetBankAccounts: `${BASE_URL}/api/v1/trading-block/list-link-accounts`,
    DepositeAmount: `${BASE_URL}/api/v1/trading-block/deposit`,
    WithdrawAmount: `${BASE_URL}/api/v1/trading-block/withdrawal`,
    CancelTransfer: `${BASE_URL}/api/v1/trading-block/cancel-transfer`,
    CheckBalance: `${BASE_URL}/api/v1/trading-block/check-account-balance`
  },
  Address: {
    getState: `${BASE_URL}/api/v1/trading-block/get-states`,
    getAddressDetails: `${BASE_URL}/api/v1/trading-block/get-account-details`,
    updateAddress: `${BASE_URL}/api/v1/trading-block/update-account-details`
  },
  Documents: {
    Statement: `${BASE_URL}/api/v1/trading-block/monthly-statement`,
    Confirm: `${BASE_URL}/api/v1/trading-block/daily-confirm`,
    TaxForms: `${BASE_URL}/api/v1/trading-block/tax-documents`,
    DownloadStatement: `${BASE_URL}/api/v1/trading-block/monthly-statement-pdf`,
    DownloadDailyConfirm: `${BASE_URL}/api/v1/trading-block/daily-confirm-pdf`,
    DownloadTaxForm: `${BASE_URL}/api/v1/trading-block/tax-documents-pdf`,
  },
  Settings: {
    usersettings: `${BASE_URL}/api/v1/usersettings`,
    setUserSetting: `${BASE_URL}/api/v1/usersettings/setUserSettings`,
    changePin: `${BASE_URL}/api/v1/user/createPin`,
    deleteAccount: `${BASE_URL}/api/v1/user/delete-account`
  },
  BankAccount: {
    LinkedList: `${BASE_URL}/api/v1/trading-block/list-link-accounts`,
    CreateLinkToken: `${BASE_URL}/api/v1/trading-block/create-link-token?accountId={0}`,
    CompleteLink: `${BASE_URL}/api/v1/trading-block/complete-link`,
    UnlinkAccount: `${BASE_URL}/api/v1/trading-block/unlink-account`,
    SetDefaultAccount: `${BASE_URL}/api/v1/trading-block/set-default-account/{0}`,
  },
  ReferFriend: {
    ReferralData: `${BASE_URL}/api/v1/user/getReferralData`,
    getSpinData: `${BASE_URL}/api/v1/user/getSpinData`,
    UpdateSpinAmount: `${BASE_URL}/api/v1/user/updateSpinAmount`,
    createDiscountVoucher: `${BASE_URL}/api/v1/user/create-discount-voucher`
  },
  Profile: {
    getProfile: `${BASE_URL}/api/v1/user/profile/{0}`,
    getTopPickStock: `${BASE_URL}/api/v1/user/userTopPicks/{0}?type=STOCK`,
    getTopPickETF: `${BASE_URL}/api/v1/user/userTopPicks/{0}?type=ETF `,
    searchUser: `${BASE_URL}/api/v1/user/search?search={0}&offset=0&limit=20`,
    userFollow: `${BASE_URL}/api/v1/follow/{0}`,
    userUnfollow: `${BASE_URL}/api/v1/unfollow/{0}`,
    userFollowerList: `${BASE_URL}/api/v1/user/followerList/{0}`,
    userBlock: `${BASE_URL}/api/v1/user/block/{0}`,
    userUnBlock: `${BASE_URL}/api/v1/user/unblock/{0}`,
    userFollowingList: `${BASE_URL}/api/v1/user/followingList/{0}`
  },
  Header: {
    searchStock: `${BASE_URL}/api/v1/stockdata/list?stock_name={0}&offset=0&limit=20`
  },
  TradeIdea: {
    getTradeIdea: `${BASE_URL}/api/v1/premium/tradeIdeas`
  },
  MarketTrackers: {
    getMarketTrackers: `${BASE_URL}/api/v1/premium/marketTrackers`
  },
  Insights:{
    getInsightData: `${BASE_URL}/api/v1/premium/insightData?symbol={0}`
  },
  AskMrWolf: {
    askMrWolf: `${BASE_URL}/api/v1/trading-block/askMrWolf`
  },
};