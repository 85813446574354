import React from "react";
import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AddCircleOutline } from "@mui/icons-material";
import background from '../../../style/images/squarebackground.png'

export default function Watchlistcard() {
  return (
    <Box
    className='watch-card'
      sx={{
        width:'100%',
        display: "flex",
        position:"relative",
        overflow:'hidden',
        justifyContent: "space-between",
        alignItems: "center",
        background: `url(${background})`,
         backgroundSize:'cover',
         backgroundRepeat:'no-repeat',
        //  backgroundPosition:'',
        borderRadius: "22px",
        padding: "25px 50px",
        color: "white", // Text color
      }}
    >
        

      {/* Text Section */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          fontSize: "24px",
          lineHeight: 1.5,
          width:'60%',
          
          zIndex:2,
        }}
      >
       Create a personalized watchlist to easily
       track your favourite content.
      </Typography>

      {/* Button */}
    </Box>
  );
}
