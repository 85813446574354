import React, { useState, useEffect } from 'react'
import { Box, Typography, Button, CircularProgress } from '@mui/material'
import Axios from '../../../config/axios'
import { UrlConstant } from '../../../commom/UrlConstant'
import { getUserFromLocalStorage } from '../../../Utils/utils'
import { NavLink } from 'react-router-dom'

function MyFollowers() {
  const user = getUserFromLocalStorage();
  const userId = user?._id;
  const [followers, setFollowers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Track loading state for follower data

  // Function to handle follow action
  const handleFollow = (followerId: string) => {
    Axios.post(UrlConstant.Profile.userFollow.replace("{0}", followerId))
      .then(() => {
        updateFollowStatus(followerId, true); // Update follow status after following
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error following user:', error);
      });
  };

  // Function to handle unfollow action
  const handleUnfollow = (followerId: string) => {
    Axios.post(UrlConstant.Profile.userUnfollow.replace("{0}", followerId))
      .then(() => {
        updateFollowStatus(followerId, false); // Update follow status after unfollowing
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error unfollowing user:', error);
      });
  };

  // Function to update the follow status of a user
  const updateFollowStatus = (followerId: string, isFollow: boolean) => {
    setFollowers((prevFollowers) =>
      prevFollowers.map((follower) =>
        follower.user._id === followerId ? { ...follower, user: { ...follower.user, is_follow: isFollow } } : follower
      )
    );
  };

  // Fetch followers data when the component mounts
  useEffect(() => {
    setLoading(true); // Start loading when the request starts
    Axios.post(UrlConstant.Profile.userFollowerList.replace("{0}", userId))
      .then(response => {
        if (response.data && response.data.data) {
          setFollowers(response.data.data); // Set followers data
        }
      })
      .catch(error => {
        console.error('Error fetching followers data:', error);
      })
      .finally(() => {
        setLoading(false); // Stop loading when the request is finished
      });
  }, [userId]);

  return (
    <Box width="100%">
      <Box padding="20px" overflow="auto" display="flex" alignItems="start" gap="20px" flexWrap="wrap">
        {/* Spinner for loading the followers data */}
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            <CircularProgress size={50} sx={{ color: '#F2674A' }} />
          </Box>
        ) : followers.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="calc(100vh - 71px - 143px)"
          >
            <Typography variant="h6" style={{ color: 'white' }}>
              No Data Available
            </Typography>
          </Box>
        )
          : (
            followers.map((follower) => (
              <NavLink to='/userprofile' state={{ userId: follower.user._id }} style={{ width: '48%' }}>
                <Box key={follower._id} className="card" width="100%" padding="20px" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center" gap="10px">
                    <Box component="img" src={follower.user.profile_pic || ''} border="1px solid white" height="42px" width="42px" borderRadius="50%" />
                    <Box>
                      <Typography fontSize="16px" className="text-white" fontWeight="bold">
                        {follower.user.firstName} {follower.user.lastName}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant={follower.user.is_follow ? 'outlined' : 'contained'}
                    className={`btn ${follower.user.is_follow ? '' : 'btn-primary'}`}
                    style={{
                      border: follower.user.is_follow ? '' : 'none',
                      padding: follower.user.is_follow ? '7px 16px' : '7px 25px',
                      backgroundColor: follower.user.is_follow ? '' : '#007bff', // Change color for Follow/Unfollow
                      borderColor: follower.user.is_follow ? '#F2674A' : '',
                      color: follower.user.is_follow ? '#F2674A' : 'white',
                    }}
                    onClick={() => {
                      if (follower.user.is_follow) {
                        handleUnfollow(follower.user._id); // Unfollow if currently following
                      } else {
                        handleFollow(follower.user._id); // Follow if not following
                      }
                    }}
                  >
                    {follower.user.is_follow ? 'Unfollow' : 'Follow'}
                  </Button>
                </Box>
              </NavLink>
            ))
          )}
      </Box>
    </Box>
  );
}

export default MyFollowers;
