import { Navigate, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AiPicks from "../MainComponents/AiPicks";
import BoosterLoan from "../MainComponents/BoosterLoan";
import Dashboard from "../MainComponents/Dashboard";
import Discovery from "../MainComponents/Discovery";
import DiscussionBoard from "../MainComponents/DiscussionBoard";
import MyAccount from "../MainComponents/MyAccount";
import Portfolio from "../MainComponents/Portfolio";
import Profile from "../MainComponents/Profile";
import StockDetail from "../MainComponents/StockDetail";
import CreateApplication from "../MainComponents/TradingAccount/CreateApplication";
import CreateTradingBlockUser from "../MainComponents/TradingAccount/CreateTradingBlockUser";
import WatchList from "../MainComponents/WachList";
import Steppers from "../MainComponents/stepper/stepper";
import LineChart from "../charts/Linechart";
import Header from "./Header";
import "../../../../src/style/style.css";
import Stepper2 from "../MainComponents/Discovery/stepper2";
import MarketSignals from "../MainComponents/MarketSignals";
import MyPicks from "../MainComponents/MyPicks";
import Stepper3 from "../MainComponents/Discovery/stepper3";
import MarketMovers from "../MainComponents/MarketMovers";
import BoosterLoanDetails from "../MainComponents/BoosterLoanDetails";
import PortfolioStockDetail from "../MainComponents/PortfolioStockDetail";
import Transfer from "../MainComponents/Transfer";
import AutoTopup from "../MainComponents/AutoTopup";
import StockDetails from "../Cards/StockDetails";
import DocumentUpload from "../MainComponents/TradingAccount/DocumentUpload";
import Order from "../MainComponents/Order";
import Header2 from "./Header2";
import { Box, Divider } from "@mui/material";
import MoreOptionsCards from "../MainComponents/MoreOptionsCards";
import Documents from "../MainComponents/Documents";
import Header3 from "./Header3";
import LinkBankAccount from "../MainComponents/LinkBankAccount";
import Setting from "../MainComponents/Setting";
import About from "../MainComponents/About";
import ReferFriend from "../MainComponents/ReferFriend";
import UserProfile from "../MainComponents/UserProfile";
import Footer from "./Footer";
import MyProfile from "../MainComponents/MyProfile";
import PremiumSubscription from "../MainComponents/PremiumSubscription";
import { useEffect } from "react";
import { configureRevenueCat, fetchCustomerInfo } from "../../../Utils/utils";
import AskWolf from "../MainComponents/AskWolf";
import Boosterlending from "../MainComponents/Boosterlending";
// import userprofile from "../MainComponents/UserProfile";

function MainDashboard() {
  const location = useLocation();
  const header2Routes = [
    "/createApplication",
    "/createTradingBlockUser",
    "/documentupload",
    "/stepper",
  ];
  const header3Routes = [
    "/discoveryform",
    "/discoveryFields",

  ];
  const isHeader2 = header2Routes.some((route) => location.pathname.startsWith(route));
  const isHeader3 = header3Routes.some((route) => location.pathname.startsWith(route));
  
  useEffect(()=>{
    configureRevenueCat()
    fetchCustomerInfo()
  },[])

  return (
    <>
      {/* <Box maxWidth={{lg:'1380px',md:'100%'}} mx='auto'> */}
      {isHeader2 ? <Header2 /> : isHeader3 ? <Header3 /> : <Header />}
      {/* </Box> */}
      {/* <Header2/> */}
      <Divider sx={{ borderBottom: "1px solid #FFFFFF5A",}}></Divider>
    <Box maxWidth={{lg:'1380px',md:'100%'}} mx='auto' >
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="boosterloan" element={<BoosterLoan />} />
        <Route path="boosterLoanDetails" element={<BoosterLoanDetails />} />
        <Route path="discovery" element={<Discovery />} />
        <Route path="discussionboard" element={<DiscussionBoard />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="portfolioStockInfo" element={<PortfolioStockDetail />} />
        <Route path="profile" element={<Profile />} />
        <Route path="myaccount" element={<MyAccount />} />
        <Route path="wachlist" element={<WatchList />} />
        <Route path="aipicks" element={<AiPicks />} />
        <Route path="mypicks" element={<MyPicks />} />
        <Route path="marketSignals" element={<MarketSignals />} />
        <Route path="marketMovers" element={<MarketMovers />} />
        <Route path="stockdetail" element={<StockDetail />} />
        <Route path="chart" element={<LineChart />} />
        <Route path="upcomingstockdetails" element={<StockDetails />} />
        <Route path="moreopt" element={<MoreOptionsCards/>} />
        <Route path="transfer" element={<Transfer />} />
        <Route path="documents" element={<Documents />} />
        <Route path="order" element={<Order/>} />
        <Route
          path="createTradingBlockUser"
          element={<CreateTradingBlockUser />}
        />
        <Route path="createApplication" element={<CreateApplication />} />
        <Route path="documentupload" element={<DocumentUpload />} />
        <Route path="stepper" element={<Steppers />} />
        <Route path="discoveryform" element={<Stepper2 />} />
        <Route path="discoveryFields" element={<Stepper3 />} />
        <Route path="autotopup" element={<AutoTopup />} />
        <Route path="linkbankaccount" element={<LinkBankAccount/>} />
        <Route path="documents" element={<Documents/>} />
        <Route path="settings" element={<Setting/>} />
        <Route path="about" element={<About/>} />
        <Route path="referfriend" element={<ReferFriend/>} />
        {/* <Route path="userprofile" element={<UserProfile/>} /> */}
        <Route path="userprofile" element={<UserProfile/>} />
        <Route path="myprofile" element={<MyProfile/>} />
        <Route path="premiumSubscription" element={<PremiumSubscription/>} />
        <Route path="askmrwolf" element={<AskWolf/>} />
        <Route path="boosterlending" element={<Boosterlending/>} />

        <Route
          path="*"
          element={<Navigate to="/dashboard" replace />}
        />
      </Routes>
    </Box>
    <Box width='100%'>
        {/* <Divider sx={{ border: '1px solid #FFFFFF33' }}></Divider>
        <Typography my='20px' className='text-white' textAlign='center'>Securities Products and Services are provided by ABC Inc. Member FINRA | SIPC.</Typography> */}
        <Footer/>
      </Box>
      </>

  );
}

export default MainDashboard;
