import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { UrlConstant } from "../../commom/UrlConstant";
import Axios from "../../config/axios";
import { login } from "../../Redux/authSlice";
import "../../style/style.css";
import LeftSide from "../common/Layout/LeftSide";
import { getUserFromLocalStorage } from "../../Utils/utils";
import Successpopup from "../common/SharedComponent/Successpopup";

interface FormValues {
  input_1: string;
  input_2: string;
  input_3: string;
  input_4: string;
  input_5: string;
  input_6: string;
  input_7: string;
  input_8: string;
}

const initialValues: FormValues = {
  input_1: "",
  input_2: "",
  input_3: "",
  input_4: "",
  input_5: "",
  input_6: "",
  input_7: "",
  input_8: "",
};

function Createpin() {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [showPin] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility

  const [msg, setmsg] = useState(''); // State for controlling popup visibility

  const user = getUserFromLocalStorage();
  React.useEffect(() => {
    if (user === undefined || user === null) {
      navigate("/login");
    }
  }, [user, navigate]);

  const onSubmit = (values: FormValues) => {
    console.log("VAL : ", values);
    const creatpin =
      values.input_1 + values.input_2 + values.input_3 + values.input_4;
    const confirmpin =
      values.input_5 + values.input_6 + values.input_7 + values.input_8;

    console.log("PIN 1 : ", creatpin);
    console.log("PIN 2 : ", confirmpin);

    if (creatpin.length !== 4 && confirmpin.length !== 4) {
      // Swal.fire("Enter valid PIN");
      setShowPopup(true)
      setheading('Failed')
      setmsg('Enter valid PIN')
      return;
    }
    if (creatpin !== confirmpin) {
      // Swal.fire("PIN not matched");
      setShowPopup(true)
      setheading('Failed')
      setmsg('PIN not matched')

      return;
    }
    setPin(creatpin);
  };

  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    console.log(e.key);
    if (e.key === "Enter") {
      formik.handleSubmit();
    } else if (e.key === "Backspace") {
      inputRefs.current[index - 1]?.focus();
      formik.setFieldValue(`input_${index}`, "");
    } else if (inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };
  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit,
  });

  const setPin = async (pin: any) => {
    const payLoad = {
      pin: pin,
    };
    try {
      const response = await Axios.post(UrlConstant.Auth.CreatePin, payLoad);

      const resData = response.data;
      if (resData.result === true) {
        const updatedUser = { ...user, IsPINCreated: true };
        dispatch(login(updatedUser));

        navigate("/", { state: updatedUser });
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    }
  };
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };

  return (
    <Container
      maxWidth={false}
      className="container_new"
      sx={{ maxWidth: "100%", margin: "0px" }}
    >
      {/* <LeftSide /> */}
      <RightSection className="RightSection bg-color">
        <Typography width="100%">
          <Typography className="Typography fw-700 mb-10 fs-24 heading">
            Create Pin{" "}
          </Typography>
          <Typography
            className="Typography fs-18 mb-50 text-secondary"
            variant="body1"
            sx={{ mb: { xs: 10, sm: 10 } }}
          >
            Set up a new PIN to secure your account.{" "}
          </Typography>
        </Typography>

        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Typography className="heading mb-10 text-secondary">
            <span>
              Create Pin{" "}
              <sup>
                <i
                  className="fa-solid fa-star-of-life"
                  style={{ color: "#fa0000", fontSize: "0.5rem" }}
                ></i>
              </sup>
            </span>
          </Typography>
          <Box mt={2} mb={0} className="otpgrp">
            {[1, 2, 3, 4].map((i) => {
              const inputName = `input_${i}` as keyof FormValues;

              return (
                <TextField
                  key={i}
                  type={showPin ? "tel" : "password"}
                  inputProps={{
                    maxLength: 1,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                  variant="outlined"
                  className="text-secondary "
                  inputRef={(el: any) => (inputRefs.current[i] = el)}
                  onKeyUp={(e: any) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    } else {
                      handleKeyUp(e, i);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  sx={{
                    ".css-j882ge-MuiInputBase-root-MuiOutlinedInput-root": {
                      width: "105px",
                      fontSize: "18px",
                    },
                    "& .MuiOutlinedInput-input": {
                      textAlign: "center",
                    },
                    " & .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "123px",
                      borderColor: `${formik.errors[inputName] && isLogin
                        ? "red !important"
                        : " "
                        } `,
                    },
                  }}
                  size="medium"
                  InputLabelProps={{
                    shrink: false,
                    classes: {
                      focused: "focused-label",
                    },
                  }}
                  {...formik.getFieldProps(inputName)}
                />
              );
            })}
          </Box>
          <Typography className="heading mb-10 text-secondary " mt={5}>
            <span>
              Confirm Pin{" "}
              <sup>
                <i
                  className="fa-solid fa-star-of-life"
                  style={{ color: "#fa0000", fontSize: "0.5rem" }}
                ></i>
              </sup>
            </span>
          </Typography>
          <div className="otpgrp" style={{ marginBottom: "50px" }}>
            {[1, 2, 3, 4].map((i) => {
              const inputName = `input_${i + 4}` as keyof FormValues;

              return (
                <TextField
                  key={i}
                  type={showPin ? "tel" : "password"}
                  inputProps={{
                    maxLength: 1,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                  variant="outlined"
                  className="text-secondary "
                  inputRef={(el: any) => (inputRefs.current[i + 4] = el)}
                  onKeyUp={(e: any) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    } else {
                      handleKeyUp(e, i + 4);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  sx={{
                    ".css-j882ge-MuiInputBase-root-MuiOutlinedInput-root": {
                      width: "105px !important",
                      fontSize: "18px",
                    },
                    "& .MuiOutlinedInput-input": {
                      textAlign: "center",
                    },
                    " & .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "123px",
                      borderColor: `${formik.errors[inputName] && isLogin
                        ? "red !important"
                        : " "
                        } `,
                    },
                  }}
                  size="medium"
                  InputLabelProps={{
                    shrink: false,
                    classes: {
                      focused: "focused-label",
                    },
                  }}
                  {...formik.getFieldProps(inputName)}
                />
              );
            })}
          </div>

          <Button
            //  sx={{marginTop:'20px'}}
            variant="contained"
            color="primary"
            fullWidth
            className="btn-primary fw-700 fs-18  btn mb-10"
            endIcon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 22C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.75 12L10.58 14.83L16.25 9.17004"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            type="submit"
          >
            Confirm
          </Button>
        </form>

        {/* <Typography sx={{ mt: 2 }} className="text-secondary"> */}
        {/*   <Link to="/" className="link">
          Skip
        </Link>*/}
        {/* </Typography> */}
      </RightSection>
      {showPopup && <Successpopup Success={Success} onClose={closePopup} Message={msg} Heading={heading} />}

    </Container>
  );
}

export default Createpin;

const RightSection = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "space-between",
  alignItems: "center",
  padding: "70px 120px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("sm")]: {
    padding: "40px 40px",
  },
}));
