import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Card, CardContent,Box,Skeleton,Typography } from '@mui/material'
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#2A2A2A', // Base color
        },
        pulse: {
          '&::after': {
            background: 'linear-gradient(90deg, #2A2A2A, #3C3C3C)', // Highlight animation
          },
        },
      },
    },
  },
});
function LoadingCards() {
  return (
    Array.from({ length: 5 }).map((_, index) => (
      <ThemeProvider theme={darkTheme}>
        <Card className="stock card" key={index}>
          <CardContent className="text-white">
            <Box display="flex" alignItems="center" gap="10px" mb={1}>
              <Skeleton variant="circular" height="30px" width="40px" />
              <Typography
                variant="body2"
                fontWeight={600}
                className="stock-label"
                mb={0}
                width="100%"
              >
                <Skeleton />
              </Typography>
            </Box>
            <Typography variant="body1" fontWeight={600} mb="0px">
              <Skeleton />
            </Typography>
            <Typography variant="caption" mr={2} display="contents">
              <Skeleton />
            </Typography>
          </CardContent>
        </Card>
  </ThemeProvider>
      ))
  )
}

export default LoadingCards
