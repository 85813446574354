import React, { useEffect, useState } from "react";
import { Container, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Header3 from "../../Layout/Header3";
import Axios from "../../../../config/axios";
import { UrlConstant } from "../../../../commom/UrlConstant";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import Successpopup from "../../SharedComponent/Successpopup";

const steps = [1, 2];

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,#4EDF6D 0%,#4EDF6D 50%,#4EDF6D 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,#4EDF6D 0%,#4EDF6D 50%,#4EDF6D 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 8,
    border: 0,
    backgroundColor: '#FFFFFF1A',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

function Stepper2() {
  const location = useLocation();
  const navigation = useNavigate();
  const id = location?.state?.id;
  const [preferenceDetails, setPreferenceDetails] = useState<any>({});
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility



  const [activeStep, setActiveStep] = useState(0); // Managing activeStep state

  const getPreferenceDetails = async (id) => {
    try {
      const response = await Axios.get(
        UrlConstant.Discovery.getPreferenceDetails.replace("{0}", id)
      );
      const resData = response.data;
      setPreferenceDetails(resData.data);
    } catch (error: any) {
      console.error("ERRR : ", error);
    }
  };

  useEffect(() => {
    if (id) {
      getPreferenceDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (preferenceDetails) {
      formik.setValues({
        preferenceName: preferenceDetails?.preferenceName || "",
        preferenceType: preferenceDetails?.preferenceType || "",
        profileLevel: preferenceDetails?.level || "",
        investmentType: preferenceDetails?.type || "",
      });
    }
  }, [preferenceDetails]);

  const formik = useFormik({
    initialValues: {
      preferenceName: "",
      preferenceType: "",
      profileLevel: "",
      investmentType: "",
    },
    validationSchema: Yup.object({
      preferenceName: Yup.string().required("Profile name is required"),
      preferenceType: Yup.string().required("Profile type is required"),
      profileLevel: Yup.string().required("Profile level is required"),
      investmentType: Yup.string().required("Investment type is required"),
    }),
    onSubmit: async (values) => {
      try {
        let payload;
        let URL;

        if (id) {
          URL = UrlConstant.Discovery.updatePreferenceData;
          payload = {
            preferenceName: values.preferenceName,
            type: values.investmentType,
            preferenceType: values.preferenceType,
            stockExchangeName: "NYSE", // dynamic
            level: values.profileLevel,
            preferenceId: id,
          };
        } else {
          URL = UrlConstant.Discovery.createPreference;
          payload = {
            preferenceName: values.preferenceName,
            type: values.investmentType,
            preferenceType: values.preferenceType,
            stockExchangeName: "NYSE", // dynamic
            level: values.profileLevel,
          };
        }

        const response = await Axios.post(URL, payload);
        // Swal.fire("Success", "Preference saved successfully!", "success");
        setheading('Success')
        setSuccess(true)
        setShowPopup(true)
        setmsg('Preference saved successfully!')

        navigation("/discoveryFields", {
          state: {
            id: id ?? response.data.data._id,
          },
        });
      } catch (error) {
        console.error("API Error:", error);
        // Swal.fire("Error", "An error occurred while saving your preference!", "error");
        setheading('Error')
        setShowPopup(true)
        setmsg('An error occurred while saving your preference!')

      }
    },
  });

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1)); // Prevent going beyond the steps
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0)); // Prevent going below step 0
  };
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        height: "calc(100vh - 125px)",
        position: 'relative',
      }}
      className="bg-color"
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, marginTop: 3 }}>
        <Stepper activeStep={activeStep} connector={<CustomConnector />} sx={{ width: "12%" }}>
          {steps.map((label) => (
            <Step key={label} sx={{ paddingRight: "0px", paddingLeft: "0px" }}>
              <StepLabel sx={{
                paddingRight: "0px",
                paddingLeft: "0px",
                "& .MuiStepLabel-iconContainer": { paddingRight: "0px", paddingLeft: "0px" },
                ".MuiStepIcon-text": { fontSize: "16px" },
                "& .MuiStepIcon-root": { height: "42px", width: "42px", padding: "0px" },
              }} />
            </Step>
          ))}
        </Stepper>

        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }} />
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={() => formik.resetForm()}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <Box mt={3} display="flex" flexDirection="column" gap={5}>
            <Typography variant="h6" className=" text-white">
              Start Creating Preference Profile
            </Typography>

            <Box display="flex" alignItems="center" width="100%" gap="20px" flexWrap="wrap">
              <FormControl sx={{ width: "32%" }}>
                <TextField
                  name="preferenceName"
                  label={<span>Profile Name <sup><i className="fa-solid fa-star-of-life" style={{ color: "#fa0000", fontSize: "0.5rem" }}></i></sup></span>}
                  variant="outlined"
                  value={formik.values.preferenceName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.preferenceName && Boolean(formik.errors.preferenceName)}
                  helperText={formik.touched.preferenceName && formik.errors.preferenceName}
                  className="text-white"
                  InputLabelProps={{
                    shrink: false
                  }}
                />
              </FormControl>

              <FormControl sx={{ width: "32%" }}>
                <InputLabel className="country fs-18" sx={{ color: "white !important" }} shrink={false}>
                  <span>Profile Type <sup><i className="fa-solid fa-star-of-life" style={{ color: "#fa0000", fontSize: "0.5rem" }}></i></sup></span>
                </InputLabel>
                <Select
                  name="preferenceType"
                  value={formik.values.preferenceType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.preferenceType && Boolean(formik.errors.preferenceType)}
                  sx={{ padding: "12px", "& .MuiSelect-icon": { color: "white" } }}
                >
                  <MenuItem value={"BUY"}>Buy</MenuItem>
                  <MenuItem value={"SELL"}>Sell</MenuItem>
                </Select>
                {formik.touched.preferenceType && formik.errors.preferenceType && (
                  <Typography color="error" variant="body2">{formik.errors.preferenceType}</Typography>
                )}
              </FormControl>

              <FormControl sx={{ width: "32%" }}>
                <InputLabel className="country fs-18" sx={{ color: "white !important" }} shrink={false}>
                  <span>Profile Level <sup><i className="fa-solid fa-star-of-life" style={{ color: "#fa0000", fontSize: "0.5rem" }}></i></sup></span>
                </InputLabel>
                <Select
                  name="profileLevel"
                  value={formik.values.profileLevel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.profileLevel && Boolean(formik.errors.profileLevel)}
                  sx={{ padding: "12px", "& .MuiSelect-icon": { color: "white" } }}
                >
                  <MenuItem value={"BEGINNER"}>Beginner</MenuItem>
                  <MenuItem value={"PRO"}>Advanced</MenuItem>
                </Select>
                {formik.touched.profileLevel && formik.errors.profileLevel && (
                  <Typography color="error" variant="body2">{formik.errors.profileLevel}</Typography>
                )}
              </FormControl>

              <FormControl sx={{ mt: "20px", width: "32%" }}>
                <InputLabel sx={{ color: "white !important" }} shrink={false}>
                  <span>Investment Type <sup><i className="fa-solid fa-star-of-life" style={{ color: "#fa0000", fontSize: "0.5rem" }}></i></sup></span>
                </InputLabel>
                <Select
                  name="investmentType"
                  value={formik.values.investmentType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.investmentType && Boolean(formik.errors.investmentType)}
                  sx={{ padding: "12px", "& .MuiSelect-icon": { color: "white" } }}
                >
                  <MenuItem value={"STOCK"}>Stock</MenuItem>
                  <MenuItem value={"ETF"}>ETF</MenuItem>
                </Select>
                {formik.touched.investmentType && formik.errors.investmentType && (
                  <Typography color="error" variant="body2">{formik.errors.investmentType}</Typography>
                )}
              </FormControl>
            </Box>
          </Box>
        )}
      </Box>

      <Box bottom="0px" width="100%" mb="20px" position="absolute">
        <Divider sx={{ border: "1px solid #FFFFFF33", mb: "10px" }} />
        <Box sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "10px", paddingRight: "20px" }}>
          <Button
            className="btn-primary"
            onClick={(event) => {
              event.preventDefault(); // Prevent default form submission behavior
              formik.handleSubmit(); // Call the formik submit handler
            }}
            sx={{
              borderRadius: '50px',
              textTransform: 'none',
              fontSize: '16px',
              padding: '13px 50px',
              marginLeft: 'auto !important',
            }}
          >
            Save & Next
          </Button>

        </Box>
      </Box>
      {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

    </Container>
  );
}

export default Stepper2;
