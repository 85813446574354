import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import { KeyboardEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { login } from "../../Redux/authSlice";
import { startBlocking } from "../../Redux/loadingSlice";
import { UrlConstant } from "../../commom/UrlConstant";
import Axios from "../../config/axios";
import "../../style/style.css";
import LeftSide from "../common/Layout/LeftSide";
import Footer from "../common/Layout/Footer";
import Successpopup from "../common/SharedComponent/Successpopup";

interface FormValues {
  email: string;
  password: string;
}

function Login() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility


  const initialValues: FormValues = {
    email: "",
    password: "",
  };
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const keyup = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      formik.handleSubmit();
    }
  };

  const onSubmit = async (values: FormValues) => {
    dispatch(startBlocking());

    try {
      const response = await Axios.post(UrlConstant.Auth.Login, values);
      const resData = response.data;
      if (resData.result === true) {
        dispatch(login(resData.data));

        setTimeout(() => {
          if (resData.data?.isVerify === false) {
            navigation("/otp", { state: { email: resData.data.email } });
          } else if (resData.data?.acceptDisclosure === false) {
            navigation("/terms", { state: resData.data });
          } else if (resData.data?.IsPINCreated === false) {
            navigation("/createpin", { state: resData.data });
          } else {
            navigation("/", { state: resData.data });
          }
        }, 100);
      } else {
        // Swal.fire(resData.msg ?? "Authentication Failed");
        setheading("ERROR")
        setShowPopup(true)
        setmsg(resData.msg ?? "Authentication Failed")

      }
    } catch (error: any) {
      console.error("ERRR : ", error);
      // Swal.fire(error.response.data.msg ?? "Authentication Failed");
      setShowPopup(true)
      setheading('Error')
      setmsg(error.response.data.msg ?? "Authentication Failed")
    } finally {
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("valid format").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
  });

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };

  return (
    <Box height='100vh'>
      <Container
        maxWidth={false}
        className="container_new"
        sx={{ maxWidth: "100%", margin: "0px" }}
      >
        {/* <LeftSide /> */}

        <Box
          width="100%"
          className="bg-color"
          padding={{ xs: "0px", xl: "100px" }}
          maxHeight="calc(100vh - 200px )"
        >
          <RightSection className="RightSection">
            <Typography width="100%">
              <Typography
                className="Typography fw-700 mb-10 fs-24 heading"
                width="100%"
              >
                Welcome back to WolfPack
              </Typography>
              <Typography
                className="Typography fs-18 mb-50 text-secondary"
                variant="body1"
                sx={{ mb: { xs: 10, sm: 10 } }}
              >
                Your world awaits—let’s pick up where you left off.
              </Typography>
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Box width="100%">
                <FormControl fullWidth margin="normal" className="mb-30">
                  <TextField
                    // name="EmailId"
                    label={
                      <span>
                        Email ID{" "}
                        <sup>
                          <i
                            className="fa-solid fa-star-of-life"
                            style={{ color: "#fa0000", fontSize: "0.5rem" }}
                          ></i>
                        </sup>
                      </span>
                    }
                    onKeyUp={keyup}
                    variant="outlined"
                    className="text-secondary "
                    sx={{
                      " & .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${formik.errors.email && isLogin ? "red !important" : ""
                          } `,
                      },
                    }}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <IconButton sx={{ background: "transparent" }}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7 20.5C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5H7Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </IconButton>
                        ),
                      },
                    }}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    {...formik.getFieldProps("email")}
                  />
                  {isLogin && formik.errors.email ? (
                    <Typography className="error ">
                      {formik.errors.email}
                    </Typography>
                  ) : null}
                </FormControl>

                <FormControl fullWidth margin="normal" className="mb-20">
                  <TextField
                    // name="Password"
                    label={
                      <span>
                        Password{" "}
                        <sup>
                          <i
                            className="fa-solid fa-star-of-life"
                            style={{ color: "#fa0000", fontSize: "0.5rem" }}
                          ></i>
                        </sup>
                      </span>
                    }
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    className="text-secondary "
                    onKeyUp={keyup}
                    sx={{
                      " & .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${formik.errors.password && isLogin
                          ? "red !important"
                          : " "
                          } `,
                      },
                    }}
                    size="medium"
                    InputProps={{
                      startAdornment: (
                        <IconButton>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7 22C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22H7Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M15.9965 16H16.0054"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.9955 16H12.0045"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.99451 16H8.00349"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </IconButton>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    {...formik.getFieldProps("password")}
                  />
                  {isLogin && formik.errors.password ? (
                    <Typography className="error ">
                      {formik.errors.password}
                    </Typography>
                  ) : null}
                  <Link
                    to="/forgot"
                    className="mb-10 link"
                    style={{ textAlign: "end", marginTop: "10px" }}
                  >
                    Forgot Password?
                  </Link>
                </FormControl>

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="btn-primary fw-700 btn-padding  btn mb-10"
                  sx={{ fontSize: "16px", paddingY: "12px" }}
                  endIcon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.76001 12.0601H19.93"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                  type="submit"
                  onClick={() => setIsLogin(true)}
                >
                  Log In
                </Button>
              </Box>
            </form>

            <Typography sx={{ mt: 2 }} className="text-secondary">
              Don't have an account? &nbsp;{" "}
              <Link to="/signup" className="link">
                Sign Up
              </Link>
            </Typography>
          </RightSection>

        </Box>
        {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

      </Container>
      {/* <Box className='bg-color' sx={{width:'100%', paddingBottom:'5px'}}><Footer/></Box> */}

    </Box>

  );
}

const RightSection = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "70px 120px",
  alignItems: "center",
  height: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("sm")]: {
    padding: "40px 40px",
  },
}));

export default Login;
