import { Grid } from "@material-ui/core";
import React, { useEffect } from 'react'
import { Container, Typography, Box, Card, CardContent, Avatar, ToggleButton, ToggleButtonGroup, CircularProgress } from "@mui/material";
import HistoryTable from "../Tabels/HistoryTable";
import ActiveLoans from "../Tabels/ActiveLoans";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import Repayment from "../Tabels/Repayment";

function BoosterLoan() {
  const [selectedCategory, setSelectedCategory] = React.useState<String>("Repayments");
  const [isBoosterLoan, setIsBoosterLoan] = React.useState<any>(false);
  const [isUpdatePaymentMethod, setIsUpdatePaymentMethod] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [boosterLoanSummary, setBoosterLoanSummary] = React.useState<any>();
  const [boosterLoanRepaymentData, setBoosterLoanRepaymentData] = React.useState<any>();
  const [boosterLoanActiveData, setBoosterLoanActiveData] = React.useState<any>();
  const [boosterLoanHistoryData, setBoosterLoanHistoryData] = React.useState<any>();

  const handleSelectedCategory = (
    event: React.MouseEvent<HTMLElement>,
    newCategory: string
  ) => {
    setSelectedCategory(newCategory);
  };

  const checkIsBoosterLoan = async () => {
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.TradingAccount.CheckAccountStatus
      );
      const resData = response.data;
      if (resData.status === true) {
        setIsBoosterLoan(resData.data.boosterLoanOptIn);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  };

  const getBoosterLoanSummary = async () => {
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.GetBoosterSummary
      );
      const resData = response.data;
      if (resData.status === true) {
        setBoosterLoanSummary(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  };

  const getBoosterLoanRepayment = async () => {
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.GetBoosterLoanRepayment
      );
      const resData = response.data;
      if (resData.status === true) {
        setBoosterLoanRepaymentData(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  };

  const getBoosterLoanActive = async () => {
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.GetBoosterLoanActive.replace("{0}", "true")
      );
      const resData = response.data;
      if (resData.status === true) {
        setBoosterLoanActiveData(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  };

  const getBoosterHistory = async () => {
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.GetBoosterLoanActive.replace("{0}", "false")
      );
      const resData = response.data;
      if (resData.status === true) {
        setBoosterLoanHistoryData(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  };



  useEffect(() => {
    checkIsBoosterLoan()
  }, [])
  useEffect(() => {
    if (isBoosterLoan) {
      getBoosterLoanSummary()
    }
  }, [isBoosterLoan])

  useEffect(() => {
    if (isBoosterLoan) {
      if (selectedCategory === "Repayments") {
        getBoosterLoanRepayment()
      } else if (selectedCategory === "Activeloans") {
        getBoosterLoanActive()
      } else {
        getBoosterHistory()
      }
    }
    setIsUpdatePaymentMethod(false)
  }, [isBoosterLoan, selectedCategory, selectedCategory, isUpdatePaymentMethod])

  console.log(isBoosterLoan, "isBoosterLoanisBoosterLoan", boosterLoanRepaymentData)

  if (!isBoosterLoan && !loading) {
    return <Container maxWidth={false} className="bg-color text-white" style={{ height: 'calc(100vh - 71px -143px)', padding: '30px' }}>Not Having Booster Loan</Container>
  }

  return <>
    <Container maxWidth={false} className="bg-color" style={{ height: 'calc(100vh - 71px - 143px)', padding: '30px' }}>
      {loading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="rgba(0, 0, 0, 0.5)" // Semi-transparent black background
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            backdropFilter: "blur(5px)", // Blur effect
            zIndex: 9999, // Ensures the loader stays above all elements
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      <Typography variant="body1" fontSize='24px' fontWeight={400} className="text-white">Booster Loan Details</Typography>
      <Box display="flex" my='20px' gap='10px' alignItems='center'>
        <Box flexGrow={1} p='0px'>
          <Card className="card" style={{ width: '100%', padding: "12px 10px" }}>
            <CardContent className="text-white" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid container style={{ alignItems: 'center' }}>
                <Grid item xs={3}><Typography variant="h5" className="text-white">Total Owning</Typography></Grid>
                <Grid item xs={9}><Typography variant="h5" textAlign='end' sx={{ color: '#FF2A2A' }}>${boosterLoanSummary?.totalAmount}</Typography></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box flexGrow={1} p='0px'>
          <Card className="card" style={{ width: '100%', padding: "12px 10px" }}>
            <CardContent className="text-white" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid container style={{ alignItems: 'center' }}>
                <Grid item xs={3}><Typography variant="h5" className="text-white">Due
                  this Week</Typography></Grid>
                <Grid item xs={9}><Typography variant="h5" textAlign='end' sx={{ color: '#4A7AF2' }}>${boosterLoanSummary?.dueInWeek}</Typography></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box flexGrow={1} p='0px'>
          <Card className="card" style={{ width: '100%', padding: "12px 10px" }}>
            <CardContent className="text-white" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid container style={{ alignItems: 'center' }}>
                <Grid item xs={4}><Typography variant="h5" className="text-white">Due
                  next Week</Typography></Grid>
                <Grid item xs={8}><Typography variant="h5" textAlign='end' sx={{ color: '#4EDF6D' }}>${boosterLoanSummary?.dueInNextWeek}</Typography></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <ToggleButtonGroup
        value={selectedCategory}
        exclusive
        onChange={handleSelectedCategory}
        sx={{
          width: '100%', display: 'flex', justifyContent: 'space-between', borderRadius: '20px', bgcolor: '#ffffff1a ',


        }}
        aria-label="Platform"
      // className="btn-primary"


      >
        <ToggleButton value={"Repayments"} className="text-white" sx={{
          flexGrow: 1, textTransform: 'none', fontWeight: '600', fontSize: '20px', borderColor: 'transparent !important', borderRadius: '20px !important',
          "&.Mui-selected": {
            bgcolor: "#f2674a !important",
            color: "white !important",
            borderColor: '#f2674a !important'
          },
          "&:hover": {
            bgcolor: "#f2674a !important",
            color: "white !important",
            borderColor: '#f2674a !important'
          },
        }}>Repayments</ToggleButton>
        <ToggleButton value={"Activeloans"} className="text-white" sx={{
          flexGrow: 1, textTransform: 'none', fontWeight: '600', fontSize: '20px', borderColor: 'transparent !important', borderRadius: '20px !important',
          "&:hover": {
            bgcolor: "#f2674a !important",
            color: "white !important",
            borderColor: '#f2674a !important'
          },
          "&.Mui-selected": {
            bgcolor: "#f2674a !important",
            color: "white !important",
            borderColor: '#f2674a !important'
          },

        }}>
          Active Loans
        </ToggleButton>
        <ToggleButton value={'History'} className="text-white" sx={{
          flexGrow: 1, textTransform: 'none', fontWeight: '600', fontSize: '20px', borderColor: 'transparent !important', borderRadius: '20px !important',
          "&.MuiToggleButton-root": {
            marginRight: '0px !important'
          },
          "&:hover": {
            bgcolor: "#f2674a !important",
            color: "white !important",
            borderColor: '#f2674a !important'
          },
          "&.Mui-selected": {
            bgcolor: "#f2674a !important",
            color: "white !Important",
            borderColor: '#f2674a !important',
            marginRight: '0px !importnat'
          },
        }}>History</ToggleButton>
      </ToggleButtonGroup>

      {selectedCategory === 'History' ?
        <HistoryTable
          boosterLoanHistoryData={boosterLoanHistoryData}
        />
        : selectedCategory === 'Activeloans' ?
          <ActiveLoans
            boosterLoanActiveData={boosterLoanActiveData}
            isrepayment={selectedCategory}
            setIsUpdatePaymentMethod={setIsUpdatePaymentMethod}

          />
          : <Repayment
            boosterLoanRepaymentData={boosterLoanRepaymentData}
            isrepayment={selectedCategory}
            setIsUpdatePaymentMethod={setIsUpdatePaymentMethod}

          />
      }
    </Container>
  </>;
}

export default BoosterLoan;