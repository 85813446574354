import React, { useEffect, useState } from "react";
import { Container, Typography, Card, Box, Grid, CardContent, IconButton, CircularProgress } from "@mui/material";
import Axios from "../../../config/axios";
import { UrlConstant } from "../../../commom/UrlConstant";
import { useNavigate } from "react-router-dom";

interface TopUpData {
    _id: string;
    transactionNumber: number;
    amount: number;
    scheduled_date: string;
    display_status: string;
    topUp: {
        total_amount: string;
        total_installments: number;
        name: string;
    };
}

const Upcoming: React.FC = () => {
    const [topUps, setTopUps] = useState<TopUpData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTopUps = async () => {
            try {
                const response = await Axios.get(UrlConstant.AutoTopup.UpcomingAutotopup);
                setTopUps(response.data.data); // Update state with the fetched data
            } catch (error) {
                console.error("Error fetching top-ups:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }
        };

        fetchTopUps();
    }, []);

    const click = (topUp: TopUpData) => {
        console.log("Navigating with:", topUp); 
        navigate('/upcomingstockdetails', { state: JSON.parse(JSON.stringify(topUp)) });
    };

    return (
        <Container
            maxWidth={false}
            className="bg-color"
            style={{
                maxHeight: '59vh',
                padding: "30px",
                overflow: 'auto',
                height: '100%', // Ensure the container takes full height of the screen
                width: '100%',
                position: 'relative' // To position the spinner overlay
            }}
        >
            {/* Full screen loading overlay */}
            {loading && (
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%" // Ensure overlay covers full height of the screen
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                        backgroundColor: "bg-color", // Same background color during loading
                        zIndex: 10
                    }}
                >
                    <CircularProgress style={{ color: "#F2674A" }} />
                </Box>
            )}

            <Box display="flex" gap="20px" flexWrap="wrap" justifyContent="space-between">
                {loading ? (
                    <Typography color="white"  minHeight='calc(100vh - 553px)'></Typography>
                ) : Array.isArray(topUps) && topUps.length > 0 ? (
                    topUps.map((topUp) => {
                        const scheduledDate = new Date(topUp.scheduled_date);
                        const month = scheduledDate.toLocaleString("default", { month: "short" });
                        const day = scheduledDate.getDate();

                        return (
                            <Box key={topUp._id} width="48%" overflow="auto">
                                <Card
                                    sx={{
                                        marginBottom: "20px",
                                        padding: "20px 30px",
                                        height: "113px",
                                        border: "1px solid #FFFFFF2A",
                                        bgcolor: "#FFFFFF2A",
                                        borderRadius: "20px",
                                    }}
                                >
                                    <IconButton onClick={() => { click(topUp); }} sx={{ width: '100%', cursor: 'pointer', padding: '0px', margin: '0px' }}>
                                        <CardContent sx={{ width: '100%', padding: "0px" }}>
                                            <Grid width="100%" container alignItems="center">
                                                <Grid
                                                    item
                                                    sx={{
                                                        marginRight: "10px",
                                                        height: "73px",
                                                        padding: "14px 0px",
                                                        textAlign: "center",
                                                        width: "73px",
                                                        borderRadius: "50px",
                                                        bgcolor: "#FFFFFF1A",
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize="24px"
                                                        lineHeight="24px"
                                                        fontWeight="550"
                                                        sx={{ marginBottom: "0px", color: "#F2674A" }}
                                                    >
                                                        {day}
                                                    </Typography>
                                                    <Typography sx={{ color: "white" }}>{month}</Typography>
                                                </Grid>
                                                <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                    <Typography
                                                        fontSize="24px"
                                                        className="text-white"
                                                        fontWeight="550"
                                                    >
                                                        {topUp.topUp.name}
                                                    </Typography>
                                                    <Typography
                                                        fontSize="16px"
                                                        className="text-white"
                                                    >
                                                        Topup {topUp.transactionNumber} of{" "}
                                                        {topUp.topUp.total_installments} |{" "}
                                                        <span style={{ color: "#4A7AF2" }}>
                                                            {scheduledDate.toLocaleDateString("en-US", {
                                                                weekday: "long",
                                                            })}
                                                        </span>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    color="#4EDF6D"
                                                    textAlign="end"
                                                    fontWeight="bold"
                                                    sx={{ fontSize: "28px" }}
                                                >
                                                    ${topUp.amount.toFixed(2)}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </IconButton>
                                </Card>
                            </Box>
                        );
                    })
                ) : (
                    <Typography color="white" minHeight='calc(100vh - 553px)'>No upcoming top-ups found.</Typography>
                )}
            </Box>
        </Container>
    );
};

export default Upcoming;
