import React, { useEffect, useState } from "react";
import { Box, Button, Container, Card, Typography, List, ListItem, ListItemText, Divider, Grid, ListItemIcon, IconButton, CircularProgress, InputLabel } from "@mui/material";
import {
    Dialog, DialogActions, DialogContent, DialogTitle, RadioGroup,
    FormControlLabel,
    Radio, FormLabel, FormControl, TextField
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import moment from "moment";
import { getUserFromLocalStorage } from "../../../Utils/utils";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";


const Documents: React.FC = () => {
    const navigation = useNavigate()
    const user = getUserFromLocalStorage();


    const [loading, setLoading] = useState(false);
    const [documentsType, setDocumentsType] = useState("Statement")
    const [documentList, setDocumentsList] = useState([])
    const [finalDataList, setFinalDataList] = useState([])
    const [startDate, setStartDate] = useState<any>(moment().subtract(1, "days").format())
    const [endDate, setEndDate] = useState<any>(moment().subtract(1, "days").format())


    const handleDocumentsType = (type) => {
        setDocumentsType(type)
    }

    const handlleViewPDF = (item) => {
        getDocument(item)
    };

    // Get List API's
    const getDocumentsList = async (type) => {
        setLoading(true)
        const data = {
            "accountId": user?.tradingblock_application_id,
            "endDate": moment(endDate).format("YYYY-MM-DD"),
            "startDate": moment(startDate).format("YYYY-MM-DD")
        }

        let URL;
        if (type == "Statement") {
            URL = UrlConstant.Documents.Statement
        } else if (type == "Confirm") {
            URL = UrlConstant.Documents.Confirm
        } else {
            URL = UrlConstant.Documents.TaxForms
        }


        try {
            const response = await Axios.post(URL, data);
            const resData = response.data;
            setDocumentsList(resData?.data.Payload ?? [])

        } catch (error: any) {
            console.error("ERRR : ", error);
        } finally {
            setLoading(false)
        }
    };

    const getDocument = async (item) => {
        let data: any = {
            "accountId": user?.tradingblock_application_id,
            "date": item.date
        };

        let RequestURL;

        if (item.type == "MonthlyStatement") {
            RequestURL = UrlConstant.Documents.DownloadStatement
        } else if (item.type == "DailyConfirm") {
            RequestURL = UrlConstant.Documents.DownloadDailyConfirm
        } else {
            RequestURL = UrlConstant.Documents.DownloadTaxForm
            data = { ...data, formType: item.form }
        }



        try {
            setLoading(true);

            const response = await Axios.post(RequestURL, data, {
                responseType: 'blob' // Ensure the response is treated as a binary Blob
            });

            if (response.status === 200) {
                // Create a Blob object from the response
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

                // Create a URL for the Blob
                const pdfUrl = URL.createObjectURL(pdfBlob);

                // Open the PDF in a new tab
                window.open(pdfUrl, '_blank');
            } else {
                console.error("Failed to fetch the document");
            }
        } catch (error: any) {
            console.error("Error fetching document: ", error);
        } finally {
            setLoading(false);
        }
    };


    const groupByMonth = (data) => {
        return data.reduce((acc, item) => {
            const date = new Date(item.date);
            const monthYear = date.toLocaleString("default", { month: "long", year: "numeric" }); // "January 2024"

            if (!acc[monthYear]) {
                acc[monthYear] = { monthYear, items: [] };
            }
            acc[monthYear].items.push(item);

            return acc;
        }, {});
    };


    useEffect(() => {
        getDocumentsList(documentsType)
    }, [documentsType, startDate, endDate])


    useEffect(() => {
        if (documentList.length) {
            // Sort by year and month, newest first
            const sortedData = documentList.sort((a: any, b: any) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);

                // First, sort by year (descending)
                if (dateB.getFullYear() !== dateA.getFullYear()) {
                    return dateB.getFullYear() - dateA.getFullYear();
                }

                // If years are the same, sort by month (descending)
                return dateB.getMonth() - dateA.getMonth();
            });

            // Group by month after sorting
            const groupByData = groupByMonth(sortedData);
            setFinalDataList(groupByData)
        }
    }, [documentList]);



    return (
        <Container
            maxWidth={false}
            className="bg-color"

            style={{
                minHeight: "calc(100vh -  71px)",
                width: "100%",
               display:'flex',
               flexDirection:'column',
                padding: '30px !important'
            }}
        >
            <Box flexGrow='1'>
            {loading && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    width="100vw"
                    height="100vh"
                    bgcolor="rgba(0, 0, 0, 0.5)" // Semi-transparent black background
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        backdropFilter: "blur(5px)", // Blur effect
                        zIndex: 9999, // Ensures the loader stays above all elements
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            )}
            <Typography
                className="text-secondary"
                sx={{ marginBottom: "20px", paddingTop: '30px' }}
            >
                {" "}
                <span
                    onClick={() => navigation(-1)}
                    style={{ color: "#4A7AF2", cursor: "pointer" }}
                >
                    More Options{" "}
                </span>{" "}
                / Documents
            </Typography>
            <Box sx={{ display: "flex", gap: '20px', minHeight: 'calc(100vh - 71px - 143px)', color: "#fff" }}>
                {/* Sidebar */}
                <Box
                    sx={{
                        width: "400px",
                        backgroundColor: "#FFFFFF1A",
                        borderRadius: '20px', border: "1px solid #FFFFFF2A",
                        padding: '20px',
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    {/* Transfer Options */}
                    <Card
                        sx={{
                            backgroundColor: documentsType == "Statement" ? "var(--Orange-10, #F2674A1A)" : "#FFFFFF2A",
                            padding: '10px',
                            marginBottom: 2,
                            border: documentsType == "Statement" ? "1px solid var(--Orange-100, #F2674A)" : '1px solid #FFFFFF2A',
                            borderRadius: '20px',
                            cursor: "pointer"
                        }}
                        onClick={() => { handleDocumentsType("Statement") }}

                    >
                        <Box display='flex' justifyContent='left' gap='10px'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5.55859H22" stroke={documentsType == "Statement" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.22 2H19.78C21.56 2 22 2.44 22 4.2V8.31C22 10.07 21.56 10.51 19.78 10.51H14.22C12.44 10.51 12 10.07 12 8.31V4.2C12 2.44 12.44 2 14.22 2Z" stroke={documentsType == "Statement" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2 17.0586H12" stroke={documentsType == "Statement" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.22 13.5H9.78C11.56 13.5 12 13.94 12 15.7V19.81C12 21.57 11.56 22.01 9.78 22.01H4.22C2.44 22.01 2 21.57 2 19.81V15.7C2 13.94 2.44 13.5 4.22 13.5Z" stroke={documentsType == "Statement" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25" stroke={documentsType == "Statement" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2 9C2 5.13 5.13 2 9 2L7.95001 3.75" stroke={documentsType == "Statement" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <Typography variant="subtitle1" className="text-white" lineHeight='22px' >
                                Statement
                            </Typography>
                        </Box>
                    </Card>
                    <Card
                        sx={{
                            backgroundColor: documentsType == "Confirm" ? "var(--Orange-10, #F2674A1A)" : "#FFFFFF2A",
                            padding: '10px',
                            marginBottom: 2,
                            border: documentsType == "Confirm" ? "1px solid var(--Orange-100, #F2674A)" : '1px solid #FFFFFF2A',
                            borderRadius: '20px',
                            cursor: "pointer"
                        }}
                        onClick={() => { handleDocumentsType("Confirm") }}
                    >
                        <Box display='flex' justifyContent='left' gap='10px'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.37 2.14984L21.37 5.74982C21.72 5.88982 22 6.30981 22 6.67981V9.99982C22 10.5498 21.55 10.9998 21 10.9998H3C2.45 10.9998 2 10.5498 2 9.99982V6.67981C2 6.30981 2.28 5.88982 2.63 5.74982L11.63 2.14984C11.83 2.06984 12.17 2.06984 12.37 2.14984Z" stroke={documentsType == "Confirm" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19V22Z" stroke={documentsType == "Confirm" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4 18V11" stroke={documentsType == "Confirm" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 18V11" stroke={documentsType == "Confirm" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 18V11" stroke={documentsType == "Confirm" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16 18V11" stroke={documentsType == "Confirm" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20 18V11" stroke={documentsType == "Confirm" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1 22H23" stroke={documentsType == "Confirm" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 8.5C12.8284 8.5 13.5 7.82843 13.5 7C13.5 6.17157 12.8284 5.5 12 5.5C11.1716 5.5 10.5 6.17157 10.5 7C10.5 7.82843 11.1716 8.5 12 8.5Z" stroke={documentsType == "Confirm" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <Typography variant="subtitle1" lineHeight='22px' className="text-white" >
                                Trade Confirmations
                            </Typography>
                        </Box>
                    </Card>
                    <Card
                        sx={{
                            backgroundColor: documentsType == "TaxForms" ? "var(--Orange-10, #F2674A1A)" : "#FFFFFF2A",
                            padding: '10px',
                            marginBottom: 2,
                            border: documentsType == "TaxForms" ? "1px solid var(--Orange-100, #F2674A)" : '1px solid #FFFFFF2A',
                            borderRadius: '20px',
                            cursor: "pointer"
                        }}
                        onClick={() => { handleDocumentsType("TaxForms") }}
                    >
                        <Box display='flex' justifyContent='left' gap='10px'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.37 2.14984L21.37 5.74982C21.72 5.88982 22 6.30981 22 6.67981V9.99982C22 10.5498 21.55 10.9998 21 10.9998H3C2.45 10.9998 2 10.5498 2 9.99982V6.67981C2 6.30981 2.28 5.88982 2.63 5.74982L11.63 2.14984C11.83 2.06984 12.17 2.06984 12.37 2.14984Z" stroke={documentsType == "TaxForms" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19V22Z" stroke={documentsType == "TaxForms" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4 18V11" stroke={documentsType == "TaxForms" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 18V11" stroke={documentsType == "TaxForms" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 18V11" stroke={documentsType == "TaxForms" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16 18V11" stroke={documentsType == "TaxForms" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20 18V11" stroke={documentsType == "TaxForms" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1 22H23" stroke={documentsType == "TaxForms" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 8.5C12.8284 8.5 13.5 7.82843 13.5 7C13.5 6.17157 12.8284 5.5 12 5.5C11.1716 5.5 10.5 6.17157 10.5 7C10.5 7.82843 11.1716 8.5 12 8.5Z" stroke={documentsType == "TaxForms" ? " #F2674A" : '#FFFFFF'} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <Typography variant="subtitle1" lineHeight='22px' className="text-white">
                                Tax Forms
                            </Typography>
                        </Box>
                    </Card>

                </Box>

                {/* Main Content */}
                <Box sx={{ flex: 1, backgroundColor: "#FFFFFF1A", borderRadius: '20px', border: "1px solid #FFFFFF2A", padding: '20px' }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: '20px' }}>
                        <Typography variant="h5">
                            {documentsType == "Statement" ? "Statement" : documentsType == "Confirm" ? "Trade Confirmations" : "Tax Forms"}
                        </Typography>

                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} gap={2} mb="40px">
                        <FormControl fullWidth sx={{ marginTop: "20px" }}>
                            <InputLabel shrink={false} sx={{ color: "gray !important" }}>
                                Start Date
                                <sup style={{ color: "red", marginLeft: "5px" }}>*</sup>
                            </InputLabel>
                            <DatePicker
                                selected={startDate}
                                onChange={(date: Date | null) => setStartDate(date)}
                                dateFormat="dd-MMMM-yyyy"
                                placeholderText="Select a start date"
                                showIcon
                                className="datePickerInput"
                                wrapperClassName="datePickerWrapper"
                                showYearDropdown
                                scrollableYearDropdown
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginTop: "20px" }}>
                            <InputLabel shrink={false} sx={{ color: "gray !important" }}>
                                End Date
                                <sup style={{ color: "red", marginLeft: "5px" }}>*</sup>
                            </InputLabel>
                            <DatePicker
                                selected={endDate}
                                onChange={(date: Date | null) => setEndDate(date)}
                                dateFormat="dd-MMMM-yyyy"
                                placeholderText="Select a end date"
                                showIcon
                                className="datePickerInput"
                                wrapperClassName="datePickerWrapper"
                                showYearDropdown
                                scrollableYearDropdown
                            />
                        </FormControl>

                    </Box>
                    <>
                        {Object.entries(finalDataList).map(([month, { items }]: any, index) => {
                            return (
                                <div key={month} style={{ marginBottom: "20px" }}>
                                    <Box mb='30px'>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: '20px' }}>
                                            <Typography variant="h5">
                                                {month}
                                            </Typography>

                                        </Box>

                                        {/* Transaction List */}
                                        <Box
                                            sx={{
                                                display: "grid",
                                                gridTemplateColumns: {
                                                    xs: "1fr", // 1 item per row on small screens
                                                    sm: "repeat(2, 1fr)", // 2 items per row on medium/large screens
                                                },
                                                gap: "16px", // Space between items
                                            }}
                                        >
                                            <List>
                                                {items?.length && items?.map((doc) => {
                                                    return (
                                                        <ListItem sx={{ backgroundColor: "#FFFFFF2A", borderRadius: '20px', padding: '20px 30px', height: '113px', marginBottom: 1, cursor: "pointer" }} onClick={() => { handlleViewPDF(doc) }}>
                                                            <ListItemIcon sx={{ marginRight: '20px' }}>
                                                                <Box height='73px' width='73px' borderRadius='50px' padding='10px 0px' textAlign='center' bgcolor='#FFFFFF1A'>
                                                                    <Typography variant="h5" fontWeight='600' color="#F2674A" mb='0px'>{moment(doc.date).format("DD")}</Typography>
                                                                    <Typography variant="subtitle2" color="#FFFFFF5A">{moment(doc.date).format("MMM")}</Typography>
                                                                </Box>
                                                            </ListItemIcon>

                                                            <ListItemText
                                                                primary={<Typography fontSize={"20px"} >{moment(doc.date).format("DD MMM, YYYY")} <span style={{ color: '#4A7AF2' }}>{moment(doc.date).format("hh:mm A")}</span></Typography>}
                                                                secondary={doc.form ?? ""}
                                                                primaryTypographyProps={{ color: "#fff", fontSize: "20px" }}
                                                                secondaryTypographyProps={{ color: "#FFFFFF", fontSize: '16px' }}
                                                            />

                                                            <Typography><ArrowForwardIosIcon sx={{ fontSize: "32px" }} /></Typography>
                                                        </ListItem>
                                                    )
                                                })}

                                            </List>

                                        </Box>
                                    </Box>


                                </div>
                            )
                        }
                        )}

                    </>

                </Box>
            </Box>
            </Box>

            <Box width='100%'>
                <Divider sx={{ border: '1px solid #FFFFFF33' }}></Divider>
                <Typography my='20px' className='text-white' textAlign='center'>Securities Products and Services are provided by ABC Inc. Member FINRA | SIPC.</Typography>
            </Box>



        </Container>
    );
};

export default Documents;
