import React from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const OrderSuccessCard: React.FC<any> = ({orderDetails}) => {
  return (
    <Card
      sx={{
        color: "#fff",
        bgcolor:'transparent',
        border:'none',
        boxShadow:'none',
        margin:'30px'
      
      }}
    >
      {/* Confetti Icon */}
      <Box sx={{ display: "flex", justifyContent: "center"}}>
      <svg width="242" height="250" viewBox="0 0 242 250" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M154.548 142.374C155.063 142.544 155.351 142.721 155.732 143.109C155.537 144.055 155.395 145.064 155.111 145.985C154.7 145.554 154.535 145.022 154.325 144.476C154.384 143.774 154.471 143.074 154.548 142.374Z" fill="#F2674A"/>
<path d="M154.591 140.121L156.197 140.75C156.007 141.534 155.863 142.314 155.731 143.109C155.351 142.721 155.062 142.544 154.547 142.374C154.521 141.623 154.564 140.871 154.591 140.121Z" fill="#F2674A"/>
<path d="M146.92 93.7174C147.608 93.6826 148.502 93.8528 149.141 94.1147C149.491 94.2585 149.787 94.5576 149.903 94.9225C150.293 96.1501 149.045 98.382 148.547 99.5358L148.352 99.6195C147.31 99.5619 146.241 98.9385 145.328 98.4691C145.854 96.885 146.292 95.2649 146.92 93.7174Z" fill="#DFDD4E"/>
<path d="M85.1243 171.32C86.6713 171.93 88.5883 172.469 89.9786 173.351C90.3994 173.618 90.826 174.019 90.8769 174.544C90.9408 175.201 90.2762 176.075 89.8985 176.587C87.7702 176.069 85.5775 174.797 83.6162 173.807C84.0236 172.895 84.4429 172.059 85.1243 171.32Z" fill="#DFDD4E"/>
<path d="M8.85278 147.141C10.4661 147.748 13.7262 150.041 14.7073 151.494C14.1645 152.181 13.6219 152.723 12.9151 153.242C11.4112 152.886 8.36567 150.301 7.33301 149.127C7.80153 148.406 8.23948 147.75 8.85278 147.141Z" fill="#FF2A2A"/>
<path d="M68.3192 180.288C70.0671 181.447 71.6906 182.84 73.3321 184.147C72.7946 185.123 72.166 185.989 71.4601 186.847C69.7581 186.038 68.0612 184.241 66.5684 183.048C67.0618 182.057 67.6694 181.181 68.3192 180.288Z" fill="#DFDD4E"/>
<path d="M205.511 112.052L205.721 112.075C207.158 113.076 207.92 116.176 208.332 117.769C207.431 118.309 206.407 118.69 205.442 119.106L205.011 119.115C204.614 118.519 204.72 117.412 204.513 116.695C204.147 115.431 203.355 114.237 202.71 113.097L205.511 112.052Z" fill="#FF2A2A"/>
<path d="M205.707 83.4707C208.279 84.6764 209.49 86.4575 210.691 88.9374C210.026 89.4888 209.297 89.9662 208.59 90.4608C208.406 90.5491 208.204 90.5993 208.01 90.6605C207.271 90.3218 206.543 87.0984 203.895 85.6212C204.485 84.8944 205.103 84.1866 205.707 83.4707Z" fill="#4A7AF2"/>
<path d="M96.0245 34.8389C96.5533 35.0647 97.181 35.2821 97.615 35.6683C97.9597 35.9746 98.1956 36.4167 98.1621 36.8884C98.0392 38.6236 95.0617 40.7149 93.8213 41.7716C93.008 41.5987 92.2062 41.3789 91.6789 40.6945C91.6049 40.3041 91.5794 40.1023 91.7628 39.7371C92.458 38.3533 94.8198 37.0767 95.9205 35.0359L96.0245 34.8389Z" fill="#4A7AF2"/>
<path d="M183.591 190.403L183.845 190.438C184.492 190.873 187.341 196.376 187.734 197.345C187.071 197.809 186.341 198.169 185.629 198.549L185.396 198.616C184.421 197.917 182.311 193.181 181.549 191.824C182.205 191.324 182.912 190.871 183.591 190.403Z" fill="#4A7AF2"/>
<path d="M64.9099 49.248C65.9153 49.6589 66.7843 50.4183 67.8292 50.7781C68.6527 51.0613 69.7468 50.8721 70.5037 51.2213C70.7081 51.3156 70.8118 51.4264 70.8838 51.6357C71.0867 52.2282 70.8229 53.0015 70.5591 53.5415C70.3358 53.9991 70.1293 54.0433 69.6649 54.2039C68.0322 54.0847 65.1725 53.5802 63.9426 52.4643C63.6248 52.176 63.4273 51.8039 63.4444 51.3634C63.4777 50.5057 64.3323 49.791 64.9099 49.248Z" fill="#4A7AF2"/>
<path d="M182.014 87.8877C183.795 89.0532 184.968 91.7664 185.48 93.7433C184.641 94.467 183.778 94.967 182.787 95.4466L182.564 95.2415C181.849 92.5259 180.888 91.5274 179.289 89.3442L179.337 89.1865C180.011 88.452 181.094 88.194 182.014 87.8877Z" fill="#FF2A2A"/>
<path d="M63.4313 113.67C63.5197 113.676 63.6088 113.677 63.6969 113.689C65.7625 113.957 68.6864 116.915 69.8047 118.532C69.2499 119.103 68.63 119.598 68.0158 120.103C67.7005 120.262 67.6804 120.257 67.3309 120.201C66.541 119.608 66.0656 118.772 65.3327 118.15C64.6064 117.535 62.2919 116.388 61.9541 115.674C62.0356 114.715 62.7593 114.252 63.4313 113.67Z" fill="#4A7AF2"/>
<path d="M91.644 65.8877C93.5766 67.0045 95.3643 68.6565 97.144 70.0184C96.6509 71.0076 96.0448 71.8955 95.4204 72.8052C94.1551 72.431 92.4914 70.9292 91.4528 70.0616C90.957 69.6472 90.1605 69.0736 90.1312 68.3898C90.0904 67.4424 91.0421 66.5258 91.644 65.8877Z" fill="#DFDD4E"/>
<path d="M153.058 180.752C155.644 181.107 158.412 181.896 160.915 182.644C160.445 183.532 159.912 184.383 159.389 185.24C158.7 185.478 152.71 183.845 151.702 183.536C152.061 182.54 152.494 181.645 153.058 180.752Z" fill="#4A7AF2"/>
<path d="M138.259 79.4121C139.992 81.8106 142.003 82.7815 144.733 83.7281C144.169 84.6287 143.6 85.526 143.025 86.4194L142.806 86.5383C140.589 86.1569 138.095 84.1995 136.802 82.4259C137.202 81.3953 137.762 80.401 138.259 79.4121Z" fill="#FF2A2A"/>
<path d="M106.349 124.764C107.147 125.702 107.925 126.657 108.683 127.627C107.058 129.189 105.348 130.651 103.554 132.013C103.381 131.964 103.335 131.962 103.16 131.853C102.639 131.527 101.739 130.632 101.67 129.986C101.517 128.573 105.302 125.866 106.349 124.764Z" fill="#F2674A"/>
<path d="M35.7212 189.609C36.4406 189.726 37.6565 191.412 38.1687 191.993C36.672 193.72 34.8312 195.349 33.1532 196.903C32.194 196.277 31.3999 195.397 30.6748 194.519C32.3073 192.858 33.9248 191.09 35.7212 189.609Z" fill="#DFDD4E"/>
<path d="M138.458 110.088L138.887 110.158C139.316 111.222 139.701 112.335 140.441 113.23C141.195 114.144 142.564 114.842 143.1 115.875C142.939 116.575 141.341 117.384 140.732 117.771C140.69 117.77 140.648 117.773 140.607 117.766C139.837 117.63 137.145 114.167 136.588 113.308C136.364 112.962 136.031 112.474 136.15 112.049C136.428 111.058 137.606 110.518 138.458 110.088Z" fill="#4A7AF2"/>
<path d="M219.159 61.3613L220.973 61.3027C220.785 63.8154 220.74 66.019 221.54 68.4331C220.874 68.6815 220.192 68.8827 219.514 69.095C219.185 69.1683 218.794 69.2469 218.487 69.0701C217.875 68.7181 217.619 67.892 217.481 67.2391C217.198 65.8944 217.144 62.9015 218.055 61.8078C218.383 61.4144 218.69 61.443 219.159 61.3613Z" fill="#DFDD4E"/>
<path d="M208.495 201.947C209.382 202.667 210.188 203.615 211 204.427C209.195 206.049 207.52 207.834 205.816 209.561C205.065 209.39 203.822 207.763 203.253 207.156C205.042 205.466 206.719 203.654 208.495 201.947Z" fill="#DFDD4E"/>
<path d="M148.153 45.5281C148.449 45.5131 148.754 45.496 149.048 45.5461C149.591 45.6386 150.136 45.9236 150.452 46.3864C150.848 46.9675 150.848 47.7248 150.712 48.3897C150.402 49.9128 149.377 51.4867 148.102 52.3585C147.861 52.3549 147.613 52.3519 147.375 52.3114C146.766 52.2084 146.11 51.8207 145.78 51.2906C145.365 50.6225 145.392 49.7492 145.591 49.0146C145.977 47.5948 146.897 46.2819 148.153 45.5281Z" fill="white"/>
<path d="M126.69 29.2053L127.061 29.1963C127.773 29.8528 128.036 34.8396 128.163 36.1333C127.268 36.6489 126.361 36.9753 125.379 37.2702C125.159 37.278 124.997 37.2444 124.783 37.1999C124.197 36.3393 123.988 31.3648 123.848 30.0203C124.789 29.647 125.695 29.3873 126.69 29.2053Z" fill="#DFDD4E"/>
<path d="M182.852 106.774C182.91 106.786 182.97 106.787 183.024 106.81C183.775 107.129 184.441 108.631 184.811 109.33C182.041 110.867 181.413 112.346 179.504 114.692L179.397 114.821C178.571 114.16 177.897 113.332 177.198 112.543C178.232 109.81 180.236 107.956 182.852 106.774Z" fill="#DFDD4E"/>
<path d="M54.8478 86.925C55.658 86.8916 56.3538 87.125 56.9383 87.7061C57.1769 87.943 57.28 88.2223 57.2413 88.5604C57.0837 89.9466 52.7522 93.2025 51.5612 94.2346C51.2363 94.383 51.0777 94.4968 50.7141 94.3518C49.9665 94.0533 49.462 92.9824 49.1113 92.3217C50.7885 90.3764 52.947 88.6595 54.8478 86.925Z" fill="#4EDF6D"/>
<path d="M202.992 169.503C203.212 169.494 203.434 169.501 203.642 169.582C205.814 170.422 207.772 173.68 208.534 175.772C207.785 176.315 207.015 176.7 206.183 177.095C206.087 177.124 205.994 177.165 205.895 177.183C205.375 177.28 205.207 176.735 205.006 176.356C203.929 174.328 202.466 173.074 200.795 171.554C201.466 170.817 202.25 170.168 202.992 169.503Z" fill="#FF2A2A"/>
<path d="M150.154 207.995C150.534 208.73 149.981 210.441 150.638 211.967C150.92 212.623 151.363 213.211 152.057 213.455C152.976 213.779 154.254 213.53 155.086 213.066C155.794 212.671 156.279 212.049 156.473 211.259C156.724 210.241 156.43 209.186 155.869 208.331C155.677 208.039 155.518 207.787 155.385 207.465L155.589 207.274C156.686 207.997 157.447 209.01 157.713 210.317C157.964 211.553 157.603 213.074 156.899 214.111C156.248 215.069 155.239 215.661 154.1 215.829C153.943 215.854 153.785 215.871 153.626 215.879C153.468 215.887 153.309 215.887 153.15 215.879C152.991 215.871 152.833 215.854 152.676 215.829C152.519 215.805 152.364 215.772 152.21 215.732C152.056 215.691 151.905 215.642 151.756 215.586C151.608 215.53 151.462 215.466 151.32 215.395C151.178 215.323 151.04 215.245 150.906 215.159C150.772 215.074 150.642 214.981 150.518 214.882C150.401 214.786 150.288 214.684 150.181 214.577C150.073 214.47 149.971 214.358 149.874 214.241C149.778 214.125 149.687 214.003 149.602 213.877C149.517 213.752 149.438 213.622 149.366 213.489C149.294 213.355 149.228 213.219 149.169 213.079C149.11 212.939 149.058 212.797 149.012 212.652C148.967 212.507 148.929 212.36 148.898 212.211C148.867 212.063 148.843 211.913 148.826 211.762C148.699 210.536 149.176 209.124 149.977 208.193C150.035 208.126 150.095 208.061 150.154 207.995Z" fill="#4EDF6D"/>
<path d="M5.50823 105.446C5.53581 105.511 5.56488 105.574 5.59096 105.638C5.80079 106.161 5.76602 106.754 5.9138 107.296C6.24293 108.503 7.24412 110.005 8.3862 110.539C9.67935 111.145 11.3889 110.863 12.6754 110.346C13.054 110.194 13.4032 109.989 13.7599 109.793C14.0348 109.643 13.9134 109.693 14.2126 109.617C14.0927 110.268 13.8924 110.862 13.5345 111.423C12.8103 112.557 11.7414 113.299 10.423 113.575C10.3388 113.593 10.254 113.609 10.1686 113.623C10.0834 113.637 9.99799 113.649 9.91226 113.658C9.82623 113.668 9.7402 113.675 9.65417 113.68C9.56814 113.685 9.48181 113.688 9.39548 113.688C9.30915 113.689 9.22282 113.687 9.13649 113.684C9.05016 113.68 8.96413 113.673 8.8781 113.665C8.79207 113.657 8.70664 113.646 8.62121 113.634C8.53577 113.621 8.45094 113.606 8.36611 113.589C8.28158 113.572 8.19735 113.552 8.11372 113.531C8.03009 113.509 7.94705 113.486 7.86462 113.46C7.78218 113.434 7.70065 113.406 7.61972 113.376C7.53878 113.346 7.45875 113.314 7.37931 113.28C7.29988 113.246 7.22164 113.21 7.144 113.172C7.06667 113.133 6.99023 113.093 6.91499 113.051C6.83945 113.009 6.76511 112.965 6.69197 112.919C6.61913 112.873 6.54719 112.825 6.47644 112.775C5.38832 112.016 4.57838 110.85 4.34667 109.534C4.07599 107.996 4.61315 106.668 5.50823 105.446Z" fill="#4EDF6D"/>
<path d="M115.774 145.945C116.226 145.936 116.643 145.968 117.083 146.077C120.074 146.813 122.268 149.283 123.843 151.788C123.449 152.276 122.979 152.672 122.511 153.087L121.341 153.851C119.354 151.231 117.831 150.019 114.858 148.606C115.084 147.658 115.353 146.824 115.774 145.945Z" fill="#4EDF6D"/>
<path d="M150.153 207.995C150.566 207.466 151.234 207.102 151.865 206.907C153.15 206.51 154.405 206.666 155.588 207.274L155.384 207.464C155.517 207.786 155.677 208.039 155.869 208.33C156.43 209.185 156.723 210.24 156.473 211.259C156.278 212.049 155.793 212.671 155.085 213.066C154.253 213.53 152.975 213.779 152.056 213.455C151.363 213.21 150.92 212.623 150.637 211.966C149.981 210.441 150.534 208.73 150.153 207.995Z" fill="#4EDF6D"/>
<path d="M148.817 237.909C148.651 237.08 148.956 236.011 149.439 235.323C150.032 234.477 151.03 233.915 152.043 233.768C152.17 233.748 152.296 233.735 152.424 233.729C152.551 233.722 152.678 233.722 152.805 233.729C152.933 233.735 153.059 233.748 153.186 233.768C153.311 233.787 153.436 233.813 153.559 233.846C153.683 233.878 153.804 233.917 153.924 233.962C154.043 234.007 154.159 234.058 154.274 234.115C154.388 234.172 154.499 234.235 154.606 234.303C154.714 234.371 154.818 234.445 154.918 234.524C155.748 235.18 156.138 236.083 156.404 237.084C156.216 237.984 156.085 238.852 155.461 239.567C155.427 239.622 155.395 239.678 155.36 239.732C154.833 240.535 153.77 241.2 152.829 241.356C152.719 241.374 152.608 241.385 152.497 241.392C152.386 241.398 152.275 241.399 152.163 241.394C152.052 241.388 151.941 241.378 151.831 241.361C151.721 241.345 151.612 241.323 151.504 241.296C151.395 241.269 151.289 241.236 151.184 241.197C151.08 241.159 150.977 241.116 150.877 241.067C150.776 241.018 150.679 240.965 150.584 240.906C150.489 240.848 150.397 240.784 150.309 240.716C149.365 240.003 148.977 239.05 148.817 237.909Z" fill="white"/>
<path d="M5.50879 105.446C5.60501 105.322 5.70663 105.202 5.81394 105.088C5.92155 104.973 6.03426 104.864 6.15207 104.76C6.26987 104.657 6.39277 104.559 6.52017 104.467C6.64757 104.375 6.77886 104.289 6.91435 104.21C7.04984 104.131 7.18893 104.058 7.33131 103.992C7.4737 103.926 7.61908 103.867 7.76716 103.816C7.91554 103.764 8.06572 103.719 8.21799 103.682C8.37057 103.644 8.52435 103.614 8.67962 103.591C10.0084 103.407 11.3127 103.792 12.3696 104.61C13.3906 105.399 14.1505 106.621 14.2797 107.92C14.3363 108.49 14.3061 109.053 14.2131 109.617C13.914 109.693 14.0354 109.642 13.7605 109.793C13.4038 109.988 13.0546 110.194 12.676 110.346C11.3894 110.862 9.67991 111.144 8.38676 110.539C7.24468 110.004 6.24349 108.503 5.91436 107.295C5.76658 106.753 5.80135 106.161 5.59152 105.638C5.56544 105.573 5.53637 105.51 5.50879 105.446Z" fill="#DFDD4E"/>
<path d="M225.983 158.336C226.814 158.303 227.57 158.308 228.345 158.647C228.476 158.704 228.603 158.767 228.727 158.836C228.851 158.905 228.972 158.981 229.088 159.063C229.204 159.145 229.316 159.233 229.423 159.326C229.53 159.42 229.632 159.518 229.73 159.622C229.827 159.726 229.918 159.835 230.004 159.948C230.09 160.062 230.17 160.18 230.244 160.301C230.318 160.423 230.385 160.548 230.446 160.677C230.507 160.805 230.561 160.937 230.609 161.071C230.982 162.138 230.927 163.361 230.41 164.372C229.783 165.599 228.744 166.153 227.479 166.545C226.517 166.581 225.757 166.556 224.88 166.105C224.75 166.039 224.624 165.967 224.501 165.888C224.379 165.81 224.261 165.725 224.147 165.634C224.034 165.543 223.925 165.447 223.821 165.345C223.717 165.243 223.619 165.136 223.526 165.024C223.433 164.912 223.346 164.795 223.265 164.674C223.184 164.553 223.11 164.428 223.042 164.299C222.974 164.171 222.913 164.039 222.858 163.904C222.803 163.769 222.756 163.631 222.715 163.491C222.677 163.355 222.645 163.216 222.62 163.076C222.596 162.936 222.579 162.795 222.569 162.653C222.559 162.511 222.556 162.369 222.561 162.227C222.566 162.085 222.578 161.944 222.597 161.803C222.616 161.662 222.643 161.523 222.677 161.384C222.71 161.246 222.751 161.11 222.799 160.976C222.847 160.842 222.901 160.711 222.963 160.583C223.024 160.455 223.092 160.33 223.166 160.209C223.812 159.166 224.821 158.609 225.983 158.336Z" fill="#DFDD4E"/>
<path d="M107.575 5.31177C108.464 5.25231 109.296 5.25982 110.138 5.59135C111.36 6.07214 112.346 7.00728 112.833 8.23672C112.891 8.38206 112.941 8.53011 112.984 8.68057C113.026 8.83102 113.061 8.98357 113.088 9.13762C113.115 9.29168 113.133 9.44694 113.144 9.60309C113.155 9.75925 113.158 9.91541 113.153 10.0719C113.147 10.2283 113.134 10.3839 113.112 10.5388C113.091 10.6941 113.062 10.8475 113.024 10.9995C112.987 11.1515 112.942 11.301 112.889 11.4485C112.837 11.5956 112.776 11.7397 112.709 11.8809C112.068 13.2025 110.989 13.8689 109.669 14.3662C108.772 14.4608 107.914 14.4785 107.048 14.1869C106.899 14.138 106.753 14.0812 106.611 14.0166C106.468 13.9524 106.329 13.8806 106.193 13.8016C106.058 13.7226 105.927 13.6365 105.801 13.5437C105.675 13.4509 105.553 13.3518 105.437 13.2461C105.321 13.1407 105.211 13.0292 105.107 12.9121C105.003 12.795 104.905 12.6728 104.814 12.5452C104.723 12.4175 104.638 12.2857 104.561 12.1494C104.483 12.013 104.413 11.8731 104.35 11.7292C103.832 10.54 103.808 9.14784 104.347 7.96104C105.009 6.50157 106.138 5.82619 107.575 5.31177Z" fill="#F2674A"/>
<path d="M121.963 96.6134C122.966 96.5654 123.842 96.6011 124.792 96.9861C124.942 97.0452 125.089 97.1122 125.232 97.1867C125.375 97.2612 125.514 97.3429 125.648 97.4317C125.783 97.5206 125.912 97.6161 126.037 97.7185C126.162 97.8209 126.281 97.9293 126.395 98.0444C126.508 98.1591 126.615 98.2792 126.716 98.405C126.817 98.5308 126.912 98.6618 126.999 98.7975C127.087 98.9333 127.167 99.0732 127.24 99.217C127.313 99.3612 127.379 99.5086 127.436 99.6594C127.908 100.879 127.878 102.303 127.315 103.487C126.619 104.954 125.412 105.63 123.966 106.177C122.864 106.259 121.861 106.231 120.849 105.743C120.705 105.673 120.564 105.596 120.427 105.512C120.29 105.428 120.157 105.337 120.029 105.24C119.901 105.142 119.778 105.039 119.661 104.929C119.544 104.819 119.432 104.703 119.326 104.582C119.22 104.461 119.121 104.334 119.028 104.203C118.935 104.072 118.849 103.936 118.769 103.796C118.69 103.656 118.618 103.512 118.553 103.365C118.488 103.217 118.431 103.067 118.382 102.914C117.972 101.679 118.042 100.29 118.631 99.1251C119.346 97.7131 120.531 97.0816 121.963 96.6134Z" fill="#F2674A"/>
<path d="M116.454 173.876C119.708 172.847 122.921 172.051 126.271 171.408C125.274 174.401 124.463 177.68 124.788 180.862C123 181.811 120.712 182.957 118.759 183.467L116.454 173.876Z" fill="white" fill-opacity="0.1"/>
<path d="M93.6792 109.103C94.711 109.022 95.9064 109.379 96.7478 109.975C97.9133 110.799 98.5898 112.169 98.7598 113.566C98.9333 114.994 98.5775 116.463 97.6807 117.598C96.6942 118.847 95.4256 119.328 93.9034 119.537C92.7344 119.501 91.5839 119.162 90.6382 118.461C90.5686 118.409 90.5006 118.356 90.4337 118.301C90.3669 118.245 90.3018 118.188 90.238 118.13C90.1741 118.071 90.1121 118.011 90.0515 117.949C89.9907 117.887 89.9316 117.824 89.8744 117.759C89.8171 117.694 89.7614 117.627 89.7074 117.559C89.6538 117.491 89.6016 117.422 89.5512 117.352C89.5009 117.281 89.4523 117.209 89.4059 117.136C89.3594 117.063 89.3147 116.989 89.2719 116.913C89.229 116.838 89.1882 116.761 89.1496 116.684C89.1109 116.606 89.074 116.528 89.0396 116.448C89.0048 116.369 88.9721 116.289 88.9415 116.207C88.9113 116.126 88.8828 116.044 88.8564 115.962C88.8303 115.879 88.806 115.796 88.7842 115.712C88.7623 115.628 88.7425 115.544 88.7251 115.459C88.7074 115.374 88.6921 115.288 88.6789 115.202C88.6658 115.117 88.655 115.031 88.6463 114.945C88.4982 113.534 88.8993 111.971 89.8057 110.869C90.8351 109.616 92.1453 109.271 93.6792 109.103Z" fill="white"/>
<path d="M174.315 24.2356C175.518 24.1669 176.526 24.1693 177.655 24.681C177.816 24.7551 177.974 24.8371 178.127 24.9269C178.281 25.0167 178.43 25.1143 178.573 25.2191C178.717 25.3239 178.855 25.4359 178.987 25.5546C179.12 25.6732 179.246 25.7984 179.366 25.9299C179.486 26.0615 179.599 26.1987 179.705 26.3416C179.811 26.4843 179.91 26.6323 180.002 26.7852C180.093 26.938 180.176 27.0948 180.252 27.2561C180.327 27.4173 180.394 27.5822 180.454 27.7501C180.514 27.9218 180.566 28.0963 180.61 28.2732C180.654 28.4501 180.689 28.6285 180.715 28.8089C180.742 28.9891 180.759 29.1702 180.768 29.3522C180.777 29.5345 180.777 29.7164 180.768 29.8984C180.759 30.0804 180.742 30.2615 180.715 30.4417C180.689 30.6222 180.653 30.8005 180.61 30.9774C180.566 31.1543 180.514 31.3288 180.453 31.5005C180.393 31.6723 180.324 31.8408 180.247 32.0059C179.487 33.6045 178.215 34.3696 176.61 34.9111C175.298 35.1081 174.007 34.9597 172.842 34.2891C171.544 33.5417 170.652 32.2693 170.274 30.8279C169.902 29.4041 170.069 27.86 170.836 26.5924C171.661 25.229 172.82 24.6056 174.315 24.2356Z" fill="#4EDF6D"/>
<path d="M176.205 25.1445C176.889 25.1541 177.514 25.2851 178.178 25.4295C178.721 26.0532 179.111 26.6545 179.309 27.4695C179.564 28.516 179.435 29.6313 178.867 30.5557C178.221 31.6073 177.228 32.1464 176.067 32.4491C175.391 32.4863 174.616 32.4731 174.009 32.1311C173.491 31.8395 173.153 31.329 172.986 30.7692C172.637 29.5965 172.835 28.0665 173.445 27.01C174.118 25.8433 174.957 25.471 176.205 25.1445Z" fill="#DFDD4E"/>
<path d="M84.2147 229.341C85.2198 229.26 86.1799 229.261 87.1352 229.623C87.2276 229.657 87.319 229.694 87.4095 229.733C87.5 229.773 87.5894 229.814 87.6778 229.858C87.7662 229.902 87.8532 229.948 87.9392 229.997C88.0252 230.045 88.1101 230.096 88.1934 230.149C88.2767 230.202 88.3586 230.257 88.4392 230.314C88.5198 230.371 88.5987 230.43 88.6763 230.491C88.7536 230.552 88.8295 230.615 88.9038 230.68C88.9779 230.745 89.0504 230.812 89.1214 230.881C89.1922 230.95 89.2611 231.02 89.3283 231.093C89.3954 231.165 89.4608 231.239 89.524 231.315C89.5873 231.391 89.6487 231.468 89.7081 231.547C89.7674 231.626 89.8247 231.706 89.8798 231.788C89.935 231.87 89.988 231.954 90.039 232.038C90.09 232.123 90.1385 232.208 90.185 232.296C90.2314 232.383 90.2758 232.471 90.3178 232.56C90.3594 232.65 90.399 232.74 90.4365 232.832C90.4721 232.92 90.5057 233.009 90.5369 233.099C90.5684 233.19 90.5971 233.28 90.6235 233.372C90.6502 233.463 90.6742 233.555 90.6961 233.648C90.7179 233.741 90.7371 233.834 90.7542 233.928C90.7713 234.022 90.7857 234.116 90.798 234.211C90.81 234.305 90.8196 234.4 90.8268 234.495C90.8342 234.59 90.839 234.685 90.8411 234.781C90.8435 234.876 90.8435 234.971 90.8408 235.066C90.8382 235.161 90.833 235.257 90.8256 235.352C90.8181 235.447 90.8079 235.542 90.7956 235.636C90.7833 235.731 90.7683 235.825 90.7512 235.918C90.7341 236.012 90.7144 236.105 90.6922 236.198C90.6703 236.291 90.6457 236.383 90.619 236.474C90.592 236.566 90.563 236.657 90.5315 236.746C90.5 236.836 90.4661 236.925 90.4302 237.014C90.3939 237.102 90.3555 237.189 90.3151 237.275C89.5267 238.962 88.1748 239.8 86.4842 240.377C85.242 240.495 84.0822 240.443 82.9296 239.91C81.5783 239.285 80.5894 238.157 80.1017 236.753C79.5907 235.283 79.6407 233.568 80.3658 232.178C81.1965 230.585 82.5834 229.87 84.2147 229.341Z" fill="#4EDF6D"/>
<path d="M233.291 95.069C233.623 95.0447 233.979 95.0165 234.308 95.0846C235.961 95.4279 236.515 98.3342 237.315 99.5889C237.348 99.6417 237.384 99.6934 237.418 99.7456C238.719 100.297 241.278 101.131 241.871 102.477C242.038 102.856 242.041 103.139 241.891 103.525C241.338 104.956 238.539 105.748 237.226 106.325C236.141 107.834 235.873 109.701 234.425 110.983C234.048 111.039 233.71 111.066 233.335 110.969C231.817 110.574 230.943 107.337 230.169 106.067C230.141 106.02 230.112 105.975 230.083 105.929C228.857 105.492 226.534 104.819 225.872 103.616C225.667 103.243 225.631 102.983 225.764 102.577C226.201 101.232 229.273 100.225 230.462 99.7009C231.424 98.1039 231.778 96.2925 233.291 95.069Z" fill="#DFDD4E"/>
<path d="M72.3989 15.5889C74.2835 16.3075 75.1519 19.2342 76.6066 20.5883C78.2502 21.0297 80.9843 20.7099 82.3332 21.721C82.5679 21.897 82.7342 22.1511 82.739 22.4466C82.7657 24.1036 79.1012 25.4568 78.2646 26.9157C77.2859 28.622 77.6219 30.9007 76.5646 32.5584C76.4115 32.7992 76.216 33.0166 76.0242 33.2268C73.9633 32.743 73.0206 29.7454 71.5704 28.3625C69.8854 27.8703 67.9112 27.9505 66.3207 27.2682C65.8732 27.076 65.5791 26.867 65.4053 26.3925C65.5833 24.8616 69.1301 23.4892 70.2053 22.3096C70.2482 22.2625 70.2901 22.2144 70.3324 22.167C70.7946 20.0925 70.6597 17.0108 72.3989 15.5889Z" fill="#DFDD4E"/>
<path d="M152.364 0C153.651 0.966974 154.685 2.23575 155.71 3.46489C150.022 9.05682 147.457 17.1995 147.158 25.0554C147.129 25.8191 147.251 28.7284 147.025 29.1227L146.684 28.6005C145.124 24.8389 142.428 20.5524 142.567 16.3554C142.77 10.225 148.095 3.95258 152.364 0Z" fill="#DFDD4E"/>
<path d="M170.186 216.748L171.772 216.65C173.865 219.904 175.999 223.005 178.951 225.56C180.419 226.83 182.557 227.827 183.72 229.368C184.123 229.903 184.153 230.75 184.06 231.39C183.911 232.419 183.018 233.912 182.153 234.488C181.715 234.779 181.405 234.856 180.884 234.746C175.82 232.392 169.044 227.111 166.935 221.792C166.599 220.942 166.445 219.99 166.849 219.134C167.476 217.808 168.888 217.21 170.186 216.748Z" fill="#FF2A2A"/>
<path d="M69.8879 202.455C71.8297 202.293 76.6339 204.369 78.4634 205.322C78.226 205.353 77.9936 205.386 77.7589 205.432C70.1352 206.924 61.8691 215.165 57.725 221.348L56.4966 223.26C54.6659 222.384 52.8959 221.306 51.1465 220.278C54.0781 214.611 59.9656 207.841 65.2908 204.54C66.7572 203.632 68.2581 203.007 69.8879 202.455Z" fill="#4A7AF2"/>
<path d="M154.325 144.476C154.535 145.022 154.701 145.553 155.112 145.984C154.343 151.08 152.673 155.375 148.401 158.514C146.565 159.862 144.511 160.804 142.449 161.744C137.784 163.87 134.038 166.272 131.082 170.571C129.113 173.105 128.297 176.311 128.002 179.461C126.936 179.938 125.873 180.427 124.789 180.862C124.463 177.68 125.275 174.401 126.271 171.408C126.513 170.83 126.776 170.262 127.061 169.705C127.345 169.147 127.651 168.601 127.977 168.066C128.304 167.532 128.65 167.011 129.016 166.503C129.383 165.996 129.768 165.502 130.172 165.024C134.611 159.671 139.515 158.067 145.305 154.869C149.666 152.459 152.926 149.338 154.325 144.476Z" fill="#F2674A"/>
<path d="M26.9302 113.724C30.0842 113.558 34.7625 116.374 37.1108 118.329C41.2457 121.771 45.0874 127.268 45.57 132.759C45.4207 133.116 45.2708 133.479 45.0676 133.809C44.377 134.928 43.4273 135.325 42.1947 135.613C41.448 135.634 40.6956 135.556 40.1024 135.052C38.6438 133.812 38.05 131.383 37.1561 129.715C36.0965 127.736 34.8702 125.994 33.2389 124.442C31.196 122.499 29.1394 121.741 26.5891 120.678C25.4773 120.215 24.3556 119.489 23.9287 118.301C23.6511 117.528 23.7315 116.705 24.0816 115.97C24.6676 114.74 25.6946 114.155 26.9302 113.724Z" fill="#F2674A"/>
<path d="M226.173 131.634C229.837 131.304 237.043 132.372 240.403 134.121C241.048 135.204 241.356 136.027 241.181 137.3C241.056 138.216 240.667 139.097 239.893 139.639C238.09 140.903 235.064 139.271 233.145 138.877C226.191 137.449 219.119 137.86 213.098 141.84C209.581 144.313 207.235 148.226 204.605 151.558C206.039 148.011 209.672 140.202 212.059 137.474C215.677 133.34 220.897 131.992 226.173 131.634Z" fill="#4EDF6D"/>
<path d="M102.943 57.0908C106.218 60.3188 110.175 62.168 114.809 62.1416C118.479 62.1209 121.072 61.068 124.191 59.2863C124.123 63.1443 123.482 66.5431 120.584 69.3179C118.715 71.1083 116.105 72.272 113.498 72.2606C112.176 72.2594 110.874 72.1705 109.582 71.8843C109.481 71.8567 109.381 71.8306 109.282 71.7981C106.524 70.8972 104.261 69.0945 102.975 66.463C101.491 63.4266 101.869 60.1812 102.943 57.0908Z" fill="#4EDF6D"/>
<path d="M149.257 124.797C153.723 128.715 160.405 131.485 166.414 131.067C167.501 130.992 168.867 130.678 169.936 130.79C169.585 134.072 168.703 137.322 165.987 139.452C163.841 141.136 161.102 141.666 158.44 141.29C157.666 141.181 156.938 140.995 156.197 140.751L154.591 140.122C152.33 138.963 150.295 137.1 149.186 134.792C147.582 131.453 148.065 128.169 149.257 124.797Z" fill="#4A7AF2"/>
<path d="M73.3545 94.0415C75.1234 94.0739 76.9054 94.1872 78.6629 93.9307C83.3217 93.2514 88.2 90.1295 90.7806 86.1766C91.3064 85.3712 91.7284 83.8291 92.5818 83.3838C94.5776 85.858 96.1037 88.6982 95.7305 91.9838C95.3804 95.0689 93.5899 98.0136 91.1544 99.8989C88.6601 101.83 85.0921 103.029 81.9326 102.546C79.3394 102.151 77.1461 100.596 75.6407 98.4815C74.6737 97.1235 73.9951 95.5737 73.3545 94.0415Z" fill="#FF2A2A"/>
<path d="M34.4679 37.0005C34.8192 36.9801 35.1702 36.9852 35.5209 37.0159C36.4136 37.0888 37.1717 37.4149 37.7655 38.0975C39.1474 39.6858 39.9118 42.3018 41.1285 44.1366C42.5191 46.2336 44.4816 47.8562 46.1209 49.7472C50.7084 55.0385 51.2396 60.8151 50.7684 67.5734L49.4929 65.1728C46.573 60.5034 43.5787 58.634 39.7979 54.9775C38.6117 53.8139 37.4975 52.5847 36.455 51.2904C35.4121 49.9961 34.4484 48.6454 33.5635 47.2381C32.6699 45.803 31.7143 44.1823 31.2191 42.5549C30.872 41.4141 30.7731 40.2048 31.3714 39.1279C32.015 37.9702 33.2359 37.3282 34.4679 37.0005Z" fill="#FF2A2A"/>
<path d="M118.855 235.216C120.215 234.231 121.687 233.369 123.08 232.427C125.889 230.528 128.565 228.511 131.098 226.256C132.083 225.379 135.251 222 136.276 221.682C135.543 227.313 134.75 232.936 133.895 238.55C133.437 241.552 133.11 244.745 132.277 247.662C132.068 248.396 131.833 249.363 131.168 249.82C130.922 249.989 130.703 249.99 130.419 250C128.089 249.274 120.699 237.859 118.855 235.216Z" fill="#4EDF6D"/>
<path d="M3.40274 68.1027C8.96833 67.9027 14.8436 69.0924 19.7884 71.6423C29.7553 76.782 30.6872 83.3382 33.8056 93.1235L32.4402 91.0295C27.2262 83.1553 18.2036 76.9535 8.72103 75.8432C6.48874 75.582 3.28793 76.1294 1.38387 74.7601C1.29304 74.6955 1.20552 74.6261 1.12158 74.5525C1.03765 74.479 0.957615 74.4012 0.881777 74.3192C0.805938 74.2372 0.734597 74.1516 0.667451 74.0625C0.600306 73.9733 0.537956 73.8808 0.480403 73.785C0.422849 73.6892 0.370392 73.591 0.32273 73.4898C0.275069 73.3886 0.233104 73.2853 0.196233 73.1799C0.159363 73.0745 0.127888 72.9673 0.102109 72.8585C0.0760298 72.7498 0.0559452 72.6402 0.0412571 72.5294C0.0223724 72.3994 0.00978243 72.2691 0.00408704 72.1378C-0.00190811 72.0069 -0.00130729 71.8759 0.00588688 71.7447C0.0130811 71.6135 0.0265706 71.4832 0.0466543 71.3537C0.0667381 71.224 0.0934163 71.0955 0.12609 70.9684C0.159063 70.8414 0.198331 70.7165 0.243594 70.5934C0.288858 70.4702 0.340416 70.3495 0.397669 70.2315C0.454923 70.1135 0.518171 69.9988 0.586815 69.887C0.65546 69.7753 0.729801 69.6672 0.809237 69.563C1.50647 68.6345 2.27985 68.278 3.40274 68.1027Z" fill="#DFDD4E"/>
<path d="M89.4951 150.549L90.1273 150.165C91.713 153.382 93.9333 154.993 97.2957 156.131C99.1407 156.756 101.036 157.237 102.867 157.903C107.718 159.669 112.034 162.774 114.385 167.477C115.391 169.49 115.982 171.684 116.454 173.877L118.759 183.468C117.483 183.987 116.211 184.437 114.893 184.836C113.343 185.356 111.57 186.073 109.961 186.335C108.778 183.287 108.258 179.872 106.903 176.924C108.73 176.305 110.526 175.605 112.291 174.826C112.038 174.067 111.775 173.313 111.499 172.562C109.902 168.232 107.492 164.927 103.145 163.102C101.41 162.374 99.5981 161.816 97.8928 161.017C93.3134 158.872 91.1744 155.146 89.4951 150.549Z" fill="#F2674A"/>
<path d="M112.291 174.825C113.202 178.148 113.871 181.546 114.893 184.835C113.343 185.355 111.57 186.073 109.961 186.335C108.779 183.287 108.259 179.871 106.903 176.923C108.73 176.304 110.526 175.605 112.291 174.825Z" fill="#2C2C2C"/>
<path d="M210.005 36.1032C210.448 36.099 210.873 36.1128 211.307 36.2113C211.448 36.2431 211.586 36.2822 211.723 36.3284C211.86 36.375 211.994 36.4281 212.125 36.4885C212.257 36.5485 212.384 36.6155 212.509 36.6891C212.633 36.7627 212.753 36.8422 212.869 36.9281C212.985 37.0143 213.097 37.1059 213.203 37.2035C213.31 37.3011 213.411 37.4041 213.507 37.5122C213.603 37.62 213.693 37.7329 213.778 37.8504C213.862 37.9678 213.94 38.0894 214.011 38.2149C216.882 43.2123 208.874 46.3312 205.777 48.5447C204.723 49.2946 203.716 50.1039 202.758 50.973C201.799 51.8417 200.895 52.7643 200.045 53.7402C196.278 58.0694 194.136 63.4514 192.677 68.9374L192.014 71.6677C191.656 70.4244 191.348 69.1386 191.107 67.8677C189.767 60.791 190.33 53.624 194.516 47.5633C197.257 43.5957 201.026 40.9002 205.075 38.4203C206.615 37.4774 208.207 36.4401 210.005 36.1032Z" fill="#FF2A2A"/>
<path d="M102.943 57.0909C104.841 52.2882 109.932 45.6809 114.796 43.5905C115.849 43.138 117.042 42.8896 118.138 43.3461C120.078 44.1533 121.277 47.4371 121.953 49.2641C123.146 52.4903 123.96 55.8486 124.191 59.2864C121.072 61.0681 118.479 62.121 114.809 62.1417C110.175 62.1681 106.218 60.3189 102.943 57.0909Z" fill="#4EDF6D"/>
<path d="M189.463 140.602C189.71 140.614 189.667 140.605 189.9 140.638C191.788 140.906 193.005 141.783 194.116 143.279L194.134 143.537C194.424 148.198 191.619 153.197 188.592 156.567C185.764 159.716 182.409 162.149 179.101 164.745C177.374 166.101 175.583 167.808 173.568 168.709C173.017 168.956 172.46 168.993 171.864 169.027C170.785 169.008 169.724 168.886 168.748 168.384C168.061 168.03 167.417 167.419 167.183 166.664C166.791 165.393 167.53 164.002 168.274 163.019C169.441 161.478 171.109 160.235 172.62 159.047C175.875 156.49 179.8 153.91 182.459 150.721C184.72 148.007 185.124 145.197 186.716 142.495C187.436 141.273 188.117 140.925 189.463 140.602Z" fill="#DFDD4E"/>
<path d="M149.257 124.796C151.312 119.456 155.686 113.232 161.056 110.824C162.026 110.389 163.131 110.136 164.157 110.537C166.071 111.287 167.27 113.905 167.977 115.707C169.354 119.215 170.85 127.115 169.936 130.789C168.868 130.677 167.502 130.991 166.414 131.066C160.405 131.484 153.723 128.714 149.257 124.796Z" fill="#4A7AF2"/>
<path d="M73.3552 94.0418C71.6268 90.3997 70.3451 84.3393 70.6805 80.2858C70.8331 78.4395 71.45 76.6407 72.9365 75.4479C74.444 74.2386 76.4487 73.9197 78.3249 74.1344C83.5164 74.7287 89.4294 79.4125 92.5826 83.384C91.7292 83.8294 91.3071 85.3714 90.7813 86.1768C88.2007 90.1297 83.3225 93.2517 78.6637 93.9309C76.9062 94.1874 75.1241 94.0742 73.3552 94.0418Z" fill="#F2674A"/>
<path d="M42.9027 160.158C43.92 160.09 44.9374 160.092 45.9545 160.163C46.9716 160.235 47.979 160.376 48.9769 160.586C57.4927 162.447 64.359 170.428 68.9015 177.46C63.1096 173.522 59.5967 171.355 52.6852 169.694C46.3307 168.466 40.9962 169.418 35.2262 172.231C33.6572 172.996 31.9336 174.079 30.1932 174.356C28.9433 174.555 27.5755 174.337 26.5533 173.555C25.5269 172.77 24.9043 171.442 24.7712 170.173C24.7616 170.087 24.7542 170.001 24.7491 169.914C24.744 169.828 24.741 169.742 24.7404 169.656C24.7398 169.569 24.7413 169.483 24.7449 169.396C24.7488 169.31 24.7548 169.224 24.7628 169.138C24.7709 169.052 24.7814 168.966 24.794 168.881C24.8066 168.795 24.8216 168.71 24.8387 168.626C24.8558 168.541 24.8747 168.456 24.8959 168.373C24.9172 168.289 24.9409 168.206 24.9667 168.123C24.9922 168.041 25.02 167.959 25.0497 167.878C25.0797 167.797 25.1118 167.717 25.1456 167.638C25.1795 167.558 25.2155 167.48 25.2535 167.402C25.2916 167.324 25.3315 167.248 25.3735 167.172C25.4154 167.097 25.4595 167.023 25.5053 166.949C25.5512 166.876 25.5989 166.804 25.6483 166.733C25.6978 166.663 25.749 166.593 25.8021 166.525C25.8552 166.457 25.91 166.39 25.9667 166.325C27.6294 164.432 35.338 161.645 37.9102 160.974C39.5649 160.542 41.1988 160.286 42.9027 160.158Z" fill="#4A7AF2"/>
<path d="M167.261 49.3691C168.114 49.5232 169.067 49.7839 169.864 50.1265C171.28 50.7337 172.652 51.7632 173.209 53.2512C173.492 54.0067 173.546 54.8524 173.317 55.6286C172.872 57.1323 171.748 58.4827 171.008 59.8596C170.11 61.5314 169.326 63.3245 168.55 65.0572C165.246 72.4414 162.466 83.4147 164.442 91.3451C164.597 91.9694 164.886 92.5364 165.083 93.143C165.2 93.5031 165.341 93.8184 165.171 94.1619L164.658 93.8797C162.354 92.1376 158.963 89.8061 157.603 87.2367C156.946 85.9952 156.627 84.5514 156.434 83.17C154.852 71.8294 160.509 58.2971 167.261 49.3691Z" fill="#DFDD4E"/>
<path d="M111.632 224.573C112.823 223.586 114.502 222.848 115.838 222.038C117.927 220.763 119.943 219.381 121.884 217.891C126.251 214.536 130.231 210.786 134.029 206.801C135.655 205.093 137.166 203.245 138.831 201.583L138.951 201.465C138.477 205.487 137.855 209.495 137.329 213.512C136.976 216.214 136.762 219.008 136.275 221.682C135.25 222 132.082 225.379 131.097 226.256C128.565 228.511 125.888 230.528 123.08 232.427C121.687 233.369 120.214 234.231 118.855 235.216C116.279 231.834 114.02 228.1 111.632 224.573Z" fill="#F2674A"/>
<path d="M131.082 170.571C134.137 170.208 138.538 169.555 141.206 171.568C141.566 171.84 141.924 172.29 141.995 172.747C142.069 173.217 142.054 174.04 141.897 174.475L141.759 174.149C141.243 174.635 140.912 175.247 140.438 175.77C139.957 176.302 139.321 176.748 138.745 177.173C129.083 184.301 106.399 192.338 94.4594 192.009C92.9387 191.968 91.4121 191.775 89.9142 191.515C89.6207 191.464 89.7442 191.477 89.419 191.484C88.9861 191.07 88.4657 190.416 88.3075 189.835C88.0886 189.033 88.4091 188.415 88.8003 187.73C91.7232 182.967 101.748 178.983 106.904 176.923C108.259 179.871 108.779 183.287 109.961 186.335C111.57 186.073 113.343 185.355 114.893 184.836C116.212 184.436 117.483 183.987 118.76 183.467C120.712 182.957 123 181.811 124.788 180.862C125.872 180.427 126.935 179.938 128.002 179.46C128.297 176.311 129.113 173.105 131.082 170.571Z" fill="white" fill-opacity="0.1"/>
<path d="M109.582 71.8838C110.874 72.17 112.177 72.2589 113.498 72.2601C113.504 75.5946 113.609 80.0145 112.764 83.2064C111.924 86.3758 108.654 89.0503 109.076 92.5239C109.365 94.9044 110.755 97.1623 111.892 99.2266C113.161 101.534 114.472 103.834 115.967 106.002C121.495 114.017 126.339 119.05 128.684 129.028C129.529 132.625 129.902 136.486 129.663 140.176C127.154 134.059 123.601 128.291 119.506 123.113C112.95 114.821 103.906 108.924 103.549 97.2464C103.414 92.8029 104.777 89.6434 107.334 86.1337C108.36 84.7262 109.28 83.3737 109.671 81.6469C110.372 78.5502 109.718 75.0289 109.582 71.8838Z" fill="#DFDD4E"/>
<path d="M89.4948 150.549C89.1165 150.946 88.7313 151.337 88.3395 151.722C85.7937 154.192 81.868 156.163 78.262 156.059C78.116 156.054 77.97 156.045 77.8243 156.032C77.6786 156.02 77.5333 156.004 77.3885 155.984C77.2437 155.964 77.0995 155.941 76.9556 155.914C76.812 155.888 76.6691 155.858 76.527 155.824C76.3846 155.79 76.2434 155.752 76.1028 155.711C75.9625 155.67 75.8231 155.626 75.6849 155.579C75.5468 155.531 75.4101 155.479 75.2743 155.425C75.1385 155.371 75.0042 155.313 74.8714 155.252C74.7386 155.191 74.6076 155.126 74.4778 155.058C74.348 154.991 74.2203 154.92 74.0941 154.846C73.9679 154.772 73.8438 154.695 73.7215 154.615C73.5989 154.535 73.4787 154.452 73.3606 154.365C73.2422 154.279 73.1262 154.19 73.0123 154.098C72.8984 154.007 72.7869 153.912 72.6778 153.815C72.5684 153.717 72.4617 153.617 72.3577 153.515C72.2533 153.412 72.1517 153.307 72.0525 153.199C68.2477 149.072 66.1407 139.749 66.4039 134.268C66.5061 132.134 66.8826 129.361 68.5942 127.891C69.6643 126.973 71.1505 126.744 72.5087 126.918C78.3993 127.668 85.6345 133.747 89.1525 138.356C91.3161 141.191 92.7444 144.385 91.0967 147.91C90.8305 148.48 90.1503 149.431 90.1174 150.034C90.115 150.078 90.1237 150.122 90.127 150.165L89.4948 150.549Z" fill="#DFDD4E"/>
<path d="M141.261 181.256C140.996 185.005 140.441 188.743 140.012 192.476C139.669 195.454 139.463 198.516 138.951 201.465L138.83 201.583C137.166 203.245 135.655 205.093 134.029 206.8C130.231 210.786 126.251 214.536 121.884 217.891C119.942 219.381 117.927 220.763 115.838 222.038C114.502 222.847 112.823 223.586 111.632 224.573C110.268 222.698 109.036 220.706 107.736 218.783C106.181 216.481 104.562 214.223 103.04 211.899C104.417 211.124 105.974 210.559 107.405 209.882C109.368 208.953 111.284 207.893 113.157 206.794C124.14 200.346 133.196 190.992 141.261 181.256Z" fill="#4EDF6D"/>
<path d="M89.4189 191.485C89.7442 191.478 89.6207 191.464 89.9141 191.515C91.412 191.776 92.9387 191.968 94.4594 192.01C106.399 192.339 129.083 184.302 138.745 177.174C139.321 176.749 139.957 176.303 140.438 175.77C140.912 175.247 141.243 174.636 141.759 174.149L141.897 174.476C141.665 176.734 141.453 178.994 141.261 181.256C133.196 190.993 124.141 200.347 113.157 206.794C111.285 207.893 109.369 208.954 107.406 209.882C105.974 210.56 104.417 211.124 103.041 211.9C100.752 208.641 98.6023 205.267 96.3925 201.954C94.0661 198.466 91.6725 195.02 89.4189 191.485Z" fill="#F2674A"/>
</svg>

      </Box>

      {/* Order Information */}
      <CardContent sx={{padding:'0px', paddingBottom:'0px !important'}}>
        <Typography
          variant="h5"
          sx={{ textAlign: "center", fontWeight: "bold", marginBottom: 2 }}
        >
          Order Successful | {orderDetails?.symbol}
        </Typography>

        <Divider sx={{ backgroundColor: "#444", marginBottom: '20px', marginTop:'10px' }} />

        {/* Order Details */}
        <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box sx={{ marginBottom: 1 }}>
          <Typography variant="body2" className='text-secondary'>
            Order ID
          </Typography>
          <Typography variant="h6">{orderDetails?.orderId}</Typography>
        </Box>
        <Box sx={{ marginBottom: 1 }}>
          <Typography variant="body2" className='text-secondary'>
            Date & Time
          </Typography>
          <Typography variant="h6">{orderDetails?.dateTime}</Typography>
        </Box>
        </Box>

        <Divider sx={{ backgroundColor: "#444", margin: "20px 0" }} />

        {/* Financial Details */}
        {/* <Box mb='10px' sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body2" className='text-secondary'>
            Estimated Cash
          </Typography>
          <Typography variant="body2">$1,890.00</Typography>
        </Box>
        <Box mb='10px' sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body2"  className='text-secondary'>
            Booster Loan
          </Typography>
          <Typography variant="body2">$2,080.00</Typography>
        </Box> */}
        <Box mb='10px'  sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className='text-secondary' variant="body2" >
            Total Qty.
          </Typography>
          <Typography variant="body2">{orderDetails?.totalQty}</Typography>
        </Box>

        <Divider sx={{ backgroundColor: "#444", margin: "20px 0" }} />

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body2" color="#aaa">
            Total Estimated Amount
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            ${orderDetails?.totalAmount}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrderSuccessCard;
