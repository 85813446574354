import { Box, Button, Container, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { UrlConstant } from "../../commom/UrlConstant";
import Axios from "../../config/axios";
import LeftSide from "../common/Layout/LeftSide";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/authSlice";
import Successpopup from "../common/SharedComponent/Successpopup";

function Otp() {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userEmail = location.state?.email;
  const email = userEmail;
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility


  useEffect(() => {
    if (!email) {
      navigate("/");
    }
  }, [email, navigate]);

  const handleSubmit = async () => {
    if (otp.length !== 6) {
      // Swal.fire({
      //   title: "Invalid OTP",
      //   text: "Please enter a 6-digit OTP.",
      //   icon: "error",
      // });
      setheading('Invalid OTP')
      setShowPopup(true)
      setmsg('Please enter a 6-digit OTP.')
      return;
    }

    const payLoad = {
      email: email,
      otp: otp,
    };

    try {
      const response = await Axios.post(
        UrlConstant.Auth.VerifyEmailByOTP,
        payLoad
      );
      const resData = response.data;

      if (resData.result === true) {
        dispatch(logout());
        navigate("/login");
      } else {
        // Swal.fire({
        //   title: "Failed",
        //   text: resData.msg,
        //   icon: "error",
        // });
        setheading('Faild')
        setShowPopup(true)
        setmsg(resData.msg)
      }
    } catch (error: any) {
      // Swal.fire({
      //   title: "Failed",
      //   text: error.response?.data?.msg || "An error occurred.",
      //   icon: "error",
      // });
      setheading('Failed')
      setShowPopup(true)
      setmsg(error.response?.data?.msg || "An error occurred.")
    }
  };

  const resendOTP = async () => {
    try {
      const response = await Axios.post(UrlConstant.Auth.ResendOTP, {
        email,
      });
      const resData = response.data;
      if (resData.result === true) {
        // Swal.fire({
        //   title: "Success",
        //   text: "Successfully sent OTP.",
        //   icon: "info",
        // });
        setheading('Success')
        setSuccess(true)
        setShowPopup(true)
        setmsg('Successfully sent OTP.')
      }
    } catch (error: any) {
      // Swal.fire({
      //   title: "Failed",
      //   text: error.response?.data?.msg || "An error occurred.",
      //   icon: "error",
      // });
      setheading('Failed')
      setShowPopup(true)
      setmsg(error.response?.data?.msg || "An error occurred.")
    }
  };
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };

  return (
    <Container
      maxWidth={false}
      className="container_new"
      sx={{ maxWidth: "100%", margin: "0px" }}
    >
      {/* <LeftSide /> */}
      <RightSection className="RightSection bg-color">
        <Typography width="100%">
          <Typography className="Typography fw-700 mb-10 fs-24 heading">
            Verify Your Identity
          </Typography>
          <Typography
            className="Typography fs-18 mb-50 text-secondary"
            variant="body1"
            sx={{ mb: { xs: 10, sm: 10 } }}
          >
            Just one step away from unlocking your access.
          </Typography>
        </Typography>

        <Box>
          <FormLabel className="text-secondary">
            <span>
              OTP{" "}
              <sup>
                <i
                  className="fa-solid fa-star-of-life"
                  style={{ color: "#fa0000", fontSize: "0.5rem" }}
                ></i>
              </sup>
            </span>
          </FormLabel>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            containerStyle={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "20px",
            }}
            inputStyle={{
              width: "64px",
            }}
            renderInput={(props) => (
              <input
                {...props}
                className="otp"
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  } else if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }}
              />
            )}
          />
          <label
            className="link"
            style={{
              textAlign: "end",
              display: "flex",
              justifyContent: "end",
              marginBottom: "20px",
              marginTop: "10px",
              fontSize: "14px",
            }}
            onClick={resendOTP}
          >
            Resend OTP?
          </label>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="btn-primary fw-700 btn mb-10"
            sx={{ fontSize: "16px", paddingY: "12px" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>

        <Typography sx={{ mt: 2 }} className="text-secondary">
          Don't have an account? &nbsp;
          <Link className="link" style={{ fontSize: "14px" }} to="/">
            Sign Up
          </Link>
        </Typography>
      </RightSection>
      {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

    </Container>
  );
}

export default Otp;

const RightSection = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: '70px 120px',
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
