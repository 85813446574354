import { createSlice } from "@reduxjs/toolkit";

const getUserFromLocalStorage = () => {
  const userJson = localStorage.getItem("user");
  return userJson ? JSON.parse(userJson) : null;
};

const initialState = {
  isAuthenticated: localStorage.getItem("isAuthenticated") === "true",
  user: getUserFromLocalStorage(),
  //role: getUserFromLocalStorage()?.role || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
      localStorage.setItem("isAuthenticated", "true");

      localStorage.setItem("user", JSON.stringify(action.payload));
      sessionStorage.setItem("pinVerified", "true");
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      localStorage.removeItem("InvList");
      localStorage.removeItem("customerInfo");
      sessionStorage.removeItem("pinVerified");
    },
  },
});

// Exporting actions generated by createSlice
export const { login, logout } = authSlice.actions;

// Exporting reducer
export default authSlice.reducer;
