import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";

type LineChartProps = {
  data?: { x: string; y: number }[]; // Optional data prop
  width?: string | number;
  height?: string | number;
  timeline?: string | number;
};

const LineChart: React.FC<LineChartProps> = ({
  data,
  width = "100%",
  height = "300px",
  timeline
}) => {

  const validatedData = data || []

  const options: ApexCharts.ApexOptions = {
    chart: {
      id: "basic-line",
      background: "transparent",
      toolbar: { show: false },
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        style: { colors: "white" },
        formatter: (value) => {
          return moment(value).format(timeline == "1 Day" ? "HH:mm" : "MMM DD");
        },
      },
      axisBorder: { show: true },
      axisTicks: { show: false },
      tooltip: { enabled: false },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: "#ccc",
        width: 1,
      },
      labels: { show: true, style: { colors: 'white' } },
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: ["#FF4500"],
    },
    tooltip: {
      enabled: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const price = series[seriesIndex][dataPointIndex];
        const time = new Date(
          w.globals.seriesX[seriesIndex][dataPointIndex]
        ).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });
        const date = moment(w.globals.seriesX[seriesIndex][dataPointIndex]).format(timeline == "1 Day" ? "MM-DD-YYYY, HH:mm:ss" : "MM-DD-YYYY");
        return `<div style="background-color: #333; display: flex; gap: 5px; color: #fff; padding: 5px; border-radius: 5px;">
                  <span>$${price.toFixed(2)}</span>
                  <span>|</span>
                  <span>${date}</span>
                </div>`;
      },
    },
    grid: { show: false },
    markers: {
      size: 0,
      colors: ["#FF4500"],
      strokeWidth: 2,
      hover: { size: 7 },
    },
  };

  const series: ApexAxisChartSeries = [
    {
      name: "Price",
      data: validatedData,
    },
  ];

  return (
    <div className="line-chart">
      <Chart key={timeline} options={options} series={series} type="line" width={width} height={height} />
    </div>
  );
};

export default LineChart;
