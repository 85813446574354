import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "../../style/style.css";
import { styled } from "@mui/system";
import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import LeftSide from "../common/Layout/LeftSide";
import Axios from "../../config/axios";
import { UrlConstant } from "../../commom/UrlConstant";
import Swal from "sweetalert2";
import Successpopup from "../common/SharedComponent/Successpopup";

// Define the shape of your form values
interface ForgotPasswordFormValues {
  email: string;
}

function ForgotPassword() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility


  // Initial values for Formik
  const initialValues: ForgotPasswordFormValues = {
    email: "",
  };

  // Submit handler with type definitions for Formik helpers
  const onSubmit = (
    values: ForgotPasswordFormValues,
    formikHelpers: FormikHelpers<ForgotPasswordFormValues>
  ) => {
    forgotPassword(values);
  };

  const forgotPassword = async (value) => {
    try {
      const response = await Axios.post(UrlConstant.Auth.ForgotPassword, {
        email: value.email,
      });
      const resData = response.data;
      if (resData.result === true) {
        navigate("/reset", { state: { email: value.email } });
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
      console.error(error.response.data.msg);
      // Swal.fire({
      //   title: "Failed",
      //   text: error.response.data.msg,
      //   icon: "error",
      // });
      setheading('Failed')
      setShowPopup(true)
      setmsg(error.response.data.msg)
    }
  };

  // Yup validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
  });

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const formik = useFormik<ForgotPasswordFormValues>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  // Handle keyup event for 'Enter' key
  const keyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      formik.handleSubmit();
    }
  };
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };
  return (
    <Container
      maxWidth={false}
      className="container_new"
      sx={{ maxWidth: "100%", margin: "0px" }}
    >
      {/* <LeftSide /> */}
      <RightSection className="RightSection bg-color">
        <Typography width="100%">
          <Typography className="Typography fw-700 mb-10 fs-24 heading">
            Lost Your Key?
          </Typography>
          <Typography
            className="Typography fs-18 mb-50 text-secondary"
            variant="body1"
            sx={{ mb: { xs: 10, sm: 10 } }}
          >
            Don’t worry, we’ve got you covered. Recover it in seconds.
          </Typography>
        </Typography>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <FormControl fullWidth className="mb-30">
            <TextField
              label={
                <span>
                  Email ID <sup><i className="fa-solid fa-star-of-life" style={{ color: "#fa0000", fontSize: '0.5rem' }}></i></sup>
                </span>
              }
              variant="outlined"
              className="text-secondary"
              onKeyUp={keyup}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${formik.errors.email && submitForm ? "red !important" : ""
                    }`,
                },
              }}
              slotProps={{
                input: {
                  startAdornment: (
                    <IconButton sx={{ backgroundColor: "transparent" }}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 20.5C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5H7Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                  ),
                },
              }}
              InputLabelProps={{
                shrink: false,
              }}
              {...formik.getFieldProps("email")}
            />

            {submitForm && formik.errors.email ? (
              <Typography className="error ">{formik.errors.email}</Typography>
            ) : null}
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="btn-primary fw-700 btn mb-10"
            sx={{ fontSize: '16px', paddingY: '13px' }}
            endIcon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.76001 12.0601H19.93"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            type="submit"
            onClick={() => {
              setSubmitForm(true);
            }}
          >
            Send Otp
          </Button>
        </form>
        <Typography
          sx={{ mt: 2 }}
          className="text-secondary"
          width="100%"
          textAlign="center"
        >
          Don't have an account? &nbsp;{" "}
          <Link to="/" className="link">
            Sign Up
          </Link>
        </Typography>
      </RightSection>
      {showPopup && <Successpopup Success={Success} Heading={heading} onClose={closePopup} Message={msg} />}

    </Container>
  );
}

export default ForgotPassword;

const RightSection = styled(Box)(({ theme }) => ({
  width: "100%",
  // padding: "4rem",
  backgroundColor: "",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: '70px 120px',
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {

  },
  [theme.breakpoints.down("sm")]: {

    padding: '40px 40px'
  },
}));
