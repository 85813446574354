import { Purchases } from "@revenuecat/purchases-js";
import moment from "moment";
import { REVENUECAT_BILLING_PUBLIC_API_KEY } from "../commom/UrlConstant";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
export const isAuthenticated = () => {
  const user = localStorage.getItem("user");
  return user ? true : false;
};

export const getUserFromLocalStorage = () => {
  const userJson = localStorage.getItem("user");
  return userJson ? JSON.parse(userJson) : null;
};

export const getCurrentUserId = () => {
  const userJson = localStorage.getItem("user");
  return userJson ? JSON.parse(userJson)._id : null;
};
export const currencyFormate = (value: any) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value || 0);
};
export function getDateFromTimestamp(timestamp: any) {
  if (parseInt(timestamp) === 0) {
    return "--/--/----";
  }
  const utcMoment = moment.utc(+timestamp);
  return utcMoment.format("DD/MM/YYYY");
}

export function throttle(callback: any, delay = 1000) {
  let shouldWait = false;

  return (...args: any) => {
    if (shouldWait) return;
    callback(...args);
    shouldWait = true;
    setTimeout(() => {
      shouldWait = false;
    }, delay);
  };
}

export const formatPrice = (price: any) => {
  if (price === null || price === undefined) {
    return 0.0; // Default to 0.0 for invalid inputs
  }
  const value = Number(price);
  return parseFloat(value.toFixed(2)).toFixed(2);
};


let purchasesInstance: any = null;

export const configureRevenueCat = () => {
  if (purchasesInstance) return purchasesInstance;

  try {
    const user = getUserFromLocalStorage();
    const appUserId = user?._id; // Replace with your authentication logic
    if (!appUserId) {
      throw new Error("User ID is required for RevenueCat configuration.");
    }
    purchasesInstance = Purchases.configure(
      REVENUECAT_BILLING_PUBLIC_API_KEY,
      appUserId
    );
    return purchasesInstance;
  } catch (error) {
    console.error("Error configuring RevenueCat:", error);
    throw error;
  }
};

export const fetchCustomerInfo = async () => {
  try {
    const customerInfo = await Purchases.getSharedInstance().getCustomerInfo();
    console.log("Customer Info:", customerInfo);

    // Save customer info to localStorage
    localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
    return customerInfo;
  } catch (error) {
    console.error("Failed to fetch customer info:", error);
    throw error;
  }
};

export const getCustomerInfoFromLocalStorage = () => {
  const customerInfoJson = localStorage.getItem("customerInfo");
  return customerInfoJson ? JSON.parse(customerInfoJson) : null;
};

const showPremiumAlert = (navigate) => {

  Swal.fire({
    title: 'Upgrade to Premium',
    text: 'Please upgrade to premium to access this feature.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Get Premium',
    cancelButtonText: 'Cancel',
  }).then((result) => {
    if (result.isConfirmed) {
      // Redirect to the /premium route
      navigate('/premiumSubscription');
    }
  });
};

export default showPremiumAlert;