import React from 'react';
import { Container, Typography, ToggleButtonGroup, ToggleButton, Box, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OpenOrder from '../Tabels/OpenOrder';
import ExecutedOrder from '../Tabels/ExecutedOrder copy';

function Order() {
  const [selectedCategory, setSelectedCategory] = React.useState<String>("Open");
  const navigation = useNavigate();

  const handleSelectedCategory = (
    event: React.MouseEvent<HTMLElement>,
    newCategory: string | null
  ) => {
    if (newCategory !== null) {
      setSelectedCategory(newCategory);
    }
  };

  return (
    <Container maxWidth={false} className="bg-color" style={{ minHeight: 'calc(100vh - 71px - 143px)',display:'flex', flexDirection:'column'}}>
      <Box padding='30px' flexGrow='1' >
        <Typography
          className="text-secondary"
          sx={{ marginBottom: "20px" }}
        >
          {" "}
          <span
            onClick={() => navigation(-1)}
            style={{ color: '#4A7AF2', cursor: 'pointer' }}
          >
            More Options
          </span>{' '}
          / Order
        </Typography>
        <ToggleButtonGroup
          value={selectedCategory}
          exclusive
          onChange={handleSelectedCategory}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: '20px',
            bgcolor: '#ffffff1a ',
          }}
          aria-label="Platform"
        >
          <ToggleButton
            value="Open"
            className="text-white"
            sx={{
              flexGrow: 1,
              textTransform: 'none',
              fontWeight: '600',
              fontSize: '20px',
              borderColor: 'transparent !important',
              borderRadius: '20px !important',
              '&:hover': {
                bgcolor: '#f2674a !important',
                color: 'white !important',
                borderColor: '#f2674a !important',
              },
              '&.Mui-selected': {
                bgcolor: '#f2674a !important',
                color: 'white !important',
                borderColor: '#f2674a !important',
              },
            }}
          >
            Open
          </ToggleButton>
          <ToggleButton
            value="Executed"
            className="text-white"
            sx={{
              flexGrow: 1,
              textTransform: 'none',
              fontWeight: '600',
              fontSize: '20px',
              borderColor: 'transparent !important',
              borderRadius: '20px !important',
              '&.MuiToggleButton-root': {
                marginRight: '0px !important',
              },
              '&:hover': {
                bgcolor: '#f2674a !important',
                color: 'white !important',
                borderColor: '#f2674a !important',
              },
              '&.Mui-selected': {
                bgcolor: '#f2674a !important',
                color: 'white !important',
                borderColor: '#f2674a !important',
                marginRight: '0px !important',
              },
            }}
          >
            Executed
          </ToggleButton>
        </ToggleButtonGroup>
        {selectedCategory === 'Open' ? (
          <>
            <Typography variant="h5" className="text-white" my="20px">
              Open Orders
            </Typography>
            <OpenOrder />
          </>
        ) : (
          <>
            <Typography variant="h5" className="text-white" my="20px">
              Executed Orders
            </Typography>
            <ExecutedOrder />
          </>
        )}
      </Box>
      <Box width='100%' >
        <Divider sx={{ border: '1px solid #FFFFFF33' }}></Divider>
        <Typography my='20px' className='text-white' textAlign='center'>Securities Products and Services are provided by ABC Inc. Member FINRA | SIPC.</Typography>
      </Box>
    </Container>
  );
}

export default Order;
