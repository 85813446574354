import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Yourinvestment from '../Cards/Yourinvestment';
import { getUserFromLocalStorage } from '../../../Utils/utils';
import Axios from '../../../config/axios';
import { UrlConstant } from '../../../commom/UrlConstant';
import { Constant } from '../../../commom/Constants';
import createaccount from "../../../style/images/createaccount.png";
import submitcard from "../../../style/images/submitcard.png";
import { useNavigate } from 'react-router-dom';
import TradingACNotice from '../MainComponents/TradingAccount/TradingACNotice';
import TradingACDisclosure from '../MainComponents/TradingAccount/TradingACDisclosure';
import img from '../../../style/images/referfriendbg.png'
import gif from '../../../style/Gif/confetti2.gif'
import Skeleton from 'react-loading-skeleton';
import { createTheme, ThemeProvider } from "@mui/material";
import { NavLink } from 'react-router-dom';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    components: {
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2A2A2A', // Base color
                },
                pulse: {
                    '&::after': {
                        background: 'linear-gradient(90deg, #2A2A2A, #3C3C3C)', // Highlight animation
                    },
                },
            },
        },
    },
});

const RightSideSection = () => {

    const user = getUserFromLocalStorage();
    const navigate = useNavigate();
    const [isTradingAcExist, setTradingAcExist] = useState<boolean>(false); //tradingblock_application_id
    const [applicationStatus, setApplicationStatus] = useState("Create Now");
    const [isDocumentRequired, setDocumentRequired] = useState<boolean>(false);
    const [applicationId, setApplicationId] = useState("");
    const [isDialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const [isNoticeOpen, setNoticeOpen] = React.useState<boolean>(false);
    const [isDisclosureOpen, setDisclosureOpen] = React.useState<boolean>(false);
    const [popup, setpopup] = React.useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(true);

    const createTradingAc = () => {
        setDialogOpen(true);
    };

    useEffect(() => {
        if (user && user.tradingblock_application_id) {
            setTradingAcExist(true);
        }
    }, [user]);

    const checkAccountStatus = async () => {
        try {
            const response = await Axios.get(
                UrlConstant.TradingAccount.CheckAccountStatus
            );
            const resData = response.data;
            setpopup(true)
            console.log("AC Status : ", resData);
            if (resData.status === true) {
                setApplicationStatus(resData.data.status);
                setDocumentRequired(resData.data.isDocumentUploadRequired);
                setApplicationId(resData.data.applicationId);

            }
        } catch (error: any) {
            console.error("ERRR : ", error);
        }
        finally {
            setLoading(false)
        }
    };


    useEffect(() => {
        checkAccountStatus()
    }, [])

    return (

        <>
            {isLoading &&
                <>
                    <ThemeProvider theme={darkTheme}>
                        <Box className='card' height='633px' width='440px'>
                            <Skeleton width='300px' height='30px'></Skeleton>
                        </Box>

                        <Box className='card' width='440px' mt='20px' borderRadius='20px' height='fit-content' padding='30px' >
                            <Box display='flex' mb='20px' alignItems='center' justifyContent='space-between'>
                                    <Skeleton width='300px' />
                                    <Skeleton width='300px' />
                                <Skeleton width='80px' height='80px' />
                            </Box>
                            <Skeleton height='50px' width='380px'></Skeleton>
                        </Box>


                    </ThemeProvider>
                </>

            }
            <Box height="100%">
                <Box width="100%">
                    <Typography
                        component="h1"
                        fontSize={24}
                        className="text-white"
                        mb={2}
                    >
                        Your Investment
                    </Typography>
                    <Box
                        height="580px"
                        width="440px"
                        className={
                            isTradingAcExist &&
                                isDocumentRequired === false &&
                                applicationStatus.toLocaleUpperCase() === "COMPLETE"
                                ? ""
                                : "d-none"
                        }
                    >
                        <Yourinvestment />
                    </Box>
                    {/* Create and Continue */}
                    {isDocumentRequired === false &&
                        (applicationStatus === "" ||
                            applicationStatus === Constant.CONTINUE) && (
                            <Box
                                className={isTradingAcExist ? "d-none" : ""}
                                position="relative"
                                width="440px"
                                sx={{
                                    background: `url(${createaccount})`,
                                    backgroundSize: "100% 100%",
                                    backgroundPosition: "center",
                                }}
                                height="580px"
                                borderRadius="30px"
                            >
                                <Box
                                    textAlign="center"
                                    bottom="5%"
                                    width="100%"
                                    sx={{ position: "absolute" }}
                                >
                                    <Typography
                                        variant="h6"
                                        fontWeight="700"
                                        className="text-white"
                                        textAlign="center"
                                    >
                                        Don’t have Trading Account?
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontWeight={600}
                                        className="text-secondary"
                                    >
                                        Let’s Simplify your Trading Experience.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            width: "90% !important",
                                            marginTop: "40px",
                                            paddingTop: "8px !important",
                                            paddingBottom: "8px !important",
                                            fontSize: "16px",
                                            // paddingX:'0px 0px !important'
                                        }}
                                        className="btn-primary fw-700   btn"
                                        endIcon={
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M9.76001 12.0601H19.93"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        }
                                        // type="submit"
                                        onClick={() => {
                                            if (applicationStatus === Constant.CONTINUE) {
                                                navigate("/createApplication");
                                            } else {
                                                setNoticeOpen(true);
                                                createTradingAc();
                                            }
                                        }}
                                    >
                                        {applicationStatus === ""
                                            ? "Create Account"
                                            : applicationStatus}
                                    </Button>
                                </Box>
                            </Box>
                        )}

                    {/* Upload Document */}
                    <Box
                        className={
                            isDocumentRequired === true && applicationStatus !== ""
                                ? ""
                                : "d-none"
                        }
                        position="relative"
                        width="440px"
                        height="580px"
                        sx={{
                            background: `url(${submitcard})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                        }}
                        // height="40%"
                        borderRadius="30px"
                    >
                        <Box
                            textAlign="center"
                            bottom="5%"
                            width="100%"
                            sx={{ position: "absolute" }}
                        >
                            <Typography
                                variant="h6"
                                fontWeight="700"
                                className="text-white"
                                textAlign="center"
                            >
                                Application is Under Review!
                            </Typography>
                            <Typography
                                variant="body2"
                                fontWeight={600}
                                className="text-secondary"
                            >
                                Application ID : #{applicationId}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: "90% !important",
                                    marginTop: "40px",
                                    paddingTop: "8px !important",
                                    paddingBottom: "8px !important",
                                    fontSize: "16px",
                                    // paddingX:'0px 0px !important'
                                }}
                                className="btn-primary fw-700   btn"
                                endIcon={
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M9.76001 12.0601H19.93"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                onClick={() => {
                                    if (isDocumentRequired === true) {
                                        navigate("/documentupload");
                                    } else if (applicationStatus === Constant.CONTINUE) {
                                        navigate("/createApplication");
                                    } else {
                                        setNoticeOpen(true);
                                        createTradingAc();
                                    }
                                }}
                            >
                                {isDocumentRequired === true
                                    ? "Upload Document"
                                    : applicationStatus}
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        className={
                            isDocumentRequired === false &&
                                applicationId &&
                                applicationStatus.toLocaleUpperCase() !== "COMPLETE"
                                ? ""
                                : "d-none"
                        }
                        position="relative"
                        width="440px"
                        sx={{
                            background: `url(${submitcard})`,
                            // backgroundSize: "100% 100%",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            // backgroundPosition: "center",
                        }}
                        height="580px"
                        borderRadius="30px"
                    >
                        <Box
                            textAlign="center"
                            bottom="5%"
                            width="100%"
                            sx={{ position: "absolute", padding: "0px 30px" }}
                        >
                            <Typography
                                variant="h5"
                                fontWeight="700"
                                className="text-white"
                                textAlign="center"
                            >
                                Document Submitted!
                            </Typography>
                            <Typography
                                my="10px"
                                className="text-secondary"
                                fontSize="18px"
                            >
                                We're carefully reviewing your details. You’ll receive an
                                update via email once the process is complete. Thank you for
                                your patience!
                            </Typography>
                            <Typography
                                variant="h5"
                                fontWeight={600}
                                className="text-white"
                            >
                                Application ID : #{applicationId}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {popup &&
                    <Box width='440px' my='20px' borderRadius='20px' height='fit-content' padding='30px' sx={{ background: `url(${img})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}>
                        <Box display='flex' mb='20px' alignItems='center' justifyContent='space-between'>
                            <Typography fontSize='24px' className='text-white' maxWidth='300px' fontWeight='bold'>Invite your friends and
                                earn exclusive rewards!</Typography>
                            <img src={gif} alt="image" height='80px' width='80px' style={{}} />
                        </Box>
                        <NavLink to='/referfriend'>
                        <Button fullWidth className='btn btn-blue' sx={{ textWrap: 'nowrap', padding: '13px 128px', fontSize: '16px', fontWeight: 'bold', gap: '10px' }}>Refer & Win
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.0001 7.16C17.9401 7.15 17.8701 7.15 17.8101 7.16C16.4301 7.11 15.3301 5.98 15.3301 4.58C15.3301 3.15 16.4801 2 17.9101 2C19.3401 2 20.4901 3.16 20.4901 4.58C20.4801 5.98 19.3801 7.11 18.0001 7.16Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16.9704 14.4402C18.3404 14.6702 19.8504 14.4302 20.9104 13.7202C22.3204 12.7802 22.3204 11.2402 20.9104 10.3002C19.8404 9.59016 18.3104 9.35016 16.9404 9.59016" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.97047 7.16C6.03047 7.15 6.10047 7.15 6.16047 7.16C7.54047 7.11 8.64047 5.98 8.64047 4.58C8.64047 3.15 7.49047 2 6.06047 2C4.63047 2 3.48047 3.16 3.48047 4.58C3.49047 5.98 4.59047 7.11 5.97047 7.16Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.00043 14.4402C5.63043 14.6702 4.12043 14.4302 3.06043 13.7202C1.65043 12.7802 1.65043 11.2402 3.06043 10.3002C4.13043 9.59016 5.66043 9.35016 7.03043 9.59016" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.0001 14.6302C11.9401 14.6202 11.8701 14.6202 11.8101 14.6302C10.4301 14.5802 9.33008 13.4502 9.33008 12.0502C9.33008 10.6202 10.4801 9.47021 11.9101 9.47021C13.3401 9.47021 14.4901 10.6302 14.4901 12.0502C14.4801 13.4502 13.3801 14.5902 12.0001 14.6302Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.08973 17.7804C7.67973 18.7204 7.67973 20.2603 9.08973 21.2003C10.6897 22.2703 13.3097 22.2703 14.9097 21.2003C16.3197 20.2603 16.3197 18.7204 14.9097 17.7804C13.3197 16.7204 10.6897 16.7204 9.08973 17.7804Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </Button>
                        </NavLink>
                    </Box>
                }
            </Box>
            <Dialog
                open={isDialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                // className="bg-color"
                PaperProps={{
                    sx: {
                        boxShadow: "0px 0px 25px rgba(170,170,170,0.7) ",
                    },
                }}
                sx={{
                    "&.MuiDialog-Paper": {
                        bgcolor: "red !important",
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "20px",
                        marginRight: "30px",
                    }}
                >
                    <IconButton
                        onClick={() => {
                            setDialogOpen(false);
                        }}
                    >
                        <svg
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18 33C26.25 33 33 26.25 33 18C33 9.75 26.25 3 18 3C9.75 3 3 9.75 3 18C3 26.25 9.75 33 18 33Z"
                                stroke="white"
                                stroke-width="2.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M13.7549 22.2449L22.2449 13.7549"
                                stroke="white"
                                stroke-width="2.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M22.2449 22.2449L13.7549 13.7549"
                                stroke="white"
                                stroke-width="2.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </IconButton>
                </Box>

                <DialogContent
                    sx={{ padding: "0px 40px 30px 40px" }}
                    className={isNoticeOpen ? "" : "d-none"}
                >
                    <TradingACNotice />
                </DialogContent>
                <DialogContent
                    sx={{ padding: "0px 40px 30px 40px" }}
                    className={isDisclosureOpen ? "" : "d-none"}
                >
                    <TradingACDisclosure />
                </DialogContent>
                <DialogActions
                    sx={{ justifyContent: "center", paddingBottom: "30px" }}
                    className={isNoticeOpen ? "" : "d-none"}
                >
                    <Button
                        onClick={() => {
                            setDialogOpen(false);
                        }}
                        variant="outlined"
                        className="btn btn-outline-primary"
                        sx={{
                            padding: "10px 40px !important",
                            borderRadius: "10px",
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setNoticeOpen(false);
                            setDisclosureOpen(true);
                        }}
                        variant="contained"
                        className="btn btn-primary"
                        sx={{ padding: "10px 40px !important", borderRadius: "10px" }}
                    >
                        Proceed
                    </Button>
                </DialogActions>

                <DialogActions
                    sx={{ justifyContent: "center", paddingBottom: "30px" }}
                    className={isDisclosureOpen ? "" : "d-none"}
                >
                    <Button
                        onClick={() => {
                            setNoticeOpen(false);
                            setDisclosureOpen(false);
                            setDialogOpen(false);
                            navigate("/createTradingBlockUser");
                        }}
                        className="btn btn-primary"
                        sx={{ padding: "10px 40px !important", borderRadius: "10px" }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default RightSideSection