import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  FormControl,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Constant } from "../../../commom/Constants";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import Yourinvestment from "../Cards/Yourinvestment";
import { CommonDataTable } from "../SharedComponent/CommonDataTable";
import RightSideSection from "../SharedComponent/RightSideSection";
import { formatPrice } from "../../../Utils/utils";
function MarketMovers() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [totalRows] = useState(10);


  const [loading, setLoading] = useState(false);
  const [marketMoversType, setMarketMoversType] = React.useState<any>("gainers");
  const [marketMovers, setMarketMovers] = React.useState<any>([]);
  const [marketMoversData, setMarketMoversData] = React.useState<any>([]);
  const [marketMoversFilter, setMarketMoversFilter] = React.useState<String>("L");


  // ** Table Headers

  const columns = [
    {
      name: "Company",
      selector: (row) => [row.company_name],
      cell: (row) => (
        <div
          onClick={() => {
            navigateToDetail(row);
          }}
          className="stock-label"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          <Avatar
            className="stock-icon"
            alt={row.symbol}
            src={row.branding?.icon_url}
            sx={{
              width: 30,
              height: 30,
              backgroundColor: "white",
              objectFit: "fill",
            }}
          />
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.company_name}
          </span>
        </div>
      ),
      sortable: true,
      center: false,
      width: "350px",
      //width: "auto",
    },
    {
      name: "Market Price",
      selector: (row) => [row.stockPrice],
      cell: (row) => (
        <div>
          {row.price === null ? "-" : `$${formatPrice(row.price)}`}
        </div>
      ),
      // width: "130px",
      width: "auto",
      sortable: true,
      center: true,
    },
    {
      name: "1D Price",
      selector: (row) => [row.todaysChange],
      cell: (row) => <div style={{ color: row.todaysChange == 0 ? "" : row.todaysChange > 0 ? "#4edf6d" : "red" }} >{row.todaysChange > 0 ? `+${formatPrice(row.todaysChange)}` : `${formatPrice(row.todaysChange)}`}</div>,
      sortable: true,
      width: "100px",
      center: true,
    },

    {
      name: "1D Percentage",
      selector: (row) => [row.todaysChangePerc],
      sortable: true,
      cell: (row) => <div style={{ color: row.Change == 0 ? "" : row.todaysChangePerc > 0 ? "#4edf6d" : "red" }}>{row.todaysChangePerc > 0 ? `+${formatPrice(row.todaysChangePerc)}` : `${formatPrice(row.todaysChangePerc)}`}</div>,
      width: "150px",

      center: true,
    },
  ];
  const navigateToDetail = async (data) => {
    //stockdetail
    navigation("/stockdetail", {
      state: {
        symbol: data.ticker,
        icon: data.branding?.logo_url ?? data.branding?.icon_url,
        title: "Market Movers"
      },
    });
  };



  const handleChangeMarketMovers = (event: SelectChangeEvent) => {
    setMarketMoversType(event.target.value as string);
  };

  const handleChangeMarketMoversFilter = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setMarketMoversFilter(newAlignment);
    const filteredData =
      marketMovers?.length &&
      marketMovers.filter((item) => item.key === newAlignment);
    setMarketMoversData(filteredData?.[0]?.data ?? []);
  };

  const getMarketMovers = async () => {
    setLoading(true); // Start loading
    try {
      const response = await Axios.get(
        UrlConstant.MarketMovers.GetMarketMovers.replace("{0}", marketMoversType)
      );
      const resData = response.data;
      setMarketMovers(resData.data);
      const order = ["L", "M", "S"];
      resData.data.sort((a, b) => {
        return order.indexOf(a.key) - order.indexOf(b.key);
      });

      resData.data?.some((item) => {
        if (item.data.length !== 0) {
          setMarketMoversFilter(item.key);
          setMarketMoversData(resData.data?.[0]?.data ?? []);
          return true;
        }
      });
      setLoading(false); // End loading
    } catch (error: any) {
      console.error("Error fetching market movers:", error);
    } finally {

    }
  };

  useEffect(() => {
    getMarketMovers()
  }, [marketMoversType])

  // useEffect(() => {
  //   const filteredData =marketMovers?.length && marketMovers.filter(item => item.key === marketMoversFilter);
  //   setMarketMoversData(filteredData?.[0]?.data)
  // }, [marketMoversFilter, marketMovers])


  return (
    <Box className="bg-color" display="flex">
      <Container
        maxWidth={false}
        className="bg-color p-3 px-5"
        style={{
          minHeight: "calc(100vh -  71px - 143px)",
          // height:'100vh',
          width: "70%",
        }}
      >
        <Typography
          className="text-secondary"
          sx={{ marginBottom: "20px", paddingTop: '30px' }}
        >
          {" "}
          <span
            onClick={() => navigation(-1)}
            style={{ color: "#4A7AF2", cursor: "pointer" }}
          >
            Dashboard{" "}
          </span>{" "}
          / Market Movers
        </Typography>
        <Box mb={5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2} alignItems="center" mb={1}>
              <Typography
                className="text-white"
                mb={0}
                style={{ fontSize: "1.5rem" }}
              >
                Market Movers
              </Typography>

              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={marketMoversType}
                  className="text-white"
                  sx={{
                    // color:'!important',
                    borderRadius: "9px !important",
                    borderColor: 'red !important',
                    padding: "0px !important",
                    '.MuiSvgIcon-root': {
                      color: 'white !important'
                    }


                  }}
                  onChange={handleChangeMarketMovers}
                >
                  <MenuItem value="gainers">Gainers</MenuItem>
                  <MenuItem value="losers">Losers</MenuItem>
                </Select>
              </FormControl>


            </Box>
          </Box>

          <Box mb={1}>
            <ToggleButtonGroup
              // className="border-white"
              // color="primary"
              value={marketMoversFilter}
              exclusive
              onChange={handleChangeMarketMoversFilter}
              aria-label="Platform"

            >
              <ToggleButton value="L">Large</ToggleButton>
              <ToggleButton value="M">Mid</ToggleButton>
              <ToggleButton value="S">Small</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Container
          style={{ width: "100%", left: "0", margin: "0" }}
          className="p-0"
        >
          <Row className="dark-tbl no-border p-0">
            <Card className="custom-card overflow-hidden bg-color w-100">
              <Card.Body className="ps-12 p-2">
                <div>
                  {loading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="200px"
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  ) : (
                    <CommonDataTable
                      setPage={setPage}
                      setRowsPerPage={setRowsPerPage}
                      data={marketMoversData}
                      totalRows={totalRows}
                      columns={columns}
                      setSearch={setSearch}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Row>
        </Container>

        {/* <Container style={{ width: "70%", left: "0", margin: "0" }}>
          <TotalFunds />
        </Container> */}
      </Container>
      <Container style={{ width: "40%", paddingTop: "20px" }}>
        <RightSideSection />
      </Container>
    </Box>
  );
}
export default MarketMovers;
