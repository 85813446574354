import {
  Avatar,
  Box,
  Container,
  FormControl,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Constant } from "../../../commom/Constants";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import Yourinvestment from "../Cards/Yourinvestment";
import { CommonDataTable } from "../SharedComponent/CommonDataTable";
import RightSideSection from "../SharedComponent/RightSideSection";
import { formatPrice } from "../../../Utils/utils";
function AiPicks() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const [allData, setAllData] = React.useState<any>({});
  const [stockList, setStockList] = useState([]);
  const [totalRows] = useState(10);
  const [buyOrSell, setBuyOrSell] = React.useState<any>(Constant.BUY);
  const [stockOrETF, setStockOrETF] = React.useState<any>(Constant.STOCK);
  const [selectedCategory, setSelectedCategory] = React.useState<String>(
    Constant.POPULAR
  );
  console.log(page, rowsPerPage, search);

  // ** Table Headers
  const columns = [
    {
      name: "Company",
      selector: (row) => [row.displayName],
      cell: (row) => (
        <div
          onClick={() => {
            navigateToDetail(row);
          }}
          className="stock-label"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          <Avatar
            className="stock-icon"
            alt={row.symbol}
            src={row.branding?.logo_url ?? row.branding?.icon_url}
            sx={{
              width: 30,
              height: 30,
              backgroundColor: "white",
              objectFit: "fill",
            }}
          />
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.displayName}
          </span>
        </div>
      ),
      sortable: true,
      center: false,
      width: "350px",
      //width: "auto",
    },
    {
      name: "Market Price",
      selector: (row) => [row.stockPrice],
      cell: (row) => (
        <div>
          {row.stockPrice === null ? "-" : `$${row.stockPrice?.toFixed(2)}`}
        </div>
      ),
      // width: "130px",
      width: "auto",
      sortable: true,
      center: true,
    },
    {
      name: "1D Price",
      selector: (row) => [row.Change],
      cell: (row) => <div style={{ color: row.Change == 0 ? "" : row.Change > 0 ? "#4edf6d" : "red" }} >{row.Change > 0 ? `+${formatPrice(row.Change)}` : `${formatPrice(row.Change)}`}</div>,
      sortable: true,
      width: "100px",
      center: true,
    },

    {
      name: "1D Percentage",
      selector: (row) => [row.ChangePercentage],
      sortable: true,
      cell: (row) => <div style={{ color: row.Change == 0 ? "" : row.ChangePercentage > 0 ? "#4edf6d" : "red" }}>{row.ChangePercentage > 0 ? `+${formatPrice(row.ChangePercentage)}` : `${formatPrice(row.ChangePercentage)}`}%</div>,
      width: "150px",

      center: true,
    },
  ];
  const navigateToDetail = async (data) => {
    //stockdetail
    navigation("/stockdetail", {
      state: {
        symbol: data.symbol,
        icon: data.branding?.logo_url ?? data.branding?.icon_url,
        title: "AI Picks"
      },
    });
  };
  const handleBuyOrSell = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setBuyOrSell(value);
    getSelectedData(allData, selectedCategory.toString(), value);
  };
  const handleStockOrETF = (event: SelectChangeEvent) => {
    setStockOrETF(event.target.value as string);
  };
  const handleSelectedCategory = (
    event: React.MouseEvent<HTMLElement>,
    newCategory: string
  ) => {
    setSelectedCategory(newCategory);
    if (newCategory === Constant.FOLLOWING) {
      getFollowiList();
    } else {
      // getSelectedData(allData, newCategory, buyOrSell);
      getAiPickList(newCategory);
    }
  };

  const getSelectedData = async (
    allStockData,
    categoryKey: string,
    actionKey: string
  ) => {
    if (allStockData?.[categoryKey]?.[actionKey]) {
      const list = allStockData[categoryKey][actionKey].map((stock: any) => {
        const item = stock.stockdata;
        const v1 = item.regularMarketPrice ?? 0;
        const v2 = item.Last ?? 0;
        const diffrence = v2 - v1;
        const div = diffrence / v1;
        const res = div * 100;
        const itemDetail = {
          displayName: item.displayName,
          stockPrice: item.Last,
          res: res,
          diffrence: diffrence,
          branding: item.branding,
          symbol: item.symbol,
          Change: item.Change,
          ChangePercentage: item.ChangePercentage,
        };

        return itemDetail;
      });
      setStockList(list);
    }
  };

  const getFollowiList = async () => {
    dispatch(startLoading());

    try {
      const response = await Axios.post(
        UrlConstant.AiPicks.GetAiPicksOfFollowing.replace("{0}", stockOrETF)
      );
      const resData = response.data;
      if (resData.result === true) {
        setAllData(resData.data);
        const list = resData.data[buyOrSell].map((stock: any) => {
          const item = stock.stockdata;
          const v1 = item.regularMarketPrice ?? 0;
          const v2 = item.Last ?? 0;
          const diffrence = v2 - v1;
          const div = diffrence / v1;
          const res = div * 100;
          const itemDetail = {
            displayName: item.displayName,
            stockPrice: item.Last,
            res: res,
            diffrence: diffrence,
            branding: item.branding,
            symbol: item.symbol,
          };

          return itemDetail;
        });
        setStockList(list);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
    }
  };
  const getAiPickList = async (newCategory) => {
    dispatch(startLoading());

    try {
      const response = await Axios.get(
        `${UrlConstant.AiPicks.GetAiPicks}?type=${stockOrETF}`
      );
      const resData = response.data;
      if (resData.result === true) {
        setAllData(resData.data);
        getSelectedData(resData.data, newCategory, buyOrSell.toString());
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (selectedCategory === Constant.FOLLOWING) {
      getFollowiList();
    } else {
      getAiPickList(selectedCategory);
    }
  }, [stockOrETF]);
  return (
    <Box className="bg-color" display="flex" minHeight='calc(100vh - 71px - 143px)' padding='30px'>
      <Container
        maxWidth={false}
        className="bg-color"

        style={{
          height: '100% !important',
          // height:'100vh',
          width: "70%",
        }}
      >
        <Typography
                  className="text-secondary"
                  sx={{ marginBottom: "20px", paddingTop: '30px' }}
                >
                  {" "}
                  <span
                    onClick={() => navigation(-1)}
                    style={{ color: "#4A7AF2", cursor: "pointer" }}
                  >
                    Dashboard{" "}
                  </span>{" "}
                  / AI Picks
                </Typography>
        <Box mb={5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2} alignItems="center" mb={1}>
              <Typography
                className="text-white"
                mb={0}
                style={{ fontSize: "1.5rem" }}
              >
                AI Picks
              </Typography>

              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={buyOrSell}
                  className="text-white"
                  sx={{
                    borderRadius: "9px !important",
                    padding: "0px !important",
                    '.MuiSvgIcon-root': {
                      color: 'white !important'

                    },
                    '.MuiInputBase-root': {
                      height: '24px !important'
                    }
                  }}
                  onChange={handleBuyOrSell}
                >
                  <MenuItem value={Constant.BUY}>Buy</MenuItem>
                  <MenuItem value={Constant.SELL}>Sell</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={stockOrETF}
                  className="text-white"
                  sx={{
                    borderRadius: "9px !important",
                    padding: "0px !important",
                    '.MuiSvgIcon-root': {
                      color: 'white !important'
                    }
                  }}
                  onChange={handleStockOrETF}
                >
                  <MenuItem value={Constant.STOCK}>Stock</MenuItem>
                  <MenuItem value={Constant.ETF}>ETF</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box mb={0} mt={2}>
            <ToggleButtonGroup
              value={selectedCategory}
              exclusive
              onChange={handleSelectedCategory}
              aria-label="Platform"
            >
              <ToggleButton value={Constant.POPULAR}>Popular</ToggleButton>
              <ToggleButton value={Constant.DIVIDENT_PAYING}>
                Dividend Paying
              </ToggleButton>
              <ToggleButton value={Constant.GROWTH}>growth</ToggleButton>
              <ToggleButton value={Constant.FOLLOWING}>Following</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Container
          style={{ width: "100%", left: "0", margin: "0" }}
          className="p-0"
        >
          <Row className="dark-tbl no-border p-0">
            <Card className="custom-card overflow-hidden bg-color w-100">
              <Card.Body className="ps-12 p-2">
                <div>
                  <CommonDataTable
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    data={stockList}
                    totalRows={totalRows}
                    columns={columns}
                    setSearch={setSearch}
                  />
                </div>
              </Card.Body>
            </Card>
          </Row>
        </Container>

        {/* <Container style={{ width: "70%", left: "0", margin: "0" }}>
          <TotalFunds />
        </Container> */}
      </Container>
      <Container style={{ width: "40%", paddingTop: "20px" }}>
        <RightSideSection />
      </Container>
    </Box>
  );
}
export default AiPicks;
