import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import "../../style/style.css";
import LeftSide from "../common/Layout/LeftSide";
import Axios from "../../config/axios";
import { UrlConstant } from "../../commom/UrlConstant";
import { InputLabel } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';

function Uploadimage() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state;
  const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer | null>(null);
  const [profilePic, setProfilePic] = useState<any>(null);
  const [imageSrc, setImageSrc] = useState<any>(null); // Original image source
  const [crop, setCrop] = useState({ x: 0, y: 0 }); // Crop position
  const [zoom, setZoom] = useState(1); // Zoom level
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null); // Pixel coordinates of cropped area
  const [croppedImage, setCroppedImage] = useState<any>(null); // Cropped image preview


  // Create a ref for the file input
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleFileSelect = (file) => {
    setProfilePic(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result); // Set the preview image
        setSelectedImage(reader.result);
      };
      
      reader.readAsDataURL(file); // Read the file as a data URL
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  useEffect(()=>{
    handleCrop()
  },[profilePic])

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };


  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };


  const base64ToBlob = (base64: string, mimeType: string) => {
    const byteString = atob(base64.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: mimeType });
  };


  const uploadProfilePic = async () => {
    const formData = new FormData();

    // Append the original profile picture if it's a File object
    if (profilePic !== null && profilePic instanceof File) {
      formData.append("file", profilePic);
    }

    // Check if croppedImage is base64, convert it to a Blob, and append it as 'profilePic'
    console.log("croppedImage: ", croppedImage);
    if (croppedImage !== null && typeof croppedImage === 'string') {
      // Assuming 'croppedImage' is base64, convert it to a Blob first
      const mimeType = croppedImage.split(';')[0].split(':')[1]; // Get the MIME type from the base64 string
      const blob = base64ToBlob(croppedImage, mimeType);

      // You can also give it a file name here, for example, 'profile_pic.jpg'
      const file = new File([blob], "profilePic.jpg", { type: mimeType });

      // Append the converted file as profilePic
      formData.append("file", file);
    }

    const myHeaders = {
      // Authorization: user.userToken,
      //"Content-Type": "multipart/form-data",
    };

    try {
      const response = await Axios.post(
        UrlConstant.Auth.UploadProfilePic,
        formData,
        { headers: myHeaders }
      );
      const resData = response.data;
      if (resData.result === true) {
        goToCreatePin();
      } else {
        console.error("Upload failed:", resData.message);
      }
    } catch (error: any) {
      console.error("Error: ", error);
    }
  };


  const getCroppedImg = async (imageSrc, pixelCrop, zoom) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) throw new Error("Canvas context could not be created");

    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve, reject) => {
      image.onload = () => {
        try {
          const scaleX = image.naturalWidth / image.width;
          const scaleY = image.naturalHeight / image.height;

          canvas.width = pixelCrop.width;
          canvas.height = pixelCrop.height;

          ctx.drawImage(
            image,
            pixelCrop.x * scaleX,
            pixelCrop.y * scaleY,
            pixelCrop.width * scaleX,
            pixelCrop.height * scaleY,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
          );

          const croppedImage = canvas.toDataURL("image/jpeg");
          resolve(croppedImage);
        } catch (error: any) {
          reject(new Error("Crop failed: " + error.message));
        }
      };

      image.onerror = () => {
        reject(new Error("Failed to load image for cropping"));
      };
    });
  };

  const handleCrop = async () => {
    try {
      const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels, zoom);
      console.log("Croppedllllllllllimage ", croppedImg);
      setCroppedImage(croppedImg);
    } catch (error: any) {
      console.error("Crop failed:", error.message);
    }
  };

  const handleRemoveImage = () => {
    setImageSrc(null);
    setProfilePic(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const goToCreatePin = () => {
    navigate("/createpin", { state: user });
  };

  return (
    <Container
      maxWidth={false}
      className="container_new"
      sx={{ maxWidth: "100%", height:'fit-content', margin: "0px" }}
    >
      <RightSection className="RightSection bg-color">
        <Typography width="100%">
          <Typography className="Typography fw-700 mb-10 fs-24 heading" width="100%">
            Profile Image
          </Typography>
          <Typography
            className="Typography fs-18 mb-50 text-secondary"
            variant="body1"
            sx={{ mb: { xs: 10, sm: 10 } }}
          >
            Add Your Profile Picture to Complete Your Account.
          </Typography>
        </Typography>
        <form style={{ width: "100%", height: "100%", marginBottom: "50px" }}>
          <Typography mt={5} mb="10px" className="text-secondary">
            Profile Image
          </Typography>
          <Box
            width="100%"
            height="70%"
            padding='30px'
            mb={5}
            sx={{
              border: "2px dashed #FFFFFF33", // Dashed border
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "16px", // Rounded corners
              backgroundColor: "transparent",
              color: "#FFFFFF",
              position: "relative",
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              onChange={(e) => handleFileSelect(e.target.files?.[0])}
            />

            {!imageSrc ? (
              <Box
                component="div"
                onClick={handleUploadClick}
                sx={{
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  <span>Upload</span> or Drag here
                </Typography>
                <Typography variant="caption" sx={{ color: "#A5A5A5" }}>
                  .PNG or .JPG supported
                </Typography>
                <Typography variant="caption" sx={{ color: "#A5A5A5" }}>
                  Max 2 MB
                </Typography>
              </Box>
            ) : (
              <Box sx={{ position: "relative", width: "100%", height: 400 }}>

                <CloseIcon onClick={handleRemoveImage} style={{ position: "absolute", top: 8, right: 8, backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 10, cursor: "pointer" }} />

                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  // aspect={1} // Aspect ratio 1:1
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete} // Automatically handle cropping
                />
                {/* <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="btn-primary fw-700 btn mb-10"
                  sx={{ fontSize: "16px", paddingY: "12px" }}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent form submission
                    handleCrop();
                  }}
                >
                  Crop Image
                </Button> */}
              </Box>

            )}
          </Box>


          {/* {imageSrc && (
           
          )} */}

          {croppedImage && (
            <div style={{ marginTop: 20 }}>
              <h3>Cropped Preview:</h3>
              <img
                src={croppedImage}
                alt="Cropped"
                style={{ maxWidth: "100%" }}
              />
            </div>
          )}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="btn-primary fw-700 btn mb-10"
            sx={{ fontSize: "16px", paddingY: "12px" }}
            endIcon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9098 7.02988L11.8798 4.99988L9.84977 7.02988C9.55977 7.31988 9.07977 7.31988 8.78977 7.02988C8.49977 6.73988 8.49977 6.25988 8.78977 5.96988L11.3498 3.40988C11.6398 3.11988 12.1198 3.11988 12.4098 3.40988L14.9698 5.96988C15.2598 6.25988 15.2598 6.73988 14.9698 7.02988C14.8198 7.17988 14.6298 7.24988 14.4398 7.24988C14.2498 7.24988 14.0598 7.17988 13.9098 7.02988Z"
                  fill="white"
                />
                <path
                  d="M11.1309 14.18V4.01001C11.1309 3.60001 11.4709 3.26001 11.8809 3.26001C12.2909 3.26001 12.6309 3.60001 12.6309 4.01001V14.18C12.6309 14.6 12.2909 14.93 11.8809 14.93C11.4709 14.93 11.1309 14.59 11.1309 14.18Z"
                  fill="white"
                />
                <path
                  d="M3.25 12C3.25 11.59 3.59 11.25 4 11.25C4.41 11.25 4.75 11.59 4.75 12C4.75 16.27 7.73 19.25 12 19.25C16.27 19.25 19.25 16.27 19.25 12C19.25 11.59 19.59 11.25 20 11.25C20.41 11.25 20.75 11.59 20.75 12C20.75 17.15 17.15 20.75 12 20.75C6.85 20.75 3.25 17.15 3.25 12Z"
                  fill="white"
                />
              </svg>
            }
            onClick={(e) => {
              e.preventDefault(); // Prevent form submission
              uploadProfilePic();
            }}
          >
            Upload
          </Button>
        </form>

        <button
          onClick={goToCreatePin}
          className="link"
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            border: 0,
          }}
        >
          Skip
        </button>
      </RightSection>
    </Container>
  );
}

export default Uploadimage;

const RightSection = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "70px 120px",
  [theme.breakpoints.down("sm")]: {
    padding: "70px 20px",
  },
}));
