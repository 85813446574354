import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Card, CardContent, Box, Skeleton, Typography } from '@mui/material'
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#2A2A2A', // Base color
        },
        pulse: {
          '&::after': {
            background: 'linear-gradient(90deg, #2A2A2A, #3C3C3C)', // Highlight animation
          },
        },
      },
    },
  },
});
function LoadingMiniCharts() {
  return (
    Array.from({ length: 2 }).map((_, index) => (
      <ThemeProvider theme={darkTheme}>
        <Box width='50%'>
          <Card className='card' sx={{width:'100% !important',height:'330px', display:'flex !important', justifyContent:'space-between', flexDirection:'column !important', padding:'20px', borderRadius:'20px'}}>
            <Box sx={{display:'flex'}}>
            <Box width='calc(100% - 212px)'>
              <Box display='flex' alignItems='start' gap='20px'>
                <Skeleton variant="circular" height="64px" width="64px" />
                <Box>
                  <Typography
                    fontSize='24px'
                    fontWeight='bold'
                    className="text-white"
                    sx={{
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                      fontWeight: "bold",
                    }}
                  >
                    <Skeleton width='117px' height='33px' />
                  </Typography>
                  <Typography fontSize='18px' className="text-secondary"> <Skeleton width='99px' height='25px' /></Typography>
                  <Typography fontSize='18px' className="text-white" fontWeight='bold'> <Skeleton width='177px' height='25px' /></Typography>
                  <Typography fontSize='15px' className="text-white" fontWeight='bold'> <Skeleton width='155px' height='16px' /></Typography>
                </Box>
              </Box>
              <Box padding={"5px"}>
                <Skeleton height='200px' width='300px' />
              </Box>
            </Box>
            <Box width='212px'>
              <Box mb='20px'>
                <Box display='flex' justifyContent='space-between'>
                  <Skeleton width='212px' height='27px' />  </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Skeleton width='212px' height='27px' /></Box>
                <Box display='flex' justifyContent='space-between'>
                  <Skeleton width='212px' height='27px' />  </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Skeleton width='212px' height='27px' /> </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Skeleton width='212px' height='27px' /> </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Skeleton width='212px' height='27px' /> </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Skeleton width='212px' height='27px' /> </Box>
              </Box>
              <Skeleton width='212px' sx={{borderRadius:'30px'}} height='70px' />
            </Box>
            </Box>
          </Card>
        </Box>
      </ThemeProvider>
    ))
  )
}

export default LoadingMiniCharts
