import React, { useEffect, useState } from 'react';
import {
    Card,
    Box,
    CardContent,
    Typography,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material';
import TotalFunds from '../charts/TotalFund';
import { CCarousel, CCarouselItem } from '@coreui/react';
import { formatPrice, getUserFromLocalStorage } from '../../../Utils/utils';
import Axios from '../../../config/axios';
import { UrlConstant } from '../../../commom/UrlConstant';
import DonutChart from '../charts/DonutChart';
// import { CarouselItem } from 'react-bootstrap';

function Yourinvestment() {

    const user = getUserFromLocalStorage();

    const [selectedTypeStock, setSelectedTypeStock] = useState<any>('stockChart');
    const [selectedTypeETF, setSelectedTypeETF] = useState<any>('etfChart');
    const [loading, setLoading] = useState<any>(false);
    const [tradingInfo, setTradingInfo] = useState<any>();
    const [portfolioData, setPortfolioData] = useState<any>()
    const [investmentChart, setInvestmentChart] = useState<any>()

    const [stockChart, setStockChart] = useState<any>()
    const [stockChartLabel, setStockLabel] = useState<any>()

    const [ETFChart, setETFChart] = useState<any>()
    const [ETFChartLabel, setETFLabel] = useState<any>()






    const handleChange = (event: SelectChangeEvent, chart) => {
        if (chart === "Stocks") {
            setSelectedTypeStock(event.target.value as string);
        } else if (chart === "ETFs") {
            setSelectedTypeETF(event.target.value as string);
        }
    };


    const totalcards = [
        { heading: "Investment Portfolio", data: investmentChart, label: ["Stocks", "Cash", "ETFs"] },
        { heading: "Stocks", Selectoptions: [{ label: "Assets", value: "stockChart" }, { label: "Sector", value: "sectorChart" }], data: stockChart || [], label: stockChartLabel },
        { heading: "ETFs", Selectoptions: [{ label: "Assets", value: "etfChart" }, { label: "Category", value: "categoryChart" }], data: ETFChart || [], label: ETFChartLabel }
    ];

    const convertToPercentage = (data: number[]) => {
        const total = data.reduce((acc, value) => acc + value, 0);
        if (total === 0) return data.map(() => 0); // Handle edge case when total is 0.

        const rawPercentages = data.map((value) => (value / total) * 100);

        const adjustedPercentages = rawPercentages.map((percentage) => (percentage > 0 && percentage < 1 ? 1 : Math.trunc(percentage)));

        return adjustedPercentages;
    };


    const fetchTradingBalance = async () => {
        // check-trading-balance
        setLoading(true)
        const data = {
            "accountId": user?.tradingblock_application_id
        }
        try {
            const response = await Axios.post(
                UrlConstant.BuySell.GetTradingBalance, data)

            setTradingInfo(response.data?.data?.Payload)
        } catch (error: any) {
            console.error("ERRR : ", error);
        } finally {
            setLoading(false)
        }
    }
    const fetchPortfolioData = async () => {
        setLoading(true)
        const data = {
            "accountId": user?.tradingblock_application_id
        }
        try {
            const response = await Axios.post(
                UrlConstant.Portfolio.GetPortfolio, data)
            if (response.data?.data) {
                setPortfolioData(response.data?.data)
            }
        } catch (error: any) {
            console.error("ERRR : ", error);
        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        fetchTradingBalance()
        fetchPortfolioData()
    }, [])

    useEffect(() => {
        const cash = tradingInfo?.Balances?.CashBalance || 0
        const stock = portfolioData?.stockChart?.stockTotal || 0
        const ETF = portfolioData?.mainChart?.chartData?.ETF || 0
        const data = [cash, stock, ETF]
        const labels = ["Cash", "Stocks", "ETFs"]

        const finalDataPer = data?.length && convertToPercentage(data)

        const finalData = labels?.map((item, i) => {
            return {
                label: item,
                value: data[i],
                percentage: finalDataPer[i]
            }
        })
        setInvestmentChart(finalData)
    }, [tradingInfo, portfolioData])

    useEffect(() => {
        let data = portfolioData?.[selectedTypeStock]?.chartDataTest
        const finalData = data?.map((item) => item.amount)
        const finalDataLabel = data?.map((item) => item.symbol)
        const finalDataPer = finalData?.length && convertToPercentage(finalData)
        const newData = finalDataLabel?.map((item, i) => {
            return {
                label: item,
                value: finalData[i],
                percentage: finalDataPer[i]
            }
        })
        setStockChart(newData)
        setStockLabel(finalDataLabel)
    }, [portfolioData, selectedTypeStock])

    useEffect(() => {
        let data = portfolioData?.[selectedTypeETF]?.chartDataTest
        const finalData = data?.map((item) => item.amount)
        const finalDataLabel = data?.map((item) => item.symbol)
        const finalDataPer = finalData?.length && convertToPercentage(finalData)
        const newData = finalDataLabel?.map((item, i) => {
            return {
                label: item,
                value: finalData[i],
                percentage: finalDataPer[i]
            }
        })
        setETFChart(newData)
        setETFLabel(finalDataLabel)
    }, [portfolioData, selectedTypeETF])
    return (
        <Card className="card" sx={{ width: '100%', height: '100%', paddingBottom: '0px' }}>
            <CardContent sx={{ paddingBottom: '0px', padding: '30px' }}>
                <CCarousel controls indicators interval={false}
                >
                    {totalcards.map((card, index) => (
                        <CCarouselItem key={index} style={{ color: 'white', padding: '0px 10px' }}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                <Typography className="text-white" variant="h5">
                                    {card.heading}
                                </Typography>
                                <Box display='flex' alignItems='center' justifyContent='space-between'>

                                    {/* Conditional rendering for Select options */}
                                    {card.Selectoptions && (
                                        <FormControl >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={card.heading === "Stocks" ? selectedTypeStock : selectedTypeETF}
                                                className="text-white"
                                                sx={{
                                                    borderRadius: '9px !important',
                                                    padding: '0px !important',
                                                    '.MuiSvgIcon-root': {
                                                        color: 'white !important'
                                                    },
                                                    fontSize: '16px'

                                                }}
                                                onChange={(e) => { handleChange(e, card.heading) }}
                                            >
                                                {card.Selectoptions.map((option, idx) => (
                                                    <MenuItem key={idx} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>
                            </Box>
                            {
                                <DonutChart
                                    series={card?.data?.length ? card?.data : []}
                                    labels={card?.label?.length ? card?.label : []}
                                />
                            }

                        </CCarouselItem>
                    ))}
                </CCarousel>

                {/* Bottom Section: Cash, Stocks, and ETFs */}
                <Box display="flex" width="100%" justifyContent="space-between" position='absolute' bottom='0px' left='0px'>
                    <Box p={3} width="34%" borderRight="1px solid #666666" borderTop="1px solid #666666" justifyContent="center">
                        <Typography className="text-secondary" textAlign="center" gutterBottom>Cash</Typography>
                        <Typography fontSize="18px" className="text-white" fontWeight='bold' textAlign="center">${formatPrice(tradingInfo?.Balances?.CashBalance)}</Typography>
                    </Box>
                    <Box p={3} width="34%" borderRight="1px solid #666666" borderTop="1px solid #666666" justifyContent="center">
                        <Typography className="text-secondary" textAlign="center" gutterBottom>Stocks</Typography>
                        <Typography fontSize="18px" className="text-white" fontWeight='bold' textAlign="center">${formatPrice(portfolioData?.stockChart?.stockTotal)}</Typography>
                    </Box>
                    <Box p={3} width="32%" borderTop="1px solid #666666" justifyContent="center">
                        <Typography className="text-secondary" textAlign="center" gutterBottom>ETFs</Typography>
                        <Typography fontSize="18px" className="text-white" fontWeight='bold' textAlign="center">${formatPrice(portfolioData?.mainChart?.chartData?.ETF)}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

export default Yourinvestment;
