import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { KeyboardEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { UrlConstant } from "../../../../commom/UrlConstant";
import Axios from "../../../../config/axios";
import { getUserFromLocalStorage } from "../../../../Utils/utils";
import Header2 from "../../Layout/Header2";
import Successpopup from "../../SharedComponent/Successpopup";

function CreateTradingBlockUser() {
  const navigate = useNavigate();
  const user = getUserFromLocalStorage();
  const [submitForm, setSubmitForm] = useState<boolean>(false);
  const [questionList, setQuestionList] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility


  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([
    "",
    "",
    "",
  ]);
  interface FormValues {
    userName: string;
    que1: string;
    que2: string;
    que3: string;
    ans1: string;
    ans2: string;
    ans3: string;
  }

  const initialValues: FormValues = {
    userName: "",
    que1: "",
    que2: "",
    que3: "",
    ans1: "",
    ans2: "",
    ans3: "",
  };

  const keyup = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      formik.handleSubmit();
    }
  };

  const validationSchema = Yup.object({
    userName: Yup.string()
      .min(6, "Must be at least 6 characters")
      .max(30, "Must be at most 30 characters")
      .matches(
        /^[a-zA-Z0-9~!#$=+._]*$/,
        "Can contain only letters, numbers, or special characters (~!#$=+._)"
      )
      .test(
        "not-email",
        "Must not be your email address",
        (value) => value !== user.email
      )
      .required("This field is required"),

    que1: Yup.string().required("Security question is required"),
    que2: Yup.string().required("Security question is required"),
    que3: Yup.string().required("Security question is required"),

    ans1: Yup.string().required("Answer is Required"),
    ans2: Yup.string().required("Answer is Required"),
    ans3: Yup.string().required("Answer is Required"),
  });

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setSubmitForm(true);
      createTradingBlockUser(values);
      setSubmitForm(false);
    },
  });

  const getSecurityQuestionList = async () => {
    try {
      const response = await Axios.get(
        UrlConstant.TradingAccount.GetSecurityQuestion
      );

      const resData = response.data;
      if (resData.status === true) {
        setQuestionList(resData.data?.questions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    getSecurityQuestionList();
  }, []);
  const handleQuestionChange = (event: SelectChangeEvent, index: number) => {
    const newSelections = [...selectedQuestions];
    newSelections[index] = event.target.value;
    setSelectedQuestions(newSelections);

    // Update formik values for the selected question
    formik.setFieldValue(`que${index + 1}`, event.target.value);
  };

  const getFilteredQuestions = (index: number) => {
    // return questionList;
    return questionList.filter(
      (question: any) =>
        !selectedQuestions.includes(question.key) ||
        selectedQuestions[index] === question.key
    );
  };
  const createTradingBlockUser = async (values) => {
    const payload = {
      userName: values.userName,
      answers: [
        {
          key: values.que1,
          answer: values.ans1,
        },
        {
          key: values.que2,
          answer: values.ans2,
        },
        {
          key: values.que3,
          answer: values.ans3,
        },
      ],
    };

    try {
      const response = await Axios.post(
        UrlConstant.TradingAccount.CreateUser,
        payload
      );

      const resData = response.data;
      if (resData.status === true) {
        navigate("/createApplication");
      } else {
        // Swal.fire({
        //   title: "Failed",
        //   text: resData.msg ?? resData.message[0],
        //   icon: "error",
        // });
        setheading('Failed')
        setShowPopup(true)
        setmsg(resData.msg ?? resData.message[0])
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
      // Swal.fire({
      //   title: "Failed",
      //   text: error.response.data.message[0],
      //   icon: "error",
      // });
      setheading('Failed')
      setShowPopup(true)
      setmsg(error.response.data.message[0])
    } finally {
    }
  };
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };
  return (
    <>
      <Box className="bg-color" p={2} height="100%">
        <Typography variant="h6" mb={3} className="text-white">
          TradingBlock User Infomation
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth margin="normal" className="">
            <TextField
              label={
                <span>
                  Username{" "}
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                </span>
              }
              variant="outlined"
              className="text-secondary"
              {...formik.getFieldProps("userName")}
              onKeyUp={keyup}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{
                width: "33%",
                padding: "10px",
                ".MuiInputBase-input": {
                  padding: "10px 15px",
                },
                " & .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${formik.errors.userName && submitForm
                    ? "red !important"
                    : " "
                    }`,
                },
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />

            {formik.touched.userName && formik.errors.userName && (
              <Typography color="error">{formik.errors.userName} </Typography>
            )}
          </FormControl>

          <List
            sx={{ listStyleType: "disc", pl: 5, py: 0 }}
            className="text-secondary"
          >
            <ListItem
              sx={{ display: "list-item", color: "white", pl: 0, py: 0 }}
            >
              <Typography className="text-secondary" variant="body2">
                Must be 6-30 character long
              </Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", color: "white", pl: 0, py: 0 }}
            >
              <Typography className="text-secondary" variant="body2">
                Can contain special character (~!#$=+._)
              </Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", color: "white", pl: 0, py: 0 }}
            >
              <Typography className="text-secondary" variant="body2">
                Must not be your email address
              </Typography>
            </ListItem>
          </List>
          <Divider
            sx={{ borderColor: "white", my: 3, borderStyle: "dashed" }}
          ></Divider>

          <Typography variant="h6" className="text-white">
            Security Questions
          </Typography>
          <Typography variant="body1" mb={4} className="text-secondary">
            Security is very important to us.Please add three security questions
            to protect your account.You may be asked to verify these when
            logging in or speaking to us by phone.
          </Typography>

          {[0, 1, 2].map((index) => (
            <Box
              display="flex"
              mt={1}
              justifyContent="space-between"
              alignItems="center"
              key={index}
              width="66%"
            >
              <FormControl
                margin="normal"
                sx={{ width: "49%", padding: "8px !important" }}
              >
                <InputLabel
                  className="country fs-18 text-secondary"
                  shrink={false}
                >
                  <span>
                    Question-{index + 1}{" "}
                    <sup>
                      <i
                        className="fa-solid fa-star-of-life"
                        style={{ color: "#fa0000", fontSize: "0.5rem" }}
                      ></i>
                    </sup>
                  </span>
                </InputLabel>
                <Select
                  labelId={`demo-simple-select-label-${index + 1}`}
                  id={`demo-simple-select-${index + 1}`}
                  value={selectedQuestions[index]}
                  className="text-white"
                  // placeholder="Select a security question"
                  sx={{
                    borderRadius: "50px !important",
                    padding: "5px !important",
                  }}
                  onChange={(event) => handleQuestionChange(event, index)}
                >
                  <MenuItem key={""} value={""} disabled selected>
                    Select a security question
                  </MenuItem>
                  {getFilteredQuestions(index).map((question: any) => (
                    <MenuItem key={question.key} value={question.key}>
                      {question.question}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched[`que${index + 1}`] &&
                  formik.errors[`que${index + 1}`] && (
                    <Typography color="error">
                      {formik.errors[`que${index + 1}`]}
                    </Typography>
                  )}
              </FormControl>

              <FormControl
                margin="normal"
                key={index + 3}
                sx={{ width: "49%" }}
              >
                <TextField
                  label={
                    <span className="text-secondary">
                      Answer-{index + 1}{" "}
                      <sup>
                        <i
                          className="fa-solid fa-star-of-life"
                          style={{ color: "#fa0000", fontSize: "0.5rem" }}
                        ></i>
                      </sup>
                    </span>
                  }
                  type="text"
                  name={`ans${index + 1}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[`ans${index + 1}`]}
                  placeholder="Good Morinig"
                  sx={{
                    padding: "8px !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                    },
                    ".MuiInputBase-input": {
                      padding: "10px 15px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />

                {/* <input
                  type="text"
                  name={`ans${index + 1}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[`ans${index + 1}`]}
                  placeholder="Answer your question"
                /> */}
                {formik.touched[`ans${index + 1}`] &&
                  formik.errors[`ans${index + 1}`] && (
                    <Typography color="error">
                      {formik.errors[`ans${index + 1}`]}
                    </Typography>
                  )}
              </FormControl>
            </Box>
          ))}
          <Divider sx={{ borderColor: "white", mt: 14, mb: 4 }}></Divider>
          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              sx={{
                padding: "13px 72px !important",
                fontSize: '16px',
                borderRadius: "10px",
              }}
              className="btn-primary fw-700 btn mb-10"
              type="submit"

            >
              Submit
            </Button>
          </Box>
        </form>
        {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

      </Box>
    </>
  );
}

export default CreateTradingBlockUser;
