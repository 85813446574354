import { Box, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import Axios from '../../../config/axios';
import { Wheel } from 'react-custom-roulette';
import image from "../../../style/images/wheelpointer.png"
import { UrlConstant } from '../../../commom/UrlConstant';
import CelebrationPopup from './CelebrationPopup';

interface SpinWheelProps {
  isDisabled: boolean;
  onSpinComplete: (earnedAmount: number) => void;  // Pass the earned amount to the parent component
  fetchReferralData:any;
}

const SpinWheel = ({ isDisabled, onSpinComplete,fetchReferralData }: SpinWheelProps) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [data, setData] = useState([{ option: "$0", style: { textColor: "white" } }]);
  const dotCount = 12; // Number of dots
  const angleStep = (2 * Math.PI) / dotCount; // Angle between dots


    useEffect(() => {
      // Fetch spin data from the API
      Axios.get(UrlConstant.ReferFriend.getSpinData)
        .then(response => {
          if (response.data.status) {
            const spinAmounts = response.data.data.spin_amounts.map((amount: number) => ({
              option: `$${amount}`,
              style: { textColor: "white" }
            }));
            setData(spinAmounts);
          }
        })
        .catch(error => {
          console.error('Error fetching spin data:', error);
        });
    }, []);

  const handleSpinClick = () => {
    if (isDisabled) return; // Disable spin if isDisabled is true

      Axios.get(UrlConstant.ReferFriend.getSpinData)
        .then(response => {
          if (response.data.status) {
            setPrizeNumber(response.data.data.spin_result_index); // Use API response for prize number
          // fetchReferralData();
            setMustSpin(true);
          }
        })
        .catch(error => {
          console.error('Error fetching spin result:', error);
        });
    };

    const handleStopSpinning = () => {
      setMustSpin(false);
      setPopupOpen(true);

      // Calculate the earned amount based on the prize (parse the prize value)
      const earnedAmount = data[prizeNumber]?.option ? parseInt(data[prizeNumber].option.replace('$', ''), 10) : 0;

      // Pass the earned amount to the parent component (ReferFriend)
      onSpinComplete(earnedAmount);
    };

    const handlePopupClose = () => {
      setPopupOpen(false);
    };

    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height:'fit-content'
        }}
        >
       
        <div
          style={{
            margin: '46px 0px',
          
            border: '0px solid transparent',
            padding: '8px',
            borderRadius: '100%',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundClip: 'border-box',
            background: 'linear-gradient(#F5654D,#FFC80A)',
          }}
        >
         
           
          <div style={{  position:'relative', border:'24px solid #25245E',borderRadius: '50%' }}>
          {Array.from({ length: dotCount }).map((_, index) => {
        const angle = angleStep * index;
        const dotSize = 12; // Increased dot size for better visibility
        const distanceFromCenter = 150; // Increased the distance from the center

        return (
          <Box
            key={index}
            sx={{
              position: "absolute",
              width: dotSize,
              height: dotSize,
              backgroundColor: "gold", // Dot color
              borderRadius: "50%",
              top: `calc(50% - ${dotSize / 2}px + ${
                Math.sin(angle) * distanceFromCenter
              }px)`, // Adjusted distance
              left: `calc(50% - ${dotSize / 2}px + ${
                Math.cos(angle) * distanceFromCenter
              }px)`, // Adjusted distance
              zIndex:'99'
            }}
          />
        );
      })}
            <div
              style={{
                height: '272px',
                rotate: '-45deg',
                width: '272px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transform: 'scale(0.65)',
              }}
            >
              <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                startingOptionIndex={0}
                data={data}
                backgroundColors={['#8540EB', '#FDBD1F']}
                textColors={['#ffffff']}
                outerBorderColor="white"
                outerBorderWidth={2}
                radiusLineWidth={0}
                disableInitialAnimation={true}
                pointerProps={{
                  src: `${image}`,
                  style: {
                    rotate: '42deg',
                    position: 'absolute',
                    top: '50%',
                    left: '42%',
                    transform: 'translate(-50%,-50%)',
                    // zIndex: '99',
                    backgroundColor: 'transparent',
                  },
                }}
                onStopSpinning={handleStopSpinning}
              />
            </div>

          </div>
        </div>
        <Button
          className="btn btn-primary"
          sx={{ py: '13px', height:'fit-content' }}
          fullWidth
          
          onClick={handleSpinClick}
          disabled={isDisabled}
        >
          Spin now
        </Button>
        <CelebrationPopup
          open={isPopupOpen}
          onClose={handlePopupClose}
          prizeAmount={data[prizeNumber]?.option
            ? parseInt(data[prizeNumber].option.replace('$', ''), 10)
            : 0}
        />
      </Box>
    );
  };

  export default SpinWheel;
