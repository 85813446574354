import React, { useState } from "react";
import {
    Container,
    Typography,
    Card,
    Box,
    Grid,
    CardContent,
    IconButton,
    Button,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Axios from "../../../config/axios"; 
import { UrlConstant } from "../../../commom/UrlConstant"; 
import UpcomingStockDetails from "./UpcomingStockDetails";
import HistoryStockDetails from "./HistoryStockDetails";
import { AxiosError } from "axios";

function StockDetails() {
    const [selectedCategory, setSelectedCategory] = useState<string>("Upcoming");
    const navigation = useNavigate();
    const location = useLocation();
    const topUp = location.state || {};

    const handleSelectedCategory = (
        event: React.MouseEvent<HTMLElement>,
        newCategory: string | null
    ) => {
        if (newCategory) {
            setSelectedCategory(newCategory);
        }
    };

    const deletebutton = async () => {
        const topUpId = topUp?.topUpId

        if (!topUpId) {
            console.error("TopUp ID not found.");
            alert("TopUp ID is missing.");
            return;
        }
        
        const isConfirmed = window.confirm("Are you sure you want to delete this top-up?");
        if (!isConfirmed) {
            return; 
        }

        const deletePayload = {
            topUpId:topUp?.topUpId, 
        };

        try {
            const response = await Axios.post(UrlConstant.AutoTopup.deletestock,deletePayload);
            if (response.data.msg==="top up deleted successfully") {
                alert("Top-up deleted successfully!");
                navigation(-1); 
            } else {
                alert("Failed to delete top-up.");
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                console.error("Axios Error: ", error.response);
                alert(`Error: ${error.response?.data?.message || 'Unknown error occurred'}`);
            } else {
                console.error("General Error: ", error);
                alert("An error occurred while deleting the top-up.");
            }
        }
    };
    return (
        <>
            <Container
                maxWidth={false}
                className="bg-color"
                style={{
                    height: "100%",
                    padding: "30px",
                    minHeight: "100vh",
                }}
            >
                {/* Breadcrumb Navigation */}
                <Typography
                    className="text-secondary"
                    sx={{ marginBottom: "20px" }}
                >
                    <span
                        onClick={() => navigation("/moreopt")}
                        style={{ color: "#4A7AF2", cursor: "pointer" }}
                    >
                        More Options
                    </span>{" "}
                    <span
                        onClick={() => navigation(-1)}
                        style={{ color: "#4A7AF2", cursor: "pointer" }}
                    >
                        / Auto Topups
                    </span>{" "}
                    / {topUp.topUp.name} Stock Auto topup
                </Typography>

                <Box width="100%" overflow="auto">
                    <Card
                        sx={{
                            marginBottom: "20px",
                            padding: "20px 30px",
                            height: "113px",
                            border: "1px solid #FFFFFF2A",
                            bgcolor: "#FFFFFF2A",
                            borderRadius: "20px",
                        }}
                    >
                        <CardContent sx={{ width: "100%", padding: "0px" }}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid
                                            item
                                            sx={{
                                                marginRight: "10px",
                                                height: "73px",
                                                padding: "14px 0px",
                                                textAlign: "center",
                                                width: "73px",
                                                borderRadius: "50px",
                                                bgcolor:"#4A7AF233"
                                            }}
                                        >
                                            <Typography
                                                fontSize="36px"
                                                lineHeight="43.57px"
                                                fontWeight="400"
                                                sx={{
                                                    marginBottom: "0px",
                                                    color: "#FFFFFF",
                                                    
                                                }}
                                            >
                                               {topUp.topUp.name.charAt(0).toUpperCase()}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "start",
                                            }}
                                        >
                                            <Typography
                                                fontSize="24px"
                                                className="text-white"
                                                fontWeight="550"
                                            >
                                                {topUp.topUp.name}
                                            </Typography>
                                            <Typography
                                                fontSize="16px"
                                                sx={{ color: "#FFFFFF80" }}
                                            >
                                                Status:{" "}
                                                <span
                                                    style={{
                                                        color: "#4EDF6D",
                                                    }}
                                                >
                                                    {topUp?.display_status}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    xs={6}
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        fontSize="13px"
                                        sx={{ color: "#FFFFFF80" }}
                                    >
                                        Frequency:{" "}
                                        <span
                                            style={{
                                                color: "white",
                                                fontWeight: "bold",
                                                fontSize: "13px",
                                            }}
                                        >
                                            {topUp?.topUp?.frequency_in_weeks}M
                                        </span>
                                        <span style={{ color: "#4A7AF2" }}>
                                            {" "}
                                            |{" "}
                                        </span>
                                        <span
                                            style={{
                                                color: "#FFFFFF80",
                                                fontSize: "13px",
                                            }}
                                        >
                                            Duration:{" "}
                                        </span>{" "}
                                        <span
                                            style={{
                                                color: "white",
                                                fontWeight: "bold",
                                                fontSize: "13px",
                                            }}
                                        >
                                            {topUp.topUp.duration_in_weeks}M
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    display="flex"
                                    alignItems="center"
                                    gap="20px"
                                    fontWeight="bold"
                                    sx={{ fontSize: "28px", color: "#4EDF6D" }}
                                >
                                    <Box>${topUp.topUp.total_amount}.00</Box>
                                    <Box
                                        marginLeft="auto"
                                        textAlign="end"
                                        fontWeight="bold"
                                        sx={{ fontSize: "28px" }}
                                    >
                                        <Button
                                            onClick={deletebutton}
                                            className="btn"
                                            style={{
                                                color: "red",
                                                padding: "13px 67px",
                                                background: "#FF2A2A33",
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>

                <ToggleButtonGroup
                    value={selectedCategory}
                    exclusive
                    onChange={handleSelectedCategory}
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "20px",
                        bgcolor: "#ffffff1a",
                        padding: "0px",
                        marginBottom: "20px",
                    }}
                    aria-label="Auto Topup Options"
                >
                    <ToggleButton
                        value="Upcoming"
                        className="text-white"
                        sx={{
                            flexGrow: 1,
                            textTransform: "none",
                            fontWeight: 600,
                            fontSize: "20px",
                            borderColor: "transparent !important",
                            borderRadius: "20px !important",
                            "&.Mui-selected": {
                                bgcolor: "#f2674a !important",
                                color: "white !important",
                                borderColor: "#f2674a !important",
                            },
                            "&:hover": {
                                bgcolor: "#f2674a !important",
                                color: "white !important",
                                borderColor: "#f2674a !important",
                            },
                        }}
                    >
                        Upcoming
                    </ToggleButton>
                    <ToggleButton
                        value="History"
                        className="text-white"
                        sx={{
                            flexGrow: 1,
                            textTransform: "none",
                            fontWeight: 600,
                            margin: "0px !important",
                            fontSize: "20px",
                            borderColor: "transparent !important",
                            borderRadius: "20px !important",
                            "&.Mui-selected": {
                                bgcolor: "#f2674a !important",
                                color: "white !important",
                                borderColor: "#f2674a !important",
                            },
                            "&:hover": {
                                bgcolor: "#f2674a !important",
                                color: "white !important",
                                borderColor: "#f2674a !important",
                            },
                        }}
                    >
                        History
                    </ToggleButton>
                </ToggleButtonGroup>

                {selectedCategory === "Upcoming" ? (
                    <>
                        <Typography
                            variant="body1"
                            fontSize="24px"
                            fontWeight={400}
                            className="text-white"
                            sx={{ margin: "0px" }}
                        >
                            Upcoming Auto Topups
                        </Typography>
                        <UpcomingStockDetails />
                    </>
                ) : (
                    <>
                        <Typography
                            variant="body1"
                            fontSize="24px"
                            fontWeight={400}
                            className="text-white"
                            sx={{ margin: "0px" }}
                        >
                            History Topup
                        </Typography>
                        <HistoryStockDetails isrepayment={selectedCategory} />
                    </>
                )}
            </Container>
        </>
    );
}

export default StockDetails;
