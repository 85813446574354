import React, { useState, useEffect } from 'react';
import { getUserFromLocalStorage } from "../../../Utils/utils";
import {
    Typography,
    Box,
    IconButton,
    Dialog,
    Divider,
    InputLabel,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Button,
    CircularProgress,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { UrlConstant } from '../../../commom/UrlConstant';
import Axios from '../../../config/axios';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormHelperText } from '@mui/material';
import Successpopup from '../SharedComponent/Successpopup';
function AddressPopup({ open, onClose }: { open: boolean; onClose: () => void }) {
    const [selectedState, setSelectedState] = useState<string>('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [states, setStates] = useState<{ abbreviation: string; name: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
    const [msg, setmsg] = useState(''); // State for controlling popup visibility
    const [Success, setSuccess] = useState(false); // State for controlling popup visibility
    const [heading, setheading] = useState(''); // State for controlling popup visibility


    const user = getUserFromLocalStorage();
    useEffect(() => {
        if (open) {
            const fetchAccountDetails = async () => {
                try {
                    const payload = {
                        accountId: user?.tradingblock_application_id,
                    };
                    const response = await Axios.post(UrlConstant.Address.getAddressDetails, payload);

                    if (response.data?.data?.physicalAddress) {
                        const { address1, address2, city, state, postalCode } = response.data.data.physicalAddress;
                        setAddressLine1(address1 || '');
                        setAddressLine2(address2 || '');
                        setCity(city || '');
                        setZipCode(postalCode || '');
                        const stateFullName = states.find((s) => s.abbreviation === state)?.name || '';
                        setSelectedState(stateFullName);

                        // Initialize Formik values dynamically
                        formik.setValues({
                            addressLine1: address1 || '',
                            addressLine2: address2 || '',
                            city: city || '',
                            zipCode: postalCode || '',
                            selectedState: stateFullName || '',
                        });
                    } else {
                        console.warn('No account details found in the response');
                    }
                } catch (error) {
                    console.error('Error fetching account details:', error);
                }
            };

            fetchAccountDetails();
        }
    }, [open, states]);



    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await Axios.get(UrlConstant.Address.getState);
                if (response.data?.data) {
                    const stateList = response.data.data.map((state: { abbreviation: string; name: string }) => ({
                        abbreviation: state.abbreviation,
                        name: state.name,
                    }));
                    setStates(stateList);
                }
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates();
    }, []);

    const formik = useFormik({
        initialValues: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            zipCode: '',
            selectedState: '',
        },
        validationSchema: Yup.object({
            addressLine1: Yup.string().required('Address Line 1 is required'),
            // addressLine2: Yup.string().required('Address Line 2 is required'),
            city: Yup.string().required('City is required'),
            zipCode: Yup.string()
                .matches(/^[0-9]{5}$/, 'Zip/Postal Code must be exactly 5 digits')
                .required('Zip/Postal Code is required'),
            selectedState: Yup.string().required('State is required'),
        }),
        onSubmit: async (values) => {
            // Submit logic
        },
        validateOnBlur: true,
        validateOnChange: true,
    });

    const handleUpdate = async () => {
        const isValid = await formik.validateForm();
        formik.setTouched({
            addressLine1: true,
            city: true,
            zipCode: true,
            selectedState: true,
        });

        if (!formik.isValid || Object.keys(formik.errors).length > 0) {
            // Swal.fire({
            //     title: 'Error!',
            //     text: 'Please fix the validation errors before updating.',
            //     icon: 'error',
            //     confirmButtonText: 'OK',
            //     background: '#141414',
            //     color: 'white',
            //     confirmButtonColor: '#f2674a',
            // });
            setheading('Error')
            setShowPopup(true)
            setmsg('Please fix the validation errors before updating.')
            return;
        }

        try {
            setLoading(true);
            const stateAbbreviation = states.find((state) => state.name === formik.values.selectedState)?.abbreviation || '';

            if (!stateAbbreviation) {
                console.error('State abbreviation not found for the selected state.');
                setLoading(false);
                return;
            }

            // Define the type for physicalAddress
            type PhysicalAddress = {
                address1: string;
                city: string;
                state: string;
                postalCode: string;
                address2?: string;
            };

            // Start building the physicalAddress object
            const physicalAddress: PhysicalAddress = {
                address1: formik.values.addressLine1,
                city: formik.values.city,
                state: stateAbbreviation,
                postalCode: formik.values.zipCode,
            };

            // Only add addressLine2 if it has a value
            if (formik.values.addressLine2.trim() !== '') {
                physicalAddress.address2 = formik.values.addressLine2;
            }

            const payload = {
                accountId: user?.tradingblock_application_id,
                physicalAddress,
            };

            const response = await Axios.post(UrlConstant.Address.updateAddress, payload);
            if (response.data?.status) {
                handleClose();
                // Swal.fire({
                //     title: 'Success!',
                //     text: 'Address updated successfully.',
                //     icon: 'success',
                //     confirmButtonText: 'OK',
                //     background: '#141414',
                //     color: 'white',
                //     confirmButtonColor: '#f2674a',
                // });
                setheading('Success')
                setSuccess(true)
                setShowPopup(true)
                setmsg('Address updated successfully.')
            } else {
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'Failed to update the address. Please try again.',
                //     icon: 'error',
                //     confirmButtonText: 'OK',
                //     background: '#141414',
                //     color: 'white',
                //     confirmButtonColor: '#f2674a',
                // });
                setheading('Error')
                setShowPopup(true)
                setmsg('Failed to update the address. Please try again.')
                console.error('Error updating account details:', response.data);
            }
        } catch (error) {
            // Swal.fire({
            //     title: 'Error!',
            //     text: 'An unexpected error occurred. Please try again later.',
            //     icon: 'error',
            //     confirmButtonText: 'OK',
            //     background: '#141414',
            //     color: 'white',
            //     confirmButtonColor: '#f2674a',
            // });
            setheading('Error')
            setShowPopup(true)
            setmsg('An unexpected error occurred. Please try again later.')
            console.error('Error updating account details:', error);
        } finally {
            setLoading(false);
        }
    };





    const handleClose = () => {
        setAddressLine1('');
        setAddressLine2('');
        setCity('');
        setZipCode('');
        setSelectedState('');
        onClose();
    };
    const closePopup = () => {
        setShowPopup(false); // Function to close the popup
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={false}
                PaperProps={{
                    sx: {
                        height: '664px',
                        width: '620px',
                        marginTop: '180px',
                        marginLeft: '410px',
                        borderRadius: '10px',
                        bgcolor: '#141414',
                        position: 'center',
                        transform: 'translate(-30%, -10%)',
                        boxShadow: '0px 10px 30px rgba(170, 170, 170, 0.7)',
                    },
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress
                            sx={{
                                color: '#f2674a',
                            }}
                        />
                    </Box>
                ) : (
                    <>
                        <Box
                            sx={{
                                height: '36px',
                                width: '560px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '10px 30px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: '800',
                                    fontSize: '24px',
                                    lineHeight: '32.78px',
                                    color: '#FFFFFF',
                                    marginTop: '22px',
                                }}
                            >
                                Address
                            </Typography>
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    height: '36px',
                                    width: '36px',
                                    color: '#FFFFFF',
                                    fontWeight: '800',
                                    marginTop: '22px',
                                    marginLeft: '440px',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <CancelOutlinedIcon />
                            </IconButton>
                        </Box>
                        <Divider sx={{ backgroundColor: 'gray', marginTop: '20px' }} />
                        {/* Form fields go here */}
                        <Box
                            sx={{
                                height: '76px',
                                width: '560px',
                                display: "flex",
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: "10px 30px"
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel shrink={false} sx={{ color: "gray !important", marginTop: '30px', fontSize: "14px", marginLeft: '-10px' }}>
                                    Address Line 1
                                    <sup style={{ color: "red", marginLeft: "0px", fontSize: '14px' }}>*</sup>
                                </InputLabel>
                                <TextField
                                    value={formik.values.addressLine1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="addressLine1"
                                    error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
                                    helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
                                    sx={{ marginTop: '30px', color: "white !important" }}
                                    fullWidth
                                    InputLabelProps={{
                                        style: { color: "gray !important" },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: "white !important",
                                            backgroundColor: "#141414",
                                            borderRadius: "8px",
                                        },
                                    }}
                                    required
                                />
                            </FormControl>
                        </Box>

                        <Box
                            sx={{
                                height: '76px',
                                width: '560px',
                                display: "flex",
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: "10px 30px"
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel shrink={false} sx={{ color: "gray !important", marginTop: '20px', fontSize: "14px", marginLeft: '-10px' }}>
                                    Address Line 2
                                    {/* <sup style={{ color: "red", marginLeft: "0px", fontSize: '14px' }}>*</sup> */}
                                </InputLabel>
                                <TextField
                                    value={formik.values.addressLine2}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="addressLine2"
                                    error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
                                    helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
                                    sx={{ marginTop: '20px', color: "white !important" }}
                                    fullWidth
                                    InputLabelProps={{
                                        style: { color: "gray !important" },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: "white !important",
                                            backgroundColor: "#141414",
                                            borderRadius: "8px",
                                        },
                                    }}
                                    required
                                />
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                height: '76px',
                                width: '560px',
                                display: "flex",
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: "10px 30px"
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel shrink={false} sx={{ color: "gray !important", marginTop: '20px', fontSize: "14px", marginLeft: '-10px' }}>
                                    City
                                    <sup style={{ color: "red", marginLeft: "0px", fontSize: '14px' }}>*</sup>
                                </InputLabel>
                                <TextField
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="city"
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                    sx={{ marginTop: '20px', color: "white !important" }}
                                    fullWidth
                                    InputLabelProps={{
                                        style: { color: "gray !important" },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: "white !important",
                                            backgroundColor: "#141414",
                                            borderRadius: "8px",
                                        },
                                    }}
                                    required
                                />
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                height: '76px',
                                width: '560px',
                                display: "flex",
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: "20px 30px"
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel shrink={false} sx={{ color: "gray !important", marginTop: '0px', fontSize: "14px", marginLeft: '-10px' }}>
                                    State
                                    <sup style={{ color: "red", marginLeft: "0px", fontSize: '14px' }}>*</sup>
                                </InputLabel>
                                <Select
                                    value={formik.values.selectedState}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="selectedState"
                                    error={formik.touched.selectedState && Boolean(formik.errors.selectedState)}
                                    fullWidth
                                    sx={{
                                        color: "white",
                                        backgroundColor: "#141414",
                                        borderRadius: "8px",
                                        height: "56px",
                                        "& .MuiSelect-icon": { color: "white" },
                                    }} MenuProps={{
                                        PaperProps: {
                                            style: {
                                                backgroundColor: '#141414',
                                            },
                                        },
                                    }}
                                >
                                    {states.map((state) => (
                                        <MenuItem key={state.abbreviation} value={state.name}>
                                            {state.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.selectedState && formik.errors.selectedState && (
                                    <FormHelperText error>{formik.errors.selectedState}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                height: '76px',
                                width: '560px',
                                display: "flex",
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: "10px 30px"
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel shrink={false} sx={{ color: "gray !important", marginTop: '20px', fontSize: "14px", marginLeft: '-10px' }}>
                                    Zip/Postal Code
                                    <sup style={{ color: "red", marginLeft: "0px", fontSize: '14px' }}>*</sup>
                                </InputLabel>
                                <TextField
                                    value={formik.values.zipCode}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="zipCode"
                                    error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                                    sx={{ marginTop: '20px', color: "white !important" }}
                                    fullWidth
                                    InputLabelProps={{
                                        style: { color: "gray !important" },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: "white !important",
                                            backgroundColor: "#141414",
                                            borderRadius: "8px",
                                        },
                                    }}
                                    required
                                />
                            </FormControl>
                        </Box>
                        <Divider sx={{ backgroundColor: 'gray', marginTop: '20px' }} />
                        <Box
                            sx={{
                                height: '76px',
                                width: '560px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '10px 30px',
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleUpdate}
                                sx={{
                                    fontSize: '15px',
                                    fontWeight: '50px',
                                    width: '560px',
                                    height: '48px',
                                    backgroundColor: '#f2674a',
                                    color: 'white',
                                    borderRadius: '1234px',
                                    textTransform: 'none',
                                    transition: 'background-color 0.3s ease, transform 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#e55b3a',
                                    },
                                }}
                            >
                                Update
                            </Button>

                        </Box>
                    </>
                )}
            </Dialog>
            {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

        </>

    );
}

export default AddressPopup;
