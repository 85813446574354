import React, { useState, useEffect } from 'react'
import { Container, Button, Box, Typography, Divider, FormControl, TextField, ToggleButtonGroup, ToggleButton, unstable_createBreakpoints } from '@mui/material'

import UserStocks from '../Tabels/UserStocks';
import Axios from '../../../config/axios';
import { UrlConstant } from '../../../commom/UrlConstant';
import { useNavigate, useLocation } from 'react-router-dom';
import followimg from '../../../style/images/follow.png'

interface UserData {
  _id: string;
  profile_pic: string;
  firstName: string;
  lastName: string;
  totalFollowers: number;
  totalFollowing: number;
  isFollow: boolean;
  isBlock: boolean;
}
function UserProfile() {
  const location = useLocation();
  const userIdFromNavLink = location.state?.userId;
  const [userData, setUserData] = useState<UserData | null>(null);
  const [selectedbutton, setselectedbutton] = React.useState('Stock');
  const [searchText, setSearchText] = useState("");
  const [followers, setfollowers] = useState(true);
  const handleBlock = () => {
    if (userData) {
      Axios.get(UrlConstant.Profile.userBlock.replace("{0}", userData._id))
        .then(() => {
          setUserData(prevData => prevData ? { ...prevData, isBlock: true } : prevData);
        })
        .catch((error) => {
          console.error('Error blocking user:', error);
        });
    }
  };

  const handleUnblock = () => {
    if (userData) {
      Axios.get(UrlConstant.Profile.userUnBlock.replace("{0}", userData._id))
        .then(() => {
          setUserData(prevData => prevData ? { ...prevData, isBlock: false } : prevData);
        })
        .catch((error) => {
          console.error('Error unblocking user:', error);
        });
    }
  };

  const handleClick = (value: string) => {
    setfollowers(false)
    setselectedbutton(value);

  };

  const handleFollow = () => {
    if (userData) {
      Axios.post(UrlConstant.Profile.userFollow.replace("{0}", userData._id))
        .then(() => {
          // Update userData with the new follow status
          setUserData(prevData => {
            if (prevData) {
              return { ...prevData, isFollow: true };
            }
            return prevData;
          });
        })
        .catch((error) => {
          console.error('Error following user:', error);
        });
    }
  };
  const handleUnfollow = () => {
    if (userData) {
      Axios.post(UrlConstant.Profile.userUnfollow.replace("{0}", userData._id))
        .then(() => {
          // Update userData with the new unfollow status
          setUserData(prevData => {
            if (prevData) {
              return { ...prevData, isFollow: false };
            }
            return prevData;
          });
        })
        .catch((error) => {
          console.error('Error unfollowing user:', error);
        });
    }
  };
  useEffect(() => {
    Axios.post(UrlConstant.Profile.getProfile.replace("{0}", userIdFromNavLink))
      .then(response => {
        setUserData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }, [userIdFromNavLink]);
  const navigation = useNavigate()
  return (
    <>
      <Container maxWidth={false} className="bg-color" style={{ minHeight: 'calc(100vh - 131px - 143px)', padding: '30px' }}>
        <Typography className="text-secondary" sx={{ marginBottom: '30px' }}>
          <span onClick={() => navigation(-1)} style={{ color: "#4A7AF2", cursor: "pointer" }}>MyProfile</span> / {userData?.firstName || 'User'}
        </Typography>
        <Box sx={{ display: 'flex', gap: '20px', width: '100%' }}>
          <Box height='100%' minHeight='calc(100vh - 131px - 143px)' className='card' width='30%' padding='20px'>
            <Box height='fit-content' width='100%' padding='30px' borderRadius='20px' gap='20px' className='bg-color' display='flex' flexDirection='column' alignItems='center'>
              <Box component='img' src={userData?.profile_pic || '/static/images/avatar/1.jpg'} border='1px solid white' height='100px' width='100px' borderRadius='50%' />
              <Typography className='text-white' fontSize='24px' fontWeight='bold'>{userData ? `${userData.firstName} ${userData.lastName}` : 'Loading...'}</Typography>
              <Box display='flex' gap='10px'>
                <Button
                  className={` ${userData?.isFollow ? '' : 'btn btn-primary'}`}
                  sx={{
                     border:userData?.isFollow?'1px solid #F2674A':'',
                     padding:userData?.isFollow?'7px 40px':'7px 52px',
                     borderRadius:'50px',
                     color:userData?.isFollow?'#F2674A':'white'
                    // borderColor:'red'
                  }}
                  onClick={userData?.isFollow ? handleUnfollow : handleFollow} // Toggle follow/unfollow
                >
                  {userData?.isFollow ? 'Unfollow' : 'Follow'}
                </Button>
                <Button  variant='outlined' sx={{borderRadius:'50px', padding: '13px 52px', borderColor:'red', color:'red'}} onClick={userData?.isBlock ? handleUnblock : handleBlock}>
                  {userData?.isBlock ? 'Unblock' : 'Block'}
                </Button>
              </Box>
            </Box>
            <Box mt='20px' width='100%'>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Button disabled={!userData?.isFollow} onClick={() => handleClick("Stock")} sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderRadius: "10px !important",
                  justifyContent: "start !important",
                  width: "100%",
                  border: selectedbutton === "Stock" && userData?.isFollow ? "1px solid #F2674A" : "1px solid #ffffff33",
                  bgcolor: selectedbutton === "Stock" && userData?.isFollow ? "#F2674A1A" : "#ffffff1a",
                  color: selectedbutton === "Stock" && userData?.isFollow ? "white" : "white",
                  "&:hover": {
                    border: "1px solid #F2674A",
                    bgcolor: "#F2674A1A",
                  },
                  "& MuiButtonBase-root-MuiButton-root.Mui-disabled": {
                   color:'green !important',
                  },
                }}>
                  <svg style={{ marginRight: '10px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <svg style={{ marginRight: '10px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00001 3.75C5.20507 3.75 3.75 5.20507 3.75 7.00002V17C3.75 18.7949 5.20507 20.25 7.00001 20.25H17C18.7949 20.25 20.25 18.7949 20.25 17V7.00002C20.25 5.20507 18.7949 3.75 17 3.75H7.00001ZM2.25 7.00002C2.25 4.37665 4.37665 2.25 7.00001 2.25H17C19.6233 2.25 21.75 4.37665 21.75 7.00002V17C21.75 19.6234 19.6233 21.75 17 21.75H7.00001C4.37665 21.75 2.25 19.6234 2.25 17V7.00002Z" fill={selectedbutton === 'Stock'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'}/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5161 8.15265C14.8406 8.41005 14.895 8.88183 14.6376 9.20637L10.4576 14.4764C10.2151 14.7821 9.77871 14.8508 9.45399 14.6343L6.76394 12.8409L3.58197 16.9308C3.32762 17.2577 2.8564 17.3166 2.52948 17.0622C2.20256 16.8079 2.14373 16.3367 2.39808 16.0097L6.00806 11.3697C6.24932 11.0596 6.68906 10.9883 7.016 11.2062L9.71151 13.0032L13.4624 8.27421C13.7198 7.94967 14.1916 7.89525 14.5161 8.15265Z" fill={selectedbutton === 'Stock'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'} />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5701 8.61914C10.5701 8.2049 10.9058 7.86914 11.3201 7.86914H14.48C14.8943 7.86914 15.23 8.2049 15.23 8.61914V11.7691C15.23 12.1833 14.8943 12.5191 14.48 12.5191C14.0658 12.5191 13.73 12.1833 13.73 11.7691V9.36914H11.3201C10.9058 9.36914 10.5701 9.03332 10.5701 8.61914Z" fill={selectedbutton === 'Stock'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'} />
                    </svg>
                  </svg>
                  Stock
                </Button>
                <Button disabled={!userData?.isFollow} onClick={() => handleClick("ETF")} sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderRadius: "10px !important",
                  justifyContent: "start !important",
                  width: "100%",
                  border: selectedbutton === "ETF" && userData?.isFollow ? "1px solid #F2674A" : "1px solid #ffffff33",
                  bgcolor: selectedbutton === "ETF" && userData?.isFollow ? "#F2674A1A" : "#ffffff1a",
                  color: selectedbutton === "ETF" && userData?.isFollow ? "white" : "white",
                  "&:hover": {
                    border: "1px solid #F2674A",
                    bgcolor: "#F2674A1A",
                  },
                }}>
                  <svg style={{ marginRight: '10px' }} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <svg style={{ marginRight: '10px' }} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.37 2.43695L21.37 6.03693C21.72 6.17693 22 6.59692 22 6.96692V10.2869C22 10.8369 21.55 11.2869 21 11.2869H3C2.45 11.2869 2 10.8369 2 10.2869V6.96692C2 6.59692 2.28 6.17693 2.63 6.03693L11.63 2.43695C11.83 2.35695 12.17 2.35695 12.37 2.43695Z" stroke={selectedbutton === 'ETF'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'}
                        stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M22 22.2871H2V19.2871C2 18.7371 2.45 18.2871 3 18.2871H21C21.55 18.2871 22 18.7371 22 19.2871V22.2871Z" stroke={selectedbutton === 'ETF'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'}
                        stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M4 18.2871V11.2871" stroke={selectedbutton === 'ETF'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'}
                        stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8 18.2871V11.2871" stroke={selectedbutton === 'ETF'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'}
                        stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M12 18.2871V11.2871" stroke={selectedbutton === 'ETF'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'}
                        stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M16 18.2871V11.2871" stroke={selectedbutton === 'ETF'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'}
                        stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M20 18.2871V11.2871" stroke={selectedbutton === 'ETF'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'}
                        stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M1 22.2871H23" stroke={selectedbutton === 'ETF'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'}
                        stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M12 8.78711C12.8284 8.78711 13.5 8.11554 13.5 7.28711C13.5 6.45868 12.8284 5.78711 12 5.78711C11.1716 5.78711 10.5 6.45868 10.5 7.28711C10.5 8.11554 11.1716 8.78711 12 8.78711Z" stroke={selectedbutton === 'ETF'&& userData?.isFollow? "#F2674A" : userData?.isFollow ?"white":'grey'}
                        stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </svg>
                  ETF
                </Button>
              </Box>
            </Box>
          </Box>
          <Box height='calc(100vh - 131px - 143px)' className='card' padding='20px' width='70%'>
            {userData?.isFollow === true ?
              <>
                <Typography fontSize='24px' mb='20px' className='text-white'>{userData ? `${userData.firstName} ${userData.lastName}` : 'Loading...'} -  {selectedbutton === 'Stock' ? "Stock" : "ETF"}</Typography>
                <Box height='100%'>
                  <UserStocks type={selectedbutton as 'Stock' | 'ETF'} id={userIdFromNavLink} />
                </Box>
              </>
              :
              <>
                <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                  <Box>
                    <Box component='img' src={followimg} mr='50px' height='240px' width='400px' />
                    <Typography fontSize='26px' my='30px' className='text-white' fontWeight='bold' width='350px' mx='auto'>Follow to Unlock Exclusive
                      Details and Stay Informed</Typography>
                    <Button
                    fullWidth
                      className='btn btn-primary'
                    
                      sx={{ padding: '13px 52px', fontSize:'16px', fontWeight:'bold' }}
                      onClick={handleFollow} // Toggle follow/unfollow
                    >
                      Follow
                    </Button>
                  </Box>
                </Box>
              </>
            }

          </Box>
        </Box>
      </Container>
    </>
  )
}

export default UserProfile