import { Box, List, ListItem, Typography } from "@mui/material";

function TradingACNotice() {
  const Cancel = () => {};
  const proceed = () => {};
  return (
    <>
      <Box display="flex" flexDirection="column" gap={2} textAlign="center">
        <Box display="flex" justifyContent="center">
          <svg
            width="194"
            height="200"
            viewBox="0 0 194 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M75.1344 34.3243C66.2355 35.3604 63.2283 18.4519 48.0871 14.6468C37.8452 12.0729 28.8873 17.2127 27.1432 18.2638C19.9777 22.5826 13.5948 30.808 13.0307 40.0834C12.1804 54.0676 25.0528 62.2765 21.1479 68.996C18.1283 74.1923 9.16637 71.4582 6.71468 77.0293C4.69308 81.6229 8.91514 87.733 11.4732 91.4348C18.4702 101.56 24.4293 100.741 26.5993 106.033C30.8387 116.372 10.5937 125.595 5.86689 145.9C2.25088 161.433 8.18474 181.44 22.3756 192.063C39.3749 204.787 63.1752 200.266 76.8876 192.063C91.7579 183.166 89.4012 173.148 101.37 169.969C115.805 166.136 123.03 179.698 150.089 181.17C158.932 181.651 168.4 182.036 173.452 175.983C180.564 167.461 174.274 151.752 173.277 149.264C165.762 130.495 148.562 124.363 151.046 117.326C152.606 112.902 160.689 111.679 161.786 111.512C169.943 110.278 173.826 115.397 179.706 113.241C183.154 111.976 186.808 108.385 187.374 104.431C189.014 92.9881 163.637 86.6032 164.086 74.4563C164.364 66.9569 174.149 66.3538 182.797 54.6071C191.325 43.0233 197.312 22.5518 188.507 10.5176C178.818 -2.72681 153.398 -2.76472 137.81 6.50609C119.261 17.5386 116.352 40.7331 107.518 39.5649C99.84 38.5495 98.4418 20.5526 90.905 20.373C84.4339 20.2187 82.8645 33.4244 75.1344 34.3243Z"
              fill="white"
              fill-opacity="0.1"
            />
            <path
              d="M174.376 120.177C171.735 119.296 160.965 115.704 159.249 118.607C157.327 121.86 166.55 133.638 178.664 136.322C184.817 137.686 193.06 137 193.924 134.056C195.032 130.281 183.844 123.335 174.376 120.177Z"
              fill="#4EDF6D"
            />
            <path
              d="M80.6003 20.2144C80.2635 23.5311 77.342 27.3368 73.4843 27.3244C69.4682 27.3114 67.0034 23.1687 66.3683 20.2144C65.5389 16.3567 67.1893 11.5494 70.2619 10.5181C74.4017 9.12869 81.1727 14.5772 80.6003 20.2144Z"
              fill="#4EDF6D"
            />
            <path
              d="M110.373 10.5175C106.621 11.6912 100.911 13.4777 99.7306 18.298C98.6237 22.8178 101.848 28.5825 105.701 29.45C113.231 31.1455 124.415 14.4201 121.535 10.2581C119.696 7.60093 112.069 9.98671 110.373 10.5175Z"
              fill="#4EDF6D"
            />
            <path
              d="M72.355 189.454C67.6215 190.208 62.3495 188.7 59.0403 185.357L14.5526 140.414C10.6934 136.515 10.9442 130.508 15.1734 126.873C19.5157 123.142 28.5366 122.451 32.7011 126.479L80.8759 173.077C85.6608 177.706 84.8697 184.156 79.2066 187.349C77.8304 188.124 73.9334 189.203 72.355 189.454Z"
              fill="#4A7AF2"
            />
            <path
              d="M27.7492 131.63C30.5147 139.3 26.7478 147.681 19.3357 150.349C11.9236 153.017 3.67301 148.962 0.90757 141.292C-1.85787 133.623 1.90896 125.242 9.32102 122.574C16.7331 119.906 24.9837 123.961 27.7492 131.63Z"
              fill="#F2674A"
            />
            <path
              d="M27.7492 131.63C30.5147 139.3 26.7478 147.681 19.3357 150.349C11.9236 153.017 3.67301 148.962 0.90757 141.292C-1.85787 133.623 1.90896 125.242 9.32102 122.574C16.7331 119.906 24.9837 123.961 27.7492 131.63Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M8.67358 123.196C8.88474 133.523 12.1296 142.522 18.5542 150.598L108.212 156.917L74.3975 59.9941L8.67358 123.196Z"
              fill="#F2674A"
            />
            <path
              d="M109.185 156.497C129.281 149.264 137.783 121.799 128.175 95.1519C118.568 68.5045 94.4887 52.7657 74.3931 59.9982C54.2975 67.2307 45.7953 94.6957 55.4029 121.343C65.0106 147.99 89.0897 163.729 109.185 156.497Z"
              fill="#D8DEE8"
            />
            <path
              d="M108.604 154.856C112.87 153.321 116.652 150.674 119.844 146.989C123.093 143.239 125.585 138.609 127.251 133.228C130.752 121.916 130.169 108.721 125.608 96.0731C121.047 83.4252 113.074 72.89 103.156 66.4082C98.4388 63.3248 93.564 61.3466 88.6679 60.5283C83.8571 59.7242 79.2545 60.0953 74.9882 61.6309C70.7219 63.1664 66.94 65.8134 63.7478 69.4981C60.4989 73.2482 58.007 77.878 56.3414 83.259C54.8775 87.9887 54.1276 93.0477 54.084 98.2583C54.0234 105.51 63.2763 109.726 65.9301 117.085C68.752 124.911 64.9344 135.256 70.0213 141.005C73.1559 144.547 76.6545 147.608 80.4357 150.079C85.1534 153.162 90.0281 155.14 94.9243 155.959C99.7351 156.763 104.338 156.392 108.604 154.856Z"
              fill="#F2674A"
            />
            <mask
              id="mask0_869_2872"
              mask-type="luminance"
              maskUnits="userSpaceOnUse"
              x="54"
              y="60"
              width="76"
              height="97"
            >
              <path
                d="M108.604 154.856C112.87 153.321 116.652 150.674 119.844 146.989C123.093 143.239 125.585 138.609 127.251 133.228C130.752 121.916 130.168 108.721 125.608 96.0731C121.047 83.4252 113.073 72.89 103.156 66.4082C98.4385 63.3248 93.5638 61.3466 88.6676 60.5283C83.8569 59.7242 79.2543 60.0953 74.9879 61.6309C70.7216 63.1664 66.9397 65.8134 63.7476 69.4981C60.4987 73.2482 58.0067 77.878 56.3412 83.259C52.8399 94.5707 53.4234 107.766 57.984 120.414C62.5447 133.062 70.5182 143.597 80.4354 150.079C85.1532 153.162 90.0279 155.14 94.9241 155.959C99.7349 156.763 104.337 156.392 108.604 154.856Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_869_2872)">
              <path
                d="M95.0185 107.799C99.4264 120.023 92.4823 133.488 79.6916 137.813C67.0987 142.07 53.5582 135.84 49.2731 123.955C44.9878 112.071 51.4833 98.7644 63.9545 94.1695C76.6217 89.5024 90.6107 95.5746 95.0185 107.799Z"
                fill="#4EDF6D"
              />
            </g>
            <path
              d="M125.817 93.7593L125.226 93.5521L124.781 93.9939C123.224 95.5416 120.409 97.7911 116.46 98.818C112.814 99.7663 108.117 99.695 102.424 96.931C103.049 96.3606 103.882 95.5581 104.803 94.5611C106.743 92.4619 109.101 89.4668 110.694 85.9338L110.994 85.2686L110.452 84.78C104.042 79.001 99.8215 71.5021 98.838 63.1266C96.4839 43.0798 113.449 23.7623 138.172 20.1042L138.026 19.1156L138.172 20.1042C164.16 16.2589 187.993 31.1139 190.567 53.0312C193.139 74.9388 173.373 94.5945 147.157 96.5027C139.552 97.0563 132.294 96.0287 125.817 93.7593Z"
              fill="#141414"
              stroke="white"
              stroke-width="2"
            />
            <path
              d="M138.969 78.6942C138.908 76.9228 139.472 75.4472 140.66 74.2678C141.849 73.0887 143.361 72.4669 145.197 72.4038C147.033 72.3407 148.585 72.8571 149.852 73.9518C151.119 75.0468 151.783 76.4802 151.844 78.2516C151.905 80.0237 151.341 81.4991 150.152 82.6782C148.963 83.8574 147.451 84.4789 145.615 84.542C143.779 84.6051 142.228 84.089 140.961 82.9943C139.694 81.8995 139.03 80.4663 138.969 78.6942ZM149.808 67.1758L140.41 67.4989L137.699 31.8175L150.058 31.3926L149.808 67.1758Z"
              fill="#DFDD4E"
            />
            <path
              d="M69.4164 57.7469C66.8675 50.1038 63.1507 41.3346 59.0785 41.5162C56.0397 41.6518 55.3118 46.6596 52.0636 47.0494C44.9762 47.8999 39.1499 25.1741 34.3416 26.0232C31.5859 26.5099 29.8634 34.617 31.388 41.5161C34.2159 54.3147 48.3566 63.5875 53.9097 60.6979C58.0435 58.5468 56.3436 50.2151 59.8169 49.2626C62.5069 48.525 66.1544 52.8017 69.4164 57.7469Z"
              fill="#4A7AF2"
            />
            <path
              d="M82.3384 53.3191C83.7579 45.4286 83.0466 43.0992 81.9693 42.6215C80.4424 41.9447 78.7302 45.2305 75.6929 45.2037C70.4982 45.1579 66.747 35.4697 66.463 35.6129C65.3576 36.17 69.7141 52.0894 73.8469 51.8436C75.3259 51.7556 75.9719 49.6338 77.9081 49.6303C79.7085 49.627 81.2255 51.4577 82.3384 53.3191Z"
              fill="#4A7AF2"
            />
            <path
              d="M131.813 130.785C131.917 129.243 147.816 127.568 149.165 131.523C149.818 133.435 146.419 134.756 146.212 137.794C145.689 145.461 166.393 150.99 165.78 153.287C165.076 155.922 136.717 152.828 135.874 145.54C135.454 141.912 142.073 139.149 141.043 135.581C139.956 131.819 131.737 131.897 131.813 130.785Z"
              fill="#4A7AF2"
            />
            <path
              d="M135.874 116.398C136.305 114.245 144.541 112.462 146.581 115.66C147.607 117.27 147.04 120.102 145.473 121.194C142.359 123.362 135.443 118.551 135.874 116.398Z"
              fill="#4A7AF2"
            />
          </svg>
        </Box>
        <Typography variant="h6" className="text-white" fontWeight={700}>
          Important Notice
        </Typography>
        <Typography
          variant="body2"
          className="text-white"
          textAlign="start"
          fontWeight={500}
        >
          In order to create a TradingBlock Brokerage Account, you must meet the
          following requirements:
        </Typography>
        <List sx={{ listStyleType: "disc", pl: 2 }} className="text-white">
          <ListItem sx={{ display: "list-item", color: "white", pl: 0 }}>
            <Typography variant="body2">
              {" "}
              Be a USA citizen or USA resident
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", color: "white", pl: 0 }}>
            <Typography variant="body2">
              {" "}
              Have a valid Social Security Number (SSN)
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", color: "white", pl: 0 }}>
            <Typography variant="body2">
              Have a valid USA bank account
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", color: "white", pl: 0 }}>
            <Typography variant="body2">
              {" "}
              Be 18 years of age or older
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="body2"
          textAlign="start"
          className="text-white"
          fontWeight={500}
        >
          If you meet the above requirements, press Proceed to continue.
        </Typography>
      </Box>
    </>
  );
}

export default TradingACNotice;
