import React, { useState } from "react";
import CreateAutoTopupPopup from "../Popups/CreateAutoTopupPopup"; // Import the popup component
import { Container, Typography, ToggleButton, ToggleButtonGroup, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Upcoming from "../Cards/Upcoming";
import HistoryTopup from "../Cards/HistoryTopup";

function AutoTopup() {
    const [selectedCategory, setSelectedCategory] = useState<string>("Upcoming");
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const navigation = useNavigate();
    const handleSelectedCategory = (
        event: React.MouseEvent<HTMLElement>,
        newCategory: string | null
    ) => {
        if (newCategory) {
            setSelectedCategory(newCategory);
        }
    };

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <>
            <Container
                maxWidth={false}
                className="bg-color"
                style={{
                    minHeight: "calc(100vh - 89px - 143px)",
                    padding: "30px",
                }}
            >
                {/* Breadcrumb Navigation */}
                <Typography
                    className="text-secondary"
                    sx={{ marginBottom: "20px" }}
                >
                    <span
                        onClick={() => navigation("/moreopt")}
                        style={{ color: "#4A7AF2", cursor: "pointer" }}
                    >
                        More Options
                    </span>{" "}
                    / Auto Topups
                </Typography>

                {/* Header Section */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                    }}
                >
                    <Typography
                        variant="body1"
                        fontSize="24px"
                        fontWeight={400}
                        className="text-white"
                    >
                        Auto Topups
                    </Typography>

                    <Button
                        variant="contained"
                        onClick={handleOpenPopup}
                        sx={{
                            borderRadius: "50px",
                            padding: "5px 20px",
                            backgroundColor: "#f2674a",
                            textTransform: "none",
                            "&:hover": { backgroundColor: "#e05d3f" },
                        }}
                    >
                        Create Auto Topup
                    </Button>
                </Box>

                <ToggleButtonGroup
                    value={selectedCategory}
                    exclusive
                    onChange={handleSelectedCategory}
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "20px",
                        bgcolor: "#ffffff1a",
                        padding: '0px',
                        marginBottom: '20px'
                    }}
                    aria-label="Auto Topup Options"
                >
                    <ToggleButton
                        value="Upcoming"
                        className="text-white"
                        sx={{
                            flexGrow: 1,
                            textTransform: "none",
                            fontWeight: 600,
                            fontSize: "20px",
                            borderColor: "transparent !important",
                            borderRadius: "20px !important",
                            "&.Mui-selected": {
                                bgcolor: "#f2674a !important",
                                color: "white !important",
                                borderColor: "#f2674a !important",
                            },
                            "&:hover": {
                                bgcolor: "#f2674a !important",
                                color: "white !important",
                                borderColor: "#f2674a !important",
                            },
                        }}
                    >
                        Upcoming
                    </ToggleButton>
                    <ToggleButton
                        value="History"
                        className="text-white"
                        sx={{
                            flexGrow: 1,
                            textTransform: "none",
                            fontWeight: 600,
                            margin: "0px !important",
                            fontSize: "20px",
                            borderColor: "transparent !important",
                            borderRadius: "20px !important",
                            "&.Mui-selected": {
                                bgcolor: "#f2674a !important",
                                color: "white !important",
                                borderColor: "#f2674a !important",
                            },
                            "&:hover": {
                                bgcolor: "#f2674a !important",
                                color: "white !important",
                                borderColor: "#f2674a !important",
                            },
                        }}
                    >
                        History
                    </ToggleButton>
                </ToggleButtonGroup>

                {selectedCategory === "Upcoming" ? (
                    <> 
                     <Typography
                        variant="body1"
                        fontSize="24px"
                        fontWeight={400}
                        className="text-white"
                        sx={{ margin: "0px" }}
                    >
                        Upcoming Auto Topups
                    </Typography>
                        <Upcoming />
                    </>
                ) : (
                    <Box height='fit-content'>
                        <Typography
                            variant="body1"
                            fontSize="24px"
                            fontWeight={400}
                            className="text-white"
                            sx={{ margin: "0px" }}
                        >
                            History Topup
                        </Typography>
                        <HistoryTopup isrepayment={selectedCategory} />
                    </Box>
                )}
            </Container>
            <CreateAutoTopupPopup open={isPopupOpen} onClose={handleClosePopup} />
        </>
    );
}

export default AutoTopup;
