import React, { useEffect, useState } from 'react';
import axios from '../../../config/axios';
import Swal from 'sweetalert2';
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { UrlConstant } from '../../../commom/UrlConstant';
import { getUserFromLocalStorage } from "../../../Utils/utils";
import Successpopup from '../SharedComponent/Successpopup';

interface Transaction {
  company: string;
  companyName: string;
  symbol: string;
  time: string;
  stockPrice: number;
  qty: number;
  totalCost: number;
  type: 'Buy' | 'Sell';
  orderId: string;
  exchange: string;
  logoUrl: string;
}

const OpenOrder: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const user = getUserFromLocalStorage();
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [isDialogOpen, setDialogOpen] = useState(false); // For controlling dialog visibility
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null); // For storing the order ID
  const [heading, setheading] = useState(''); // State for controlling popup visibility


  const fetchOpenOrders = async () => {
    try {
      const response = await axios.post(UrlConstant.Order.orderList, {
        accountId: user?.tradingblock_application_id,
        pageIndex: '0',
        pageSize: '50',
        type: 'open',
      });

      const formattedData = response.data.data.map((order: any) => ({
        company: `${order.exchange}:${order.symbol}`,
        companyName: order.companyName,
        symbol: order.symbol,
        time: order.time,
        stockPrice: order.stockPrice,
        qty: order.qty?.toFixed(2),
        totalCost: order.totalCost,
        type: order.type === 'Buy' ? 'Buy' : 'Sell',
        orderId: order.orderId,
        logoUrl: `${UrlConstant.StockIcon.baseUrl}${order.symbol}${UrlConstant.StockIcon.fileType}${UrlConstant.StockIcon.apiKey}`,
      }));
      setTransactions(formattedData);
    } catch (error) {
      console.error('Error fetching open orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOpenOrders();
  }, []);

  const handleCancelOrderClick = (orderId: string) => {
    setSelectedOrderId(orderId); // Store the selected order ID
    setDialogOpen(true); // Open the confirmation dialog
  };
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };
  const confirmCancelOrder = async () => {
    try {
      const requestBody = { accountId: user?.tradingblock_application_id, orderId: selectedOrderId };
      const response = await axios.post(UrlConstant.Order.cancelOrderList, requestBody);

      if (response.data.status) {
        setSuccess(true);
        setShowPopup(true);
        setmsg('Order canceled successfully!');
        fetchOpenOrders(); // Reload the orders
      } else {
        setShowPopup(true);
        setmsg('Failed to cancel the order. Please try again.');
      }
    } catch (error) {
      console.error('Error canceling the order:', error);
      setShowPopup(true);
      setmsg('An error occurred while canceling the order.');
    } finally {
      setDialogOpen(false); // Close the dialog
      setSelectedOrderId(null); // Clear the selected order ID
    }
  };
  return (
    <TableContainer
      component={Paper}
      style={{
        color: 'white',
        background: 'transparent',
        borderRadius: '20px',
        border: '2px solid #FFFFFF1A',
        height: '100%',
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px', // Adjust this value based on your layout
          }}
        >
          <CircularProgress style={{ color: 'orange' }} /> {/* Orange spinner */}
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow sx={{ '& th': { borderColor: '#FFFFFF1A', padding: '20px 30px' } }}>
              <TableCell style={{ fontSize: '12px', color: 'white' }}>Company</TableCell>
              <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}>Code</TableCell>
              <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}>Date & Time</TableCell>
              <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}>Price</TableCell>
              <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}>Qty</TableCell>
              <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}>Amount</TableCell>
              <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}>Type</TableCell>
              <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'center' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center', color: 'red', fontSize: '18px' }}>
                  No Order Available
                </TableCell>
              </TableRow>
            ) : (
              transactions.map((transaction, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '& td': { borderBottom: 'none !important', padding: '20px 30px' },
                  }}
                >
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Box
                        component="img"
                        src={transaction.logoUrl}
                        style={{
                          marginRight: '8px',
                          height: '36px',
                          width: '36px',
                          borderRadius: '50%',
                          backgroundColor: '#4A7AF2',
                        }}
                      />
                      <Typography style={{ fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
                        {transaction.company}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ fontSize: '16px', color: 'white', textAlign: 'start', fontWeight: 'bold' }}>
                    {transaction.symbol}
                  </TableCell>
                  <TableCell style={{ fontSize: '16px', color: 'white', textAlign: 'start', fontWeight: 'bold' }}>
                    {new Date(transaction.time).toLocaleDateString()} |{' '}
                    {new Date(transaction.time).toLocaleTimeString()}
                  </TableCell>
                  <TableCell style={{ fontSize: '16px', color: 'white', textAlign: 'start', fontWeight: 'bold' }}>
                    {`$ ${transaction.stockPrice.toFixed(2)}`}
                  </TableCell>
                  <TableCell style={{ fontSize: '16px', color: 'white', textAlign: 'start', fontWeight: 'bold' }}>
                    {transaction.qty}
                  </TableCell>
                  <TableCell style={{ fontSize: '16px', color: 'white', textAlign: 'start', fontWeight: 'bold' }}>
                    {`$ ${transaction.totalCost.toFixed(2)}`}
                  </TableCell>
                  <TableCell style={{ textAlign: 'start', fontWeight: 'bold' }}>
                    <Chip
                      label={transaction.type}
                      style={{
                        fontSize: '14px',
                        backgroundColor: transaction.type === 'Buy' ? '#4EDF6D33' : '#FF2A2A33',
                        color: transaction.type === 'Buy' ? '#4EDF6D' : '#FF2A2A',
                        padding: '5px 28px',
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', cursor: 'pointer' }}>
                    <CancelOutlined
                      sx={{ color: '#FF2A2A', cursor: 'pointer' }}
                      onClick={() => handleCancelOrderClick(transaction.orderId)}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      )}
      {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}
      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Rounded corners
            minWidth: "700px", // Consistent width
            border: "1px solid #FFFFFF1A", // Border style
          },
        }}
      >
        {/* Close Icon */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            marginTop: "20px",
            marginRight: "30px",
          }}
        >
          <IconButton onClick={() => setDialogOpen(false)}>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 33C26.25 33 33 26.25 33 18C33 9.75 26.25 3 18 3C9.75 3 3 9.75 3 18C3 26.25 9.75 33 18 33Z"
                stroke="white"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.7549 22.2449L22.2449 13.7549"
                stroke="white"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.2449 22.2449L13.7549 13.7549"
                stroke="white"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </IconButton>
        </Box>

        {/* Dialog Content */}
        <DialogContent
          sx={{
            padding: "0px 40px 30px 40px", // Same padding as the other dialog
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <svg width="359" height="250" viewBox="0 0 359 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M352.665 250H23.9488C20.4508 250 17.6143 247.169 17.6143 243.676C17.6143 240.184 20.45 237.352 23.9488 237.352H352.665C356.164 237.352 359 240.183 359 243.676C358.999 247.169 356.164 250 352.665 250Z" fill="#F2674A" />
            <path d="M321.041 174.138H114.422C104.339 174.138 96.165 165.977 96.165 155.91V18.2279C96.165 8.16131 104.339 0 114.422 0H321.041C331.124 0 339.298 8.16048 339.298 18.2279V155.91C339.298 165.976 331.124 174.138 321.041 174.138Z" fill="white" fill-opacity="0.1" />
            <path opacity="0.5" d="M339.298 26.4667H96.1641V18.2279C96.1641 8.16131 104.338 0 114.421 0H321.04C331.123 0 339.297 8.16048 339.297 18.2279V26.4667H339.298Z" fill="white" fill-opacity="0.1" />
            <path d="M298.616 17.2296H154.785C152.574 17.2296 150.782 15.4405 150.782 13.2335C150.782 11.0264 152.574 9.2373 154.785 9.2373H298.617C300.827 9.2373 302.619 11.0264 302.619 13.2335C302.619 15.4405 300.826 17.2296 298.616 17.2296Z" fill="white" fill-opacity="0.1" />
            <path d="M113.625 13.2336C113.625 15.0367 112.161 16.4985 110.355 16.4985C108.549 16.4985 107.085 15.0367 107.085 13.2336C107.085 11.4305 108.549 9.96875 110.355 9.96875C112.161 9.96875 113.625 11.4305 113.625 13.2336Z" fill="white" fill-opacity="0.1" />
            <path d="M125.833 13.2336C125.833 15.0367 124.369 16.4985 122.563 16.4985C120.757 16.4985 119.293 15.0367 119.293 13.2336C119.293 11.4305 120.757 9.96875 122.563 9.96875C124.369 9.96875 125.833 11.4305 125.833 13.2336Z" fill="white" fill-opacity="0.1" />
            <path d="M137.84 13.2336C137.84 15.0367 136.376 16.4985 134.57 16.4985C132.764 16.4985 131.3 15.0367 131.3 13.2336C131.3 11.4305 132.764 9.96875 134.57 9.96875C136.376 9.96875 137.84 11.4305 137.84 13.2336Z" fill="white" fill-opacity="0.1" />
            <path d="M235.085 64.5869L214.528 85.1101L193.972 64.5869L181.567 76.9719L202.124 97.4951L181.567 118.018L193.972 130.403L214.528 109.879L235.085 130.403L247.49 118.018L226.933 97.4951L247.49 76.9711L235.085 64.5869Z" fill="white" fill-opacity="0.1" />
            <path d="M107.379 181.875H9.63684C4.86713 181.875 1 178.014 1 173.252V108.121C1 103.359 4.86713 99.498 9.63684 99.498H107.379C112.148 99.498 116.016 103.359 116.016 108.121V173.252C116.016 178.014 112.148 181.875 107.379 181.875Z" fill="white" fill-opacity="0.1" />
            <path opacity="0.5" d="M116.016 112.018H1V108.121C1 103.359 4.86713 99.498 9.63684 99.498H107.379C112.148 99.498 116.016 103.359 116.016 108.121V112.018Z" fill="white" fill-opacity="0.1" />
            <path d="M96.7706 107.648H28.7304C27.685 107.648 26.8369 106.801 26.8369 105.758C26.8369 104.714 27.685 103.867 28.7304 103.867H96.7706C97.816 103.867 98.6641 104.714 98.6641 105.758C98.6641 106.802 97.8169 107.648 96.7706 107.648Z" fill="white" fill-opacity="0.1" />
            <path d="M9.26022 105.758C9.26022 106.611 8.56738 107.303 7.71353 107.303C6.85885 107.303 6.16602 106.611 6.16602 105.758C6.16602 104.905 6.85885 104.214 7.71353 104.214C8.56738 104.214 9.26022 104.906 9.26022 105.758Z" fill="white" fill-opacity="0.1" />
            <path d="M15.0346 105.758C15.0346 106.611 14.3418 107.303 13.4871 107.303C12.6324 107.303 11.9404 106.611 11.9404 105.758C11.9404 104.905 12.6333 104.214 13.4871 104.214C14.3418 104.214 15.0346 104.906 15.0346 105.758Z" fill="white" fill-opacity="0.1" />
            <path d="M20.7153 105.758C20.7153 106.611 20.0225 107.303 19.1686 107.303C18.3139 107.303 17.6211 106.611 17.6211 105.758C17.6211 104.905 18.3139 104.214 19.1686 104.214C20.0225 104.214 20.7153 104.906 20.7153 105.758Z" fill="white" fill-opacity="0.1" />
            <path d="M66.7172 130.052L56.9928 139.761L47.2684 130.052L41.4004 135.91L51.1248 145.619L41.4004 155.327L47.2684 161.187L56.9928 151.478L66.7172 161.187L72.5852 155.327L62.8608 145.619L72.5852 135.91L66.7172 130.052Z" fill="white" fill-opacity="0.1" />
            <path d="M107.379 181.875H9.63684C4.86713 181.875 1 178.014 1 173.252V108.121C1 103.359 4.86713 99.498 9.63684 99.498H107.379C112.148 99.498 116.016 103.359 116.016 108.121V173.252C116.016 178.014 112.148 181.875 107.379 181.875Z" stroke="white" stroke-opacity="0.1" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M299.87 237.352H215.672L222.324 142.255C222.72 136.582 227.648 131.983 233.33 131.983H296.953C302.635 131.983 306.919 136.582 306.523 142.255L299.87 237.352Z" fill="#4A7AF2" />
            <path d="M287.483 237.352H203.285L209.937 142.255C210.333 136.582 215.261 131.983 220.944 131.983H284.566C290.248 131.983 294.532 136.582 294.136 142.255L287.483 237.352Z" fill="#141414" fill-opacity="0.1" />
            <path d="M253.363 131.984C256.505 134.907 257.742 137.677 258.261 139.789C259.321 144.104 258.416 149.879 258.413 149.932C260.038 151.857 262.108 154.451 264.331 157.644C264.331 157.644 269.299 164.784 272.804 172.194C277.171 181.428 284.249 196.394 280.946 212.317C278.415 224.517 269.774 237.352 269.774 237.352H221.868L227.738 131.972L253.363 131.984Z" fill="#4A7AF2" />
            <path d="M163.725 72.3316C163.96 71.578 167.814 59.7503 176.866 55.7229C181.198 53.7961 186.081 53.6114 186.081 53.6114C186.586 53.5925 188.67 53.5298 191.477 53.9899C191.477 53.9899 197.956 54.7731 202.681 58.1171C210.994 64.0029 215.812 78.7392 214.925 91.5554C214.61 96.1122 213.527 100.793 216.495 104.294C218.096 106.183 220.752 106.81 224.519 107.963C232.346 110.357 245.222 113.382 250.397 123.843C255.573 134.304 256.204 149.049 252.795 153.082C249.387 157.115 168.046 156.737 165.166 153.586C162.285 150.435 156.984 135.186 156.352 123.086C155.791 112.331 163.337 107.876 165.166 94.6191C166.439 85.3868 165.071 77.519 163.725 72.3316Z" fill="#262626" />
            <path d="M210.129 71.1621C211.689 74.1994 213.926 79.0975 215.557 85.4359C218.133 95.4506 216.098 97.0484 218.46 100.905C223.704 109.471 235.088 104.24 245.979 114.012C252.088 119.493 254.203 126.251 254.916 128.633C258.013 138.972 255.124 147.841 253.903 151.043" stroke="#262626" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M166.704 91.2002C165.18 94.6316 162.722 100.064 159.509 106.702C153.64 118.827 152.003 121.03 152.446 125.353C153.215 132.843 159.613 140.687 159.613 140.687" stroke="#262626" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.146 117.497C179.146 117.497 200.021 117.497 200.113 117.497C216.691 117.497 234.85 129.343 245.753 140.916C254.475 150.174 260.857 161.427 265.7 173.182C272.007 188.492 278.183 209.705 270.469 225.55C269.317 227.915 266.706 233.103 261.168 235.903C255.075 238.983 248.71 237.144 246.737 237.144C233.861 237.144 119.746 237.179 119.746 237.179C116.696 207.583 122.571 185.285 126.815 173.12C130.757 161.817 135.369 149.056 147.403 136.939C158.928 125.334 171.635 119.986 179.146 117.497Z" fill="#FEC02D" />
            <path d="M178.191 117.497L163.548 237.691L154.081 237.618L168.745 121.247L178.191 117.497Z" fill="#EAA418" />
            <path d="M189.587 126.343C195.46 126.365 200.239 121.63 200.262 115.766L200.348 92.9206L179.082 92.8398L178.995 115.686C178.973 121.549 183.715 126.32 189.587 126.343Z" fill="#FFD1CA" />
            <path d="M179.019 109.291L192.87 102.187C192.73 103.384 191.943 108.992 187.002 112.866C183.848 115.339 180.533 115.904 179.002 116.064L179.019 109.291Z" fill="#141414" fill-opacity="0.1" />
            <path d="M200.864 85.8429C200.931 85.4975 201.842 84.9764 202.122 84.7975C202.595 84.4958 203.106 84.255 203.64 84.0827C204.678 83.7496 205.818 83.6721 206.884 83.9162C209.004 84.4018 210.884 86.1801 211.236 88.3584C211.441 89.6239 211.149 90.9323 210.606 92.094C209.789 93.8402 208.404 95.3077 206.731 96.2699C205.345 97.0671 203.767 97.5181 202.173 97.6129C201.853 97.6319 198.586 97.556 198.61 97.4315C199.362 93.5689 200.113 89.7063 200.864 85.8429Z" fill="#FFD1CA" />
            <path d="M203.78 86.0635C203.528 87.7685 202.028 96.7592 193.911 103.112C192.416 104.282 182.475 112.061 173.381 108.265C161.694 103.386 162.969 84.2209 163.051 83.1829C163.329 79.674 164.15 69.3394 172.723 63.8254C179.719 59.3255 187.3 60.9802 188.375 61.2325C189.508 61.4988 196.726 63.3168 201.142 70.3815C205.149 76.7925 204.191 83.2744 203.78 86.0635Z" fill="#FFD1CA" />
            <path d="M169.684 83.1966C169.495 84.693 168.34 85.7796 167.102 85.6246C165.864 85.4696 165.015 84.1307 165.203 82.6343C165.391 81.1379 166.546 80.0504 167.784 80.2063C169.021 80.3621 169.872 81.7002 169.684 83.1966Z" fill="#262626" />
            <path d="M183.188 84.8898C182.999 86.3862 181.844 87.4728 180.607 87.3178C179.37 87.1628 178.519 85.8239 178.708 84.3275C178.896 82.8311 180.051 81.7445 181.288 81.8995C182.525 82.0545 183.376 83.3934 183.188 84.8898Z" fill="#262626" />
            <path d="M173.761 85.4355L171.69 88.2272C171.31 88.74 171.452 89.4688 171.999 89.8011L173.9 90.9561" stroke="#262626" stroke-width="1.1112" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M187.717 91.9369C187.506 93.6155 185.971 94.8061 184.29 94.595C182.609 94.3839 181.416 92.8521 181.628 91.1735C181.839 89.4949 183.374 88.3044 185.055 88.5155C186.737 88.7265 187.929 90.2584 187.717 91.9369Z" fill="#141414" fill-opacity="0.1" />
            <path d="M202.291 93.5207C202.281 93.1843 202.252 90.9047 204.084 89.2599C205.52 87.9712 207.189 87.8872 207.645 87.8789" stroke="#262626" stroke-width="1.1112" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M203.907 89.7097C204.009 89.694 205.238 89.5233 206.095 90.4509C206.8 91.2127 206.736 92.1558 206.722 92.3125" stroke="#262626" stroke-width="1.1112" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M164.641 87.0922C164.06 87.0197 163.5 87.1236 163.002 87.3478C163.061 88.9926 163.22 90.9878 163.582 93.1042C163.68 93.1264 163.774 93.1586 163.876 93.1718C165.558 93.3828 167.093 92.1923 167.303 90.5137C167.515 88.8351 166.323 87.3033 164.641 87.0922Z" fill="#141414" fill-opacity="0.1" />
            <path d="M170.947 96.5536C171.302 96.1241 172.403 94.9023 174.308 94.4265C176.467 93.8882 178.199 94.6714 178.663 94.899" stroke="#262626" stroke-width="1.1112" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M162.096 77.9043H175.477C175.477 77.9043 175.981 73.5561 177.307 71.4134C178.634 69.2706 180.653 67.9474 180.653 67.9474C180.349 68.7149 180.003 69.7315 179.738 70.9566C179.549 71.8305 179.297 72.9897 179.312 74.501C179.312 74.501 179.317 75.0731 179.643 77.9035H191.256C190.99 77.1978 190.417 75.4004 190.91 73.1777C191.339 71.2378 192.359 69.9599 192.87 69.3967C192.477 72.876 192.918 75.6478 193.339 77.3998C194.073 80.4544 195.297 82.701 196.211 84.3788C197.089 85.9906 197.95 87.2776 198.612 88.196C198.612 88.196 200.188 86.2083 201.466 85.4357C202.744 84.6632 204.049 83.9674 204.049 83.9674C204.343 82.4479 204.606 80.1394 204.196 77.4006C203.352 71.7629 200.219 68.0793 198.91 66.7041C194.468 62.0393 189.153 60.9914 187.218 60.6385C183.088 59.8858 179.776 60.514 178.634 60.7647C177.74 60.9609 175.667 61.4762 173.253 62.7343C171.578 63.6066 166.834 66.1476 164.022 71.7143C163.356 73.031 162.485 75.1276 162.096 77.9043Z" fill="#262626" />
            <path d="M176.314 118.491C176.314 118.491 163.359 171.482 161.844 189.253C160.33 207.023 159.762 237.69 159.762 237.69L119.745 237.18C118.93 231.442 118.201 223.637 118.483 214.396C119.142 192.797 124.92 176.733 127.193 170.915C132.542 157.217 139.002 140.675 155.785 128.569C163.545 122.971 171.095 120.066 176.314 118.491Z" fill="#F2674A" />
            <path d="M149.915 151.948L133.251 237.353H143.225L149.915 151.948Z" fill="#141414" fill-opacity="0.1" />
            <path d="M152.819 136.698C151.904 141.835 150.473 150.422 149.194 161.152C147.628 174.293 147.863 177.445 145.287 211.031C144.514 221.1 143.225 237.353 143.225 237.353" stroke="white" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M189.158 218.271C190.671 219.837 191.543 221.342 192.046 222.383C193.626 225.657 193.68 228.768 193.719 230.969C193.742 232.28 193.667 233.368 193.593 234.12C193.593 234.12 174.027 227.819 171.123 228.07C168.565 228.292 155.817 231.547 152.82 232.319C152.404 232.426 151.97 232.414 151.559 232.288L147.265 230.969L143.216 231.141C141.763 231.203 140.767 229.719 141.359 228.392C141.506 228.063 141.703 227.704 141.963 227.314C143.73 224.668 145.498 222.777 145.498 222.777L139.009 223.945C138.38 223.948 137.35 223.86 136.996 223.231C136.469 222.291 137.764 220.705 138.649 219.643C140.703 217.178 142.948 215.853 143.667 215.436C146.202 213.969 148.566 212.6 152.44 211.435C155.493 210.517 163.899 207.851 174.247 210.474C181.028 212.192 186.008 215.588 189.158 218.271Z" fill="#FFD1CA" />
            <path d="M188.124 217.42C188.209 218.081 188.664 222.106 185.766 225.549C182.536 229.386 177.844 229.291 177.274 229.27L193.593 234.119C193.593 234.119 193.713 228.292 193.425 226.859C193.137 225.426 192.132 222.117 191.096 220.701C190.061 219.286 188.124 217.42 188.124 217.42Z" fill="#F2674A" />
            <path d="M147.266 230.968C149.143 228.917 152.382 225.883 157.186 223.489C161.713 221.232 165.838 220.436 168.536 220.129" stroke="#262626" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M143.216 231.141C146.176 226.101 149.453 223.479 151.872 222.02C151.872 222.02 153.659 220.943 163.044 217.42" stroke="#262626" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M145.203 222.829C147.584 219.321 150.069 217.692 151.873 216.853C155.08 215.359 159.952 214.584 159.952 214.584" stroke="#262626" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M202.291 117.497C202.291 117.497 198.2 147.851 197.632 166C197.063 184.149 196.482 215.152 196.482 215.152L189.158 218.272C189.158 218.272 192.654 228.575 192.87 231.789C193.087 235.003 192.87 237.691 192.87 237.691C203.581 237.482 212.286 237.747 218.27 238.028C223.361 238.268 230.942 238.314 246.105 238.406C251.496 238.439 256.052 238.414 261.167 235.905C261.772 235.607 266.91 233.019 270.721 227.819C273.187 224.454 277.058 217.319 274.886 199.651C273.972 192.217 270.799 168.96 252.732 146.906C242.409 134.307 232.223 128.199 229.389 126.572C220.795 121.64 213.235 119.631 211.666 119.225C207.926 118.261 204.691 117.768 202.291 117.497Z" fill="#F2674A" />
            <path d="M226.878 175.516C227.072 180.265 226.747 188.789 222.373 198.013C220.033 202.947 217.191 206.695 214.799 209.35C220.017 208.007 225.234 206.664 230.452 205.322L226.878 175.516Z" fill="#141414" fill-opacity="0.1" />
            <path d="M248.252 201.353C248.252 201.353 202.548 212.569 196.482 215.153C190.416 217.737 189.158 218.273 189.158 218.273" stroke="white" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M251.785 154.091C251.785 154.091 274.255 196.311 267.564 213.578C266.541 216.218 264.281 221.929 258.629 226.077C253.329 229.967 247.617 230.583 237.774 230.845C229.166 231.073 217.717 231.023 204.069 229.962" stroke="white" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M245.214 138.726C235.944 142.39 231.625 147.33 229.443 150.688C229.443 150.688 222.745 160.987 230.996 205.663" stroke="white" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M191.244 238.41H129.907V231.074H194.413V235.246C194.413 236.994 192.994 238.41 191.244 238.41Z" fill="#4A7AF2" />
            <path d="M136.621 238.411H51.9813C50.0465 238.411 48.2868 236.845 48.0498 234.913L40.3643 172.15C40.1281 170.218 41.5047 168.652 43.4386 168.652H128.079C130.013 168.652 131.773 170.218 132.01 172.15L139.695 234.913C139.932 236.845 138.555 238.411 136.621 238.411Z" fill="#4A7AF2" />
            <path d="M131.665 238.411H47.0263C45.0915 238.411 43.3317 236.845 43.0947 234.913L35.4092 172.15C35.173 170.218 36.5496 168.652 38.4836 168.652H123.124C125.058 168.652 126.818 170.218 127.055 172.15L134.74 234.913C134.977 236.845 133.6 238.411 131.665 238.411Z" fill="#4A7AF2" />
            <path d="M35.4092 172.15C36.022 171.982 38.2648 191.55 51.0735 209.165C53.289 212.212 57.2858 217.626 64.0754 222.902C64.0754 222.902 70.9797 228.268 96.7069 238.41C95.3204 238.41 93.9331 238.41 92.5466 238.41C89.1353 238.41 85.724 238.41 82.3119 238.41C77.9939 238.41 73.6759 238.41 69.3579 238.41C65.2521 238.41 61.1463 238.41 57.0397 238.41C54.8365 238.41 52.6342 238.412 50.431 238.411C48.6762 238.41 46.4929 238.802 44.9264 237.823C43.6299 237.014 43.0634 235.413 42.8776 233.897C42.7463 232.825 42.7612 231.74 42.6406 230.667C42.5208 229.605 42.3152 228.551 42.1856 227.488C41.9015 225.166 41.6166 222.844 41.3326 220.522C40.8676 216.724 40.4027 212.926 39.937 209.128C39.4357 205.032 38.9337 200.936 38.4324 196.841C37.9667 193.038 37.5009 189.235 37.0352 185.432C36.6776 182.513 36.3729 179.586 35.9625 176.672C35.4926 173.33 35.2598 172.19 35.4092 172.15Z" fill="#4A7AF2" />
            <path d="M38.4844 168.652H123.124C125.059 168.652 126.819 170.218 127.056 172.15L134.741 234.913" stroke="#262626" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M87.5243 201.784C87.5243 204.472 85.3417 206.652 82.6488 206.652C79.956 206.652 77.7734 204.472 77.7734 201.784C77.7734 199.095 79.956 196.916 82.6488 196.916C85.3409 196.915 87.5243 199.095 87.5243 201.784Z" fill="#FEC02D" />
            <path d="M268.196 137.706H277.594C281.72 137.706 284.974 141.208 284.666 145.316L281.797 183.455" stroke="white" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M147.688 54.7686L160.462 60.0599" stroke="white" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M147.135 40.8076L163.304 56.1384" stroke="white" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M161.183 40.5801L167.134 53.0451" stroke="white" stroke-width="0.824457" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M315.893 109.067C317.052 106.035 318.216 103.681 319.387 102.008C320.557 100.334 322.106 98.9803 324.033 97.9489C325.16 97.3173 326.338 96.8548 327.573 96.5588C328.805 96.2637 329.977 95.9001 331.086 95.4681C332.193 95.0369 333.207 94.4655 334.128 93.7565C335.048 93.0466 335.793 91.9476 336.362 90.4562C337.324 87.9383 337.302 85.9126 336.295 84.3808C335.287 82.8489 333.831 81.7203 331.927 80.9947C329.197 79.9551 327.029 79.9988 325.421 81.1225C323.811 82.2471 322.387 84.0452 321.148 86.5169L311.187 82.7228C312.149 80.2049 313.434 78.0737 315.044 76.3308C316.653 74.5887 318.492 73.2869 320.561 72.4261C322.629 71.5654 324.879 71.1565 327.311 71.1993C329.741 71.2414 332.244 71.7534 334.819 72.7337C340.379 74.8509 344.335 77.654 346.69 81.1406C349.042 84.6281 349.248 88.9153 347.304 94.0047C346.675 95.6503 345.958 97.0246 345.153 98.1311C344.346 99.2367 343.417 100.163 342.366 100.911C341.313 101.658 340.13 102.283 338.819 102.784C337.506 103.286 336.056 103.764 334.467 104.218C333 104.661 331.777 105.122 330.797 105.602C329.817 106.083 328.989 106.667 328.316 107.351C327.641 108.036 327.08 108.809 326.634 109.669C326.186 110.53 325.748 111.526 325.316 112.656L315.893 109.067ZM309.899 123.831L313.758 113.731L324.184 117.701L320.325 127.802L309.899 123.831Z" fill="white" />
            <path d="M47.0793 56.851C46.3411 54.9655 45.8902 53.3916 45.7275 52.1285C45.5648 50.8655 45.7118 49.5925 46.1685 48.3088C46.4236 47.5462 46.7729 46.8388 47.2189 46.185C47.664 45.5329 48.0521 44.8741 48.3832 44.2104C48.7135 43.5476 48.9423 42.8592 49.0694 42.1468C49.1958 41.4345 49.0785 40.615 48.7152 39.6875C48.1016 38.1218 47.2412 37.196 46.1321 36.9099C45.0231 36.6238 43.8769 36.7128 42.6936 37.1754C40.9966 37.8382 40.0131 38.7657 39.7423 39.9579C39.4714 41.1509 39.5688 42.5772 40.0346 44.2376L33.8412 46.6558C33.2277 45.0901 32.9263 43.5682 32.9378 42.0899C32.9494 40.6125 33.2516 39.2414 33.8454 37.9775C34.4391 36.7145 35.3062 35.5833 36.4466 34.5849C37.5861 33.5873 38.9569 32.7752 40.5581 32.1503C44.0148 30.8006 47.019 30.4387 49.5707 31.0628C52.1215 31.6886 54.0175 33.5824 55.2562 36.7458C55.6567 37.769 55.9028 38.7039 55.9961 39.5523C56.0877 40.4006 56.0481 41.2177 55.8763 42.0034C55.7038 42.7891 55.4205 43.5723 55.025 44.3531C54.6294 45.1347 54.1604 45.9616 53.6178 46.8372C53.1265 47.6558 52.7557 48.3814 52.5047 49.0129C52.2545 49.6453 52.1166 50.2611 52.0934 50.8589C52.0695 51.4583 52.1356 52.0502 52.29 52.6331C52.4444 53.2176 52.6608 53.8615 52.9357 54.564L47.0793 56.851ZM50.5113 66.1781L48.0513 59.899L54.5328 57.368L56.9928 63.647L50.5113 66.1781Z" fill="white" />
            <path d="M336.595 237.352H310.25L308.492 209.35H338.352L336.595 237.352Z" fill="#4EDF6D" fill-opacity="0.2" />
            <path d="M334.779 209.349H312.066V185.476C312.066 179.214 317.151 174.138 323.423 174.138C329.694 174.138 334.779 179.214 334.779 185.476V209.349Z" fill="#4EDF6D" />
            <mask id="mask0_578_8220" maskUnits="userSpaceOnUse" x="312" y="174" width="23" height="36">
              <path d="M334.779 209.349H312.066V185.476C312.066 179.214 317.151 174.138 323.423 174.138C329.694 174.138 334.779 179.214 334.779 185.476V209.349Z" fill="white" />
            </mask>
            <g mask="url(#mask0_578_8220)">
              <path d="M316.215 177.594C316.215 180.768 314.307 180.768 314.307 183.943C314.307 187.119 316.215 187.119 316.215 190.294C316.215 193.468 314.307 193.468 314.307 196.643C314.307 199.819 316.215 199.819 316.215 202.996C316.215 206.173 314.307 206.173 314.307 209.349" stroke="#262626" stroke-width="0.412228" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M321.21 174.612C321.21 178.085 319.122 178.085 319.122 181.558C319.122 185.031 321.21 185.031 321.21 188.505C321.21 191.978 319.122 191.978 319.122 195.45C319.122 198.926 321.21 198.926 321.21 202.401C321.21 205.876 319.122 205.876 319.122 209.351" stroke="#262626" stroke-width="0.412228" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M328.558 174.9C328.558 178.344 326.487 178.344 326.487 181.788C326.487 185.232 328.558 185.232 328.558 188.678C328.558 192.122 326.487 192.122 326.487 195.565C326.487 199.012 328.558 199.012 328.558 202.458C328.558 205.904 326.487 205.904 326.487 209.35" stroke="#262626" stroke-width="0.412228" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M333.691 179.053C333.691 182.081 331.87 182.081 331.87 185.11C331.87 188.14 333.691 188.14 333.691 191.169C333.691 194.197 331.87 194.197 331.87 197.226C331.87 200.257 333.691 200.257 333.691 203.288C333.691 206.318 331.87 206.318 331.87 209.349" stroke="#262626" stroke-width="0.412228" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <path d="M338.352 209.35L336.595 237.352H310.25C313.769 236.4 320.693 234.016 327.188 227.916C334.669 220.891 337.383 212.881 338.352 209.35Z" fill="#4EDF6D" fill-opacity="0.1" />
            <path d="M336.841 213.8C335.772 216.454 333.967 220.107 330.925 223.881C327.886 227.652 324.701 230.192 322.334 231.803" stroke="white" stroke-width="0.412228" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign="center"
            className="text-white"
            my="20px"
          >
            Are you sure?
          </Typography>
          <Typography
            variant="subtitle1"
            textAlign="center"
            className="text-white"
            px="85px"
          >
            Do you really want to delete this preference?
          </Typography>
        </DialogContent>

        {/* Dialog Actions */}
        <DialogActions
          sx={{
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <Button
            variant="outlined"
            className="btn"
            sx={{
              color: "#F2674A",
              borderColor: "#F2674A",
              padding: "12px 50px",
              fontSize: "16px",
              "&:hover": {
                color: "#F2674A !important",
                borderColor: "#F2674A !important",
              },
            }}
            onClick={confirmCancelOrder}
          >
            Yes, cancel it!
          </Button>
          <Button
            onClick={() => setDialogOpen(false)}
            variant="contained"
            color="primary"
            className="btn-primary btn"
            sx={{
              padding: "12px 50px",
              fontSize: "16px",
            }}
          >
            No, keep it
          </Button>
        </DialogActions>
      </Dialog>

    </TableContainer>
  );
};

export default OpenOrder;