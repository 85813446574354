import React, { useEffect, useState } from "react";
import { Container, Typography, Card, Box, Grid, CardContent, CircularProgress } from "@mui/material";
import axios from "../../../config/axios";
import { UrlConstant } from "../../../commom/UrlConstant";

// Define interface for props
interface HistoryTopupProps {
    isrepayment: string;
}

interface TopUpData {
    _id: string;
    transactionNumber: number;
    amount: number;
    scheduled_date: string;
    display_status: string;
    topUp: {
        name: string;
        total_amount: number;
        total_installments: number;
    };
}

const HistoryTopup: React.FC<HistoryTopupProps> = ({ isrepayment }) => {
    const [topUps, setTopUps] = useState<TopUpData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchTopUps = async () => {
            try {
                const response = await axios.get(UrlConstant.AutoTopup.HistoryTopup);
                setTopUps(response.data.data); // Update state with the fetched data
            } catch (error) {
                console.error("Error fetching top-ups:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }
        };

        fetchTopUps();
    }, []);

    return (
        <Container
            maxWidth={false}
            className="bg-color"
            style={{
                padding: "30px",
                overflow: 'auto',
                maxHeight: '59vh',
                position: 'relative',
                minHeight:'calc(100vh - 493px)' 
            }}
        >
            {/* Show the loading spinner */}
            {loading && (
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%" // Full screen overlay
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                        backgroundColor: "bg-color", // Dark background during loading
                        zIndex: 10, // Ensure the spinner is on top of content
                    }}
                >
                    <CircularProgress style={{ color: "#F2674A" }} />
                </Box>
            )}

            {/* Content display */}
            <Box display="flex" gap="20px"  flexWrap="wrap" justifyContent="space-between">
                {loading ? (
                    <Typography color="white" minHeight='calc(100vh - 553px)'></Typography>
                ) : Array.isArray(topUps) && topUps.length > 0 ? (
                    topUps.map((topUp) => {
                        const scheduledDate = new Date(topUp.scheduled_date);
                        const month = scheduledDate.toLocaleString("default", { month: "short" });
                        const day = scheduledDate.getDate();

                        return (
                            <Box key={topUp._id} width="48%">
                                <Card
                                    sx={{
                                        marginBottom: "20px",
                                        padding: "20px 30px",
                                        height: "113px",
                                        border: "1px solid #FFFFFF2A",
                                        bgcolor: "#FFFFFF2A",
                                        borderRadius: "20px",
                                    }}
                                >
                                    <CardContent sx={{ padding: "0px" }}>
                                        <Grid container alignItems="center">
                                            {/* Left Section - Date */}
                                            <Grid
                                                item
                                                sx={{
                                                    marginRight: "10px",
                                                    height: "73px",
                                                    padding: "14px 0px",
                                                    textAlign: "center",
                                                    width: "73px",
                                                    borderRadius: "50px",
                                                    bgcolor: "#FFFFFF1A",
                                                }}
                                            >
                                                <Typography
                                                    fontSize="24px"
                                                    lineHeight="24px"
                                                    fontWeight="550"
                                                    sx={{ marginBottom: "0px", color: "#F2674A" }}
                                                >
                                                    {day}
                                                </Typography>
                                                <Typography sx={{ color: "white" }}>{month}</Typography>
                                            </Grid>
                                            {/* Middle Section - Name and Details */}
                                            <Grid item xs={8}>
                                                <Typography
                                                    fontSize="24px"
                                                    className="text-white"
                                                    fontWeight="550"
                                                >
                                                    {topUp.topUp.name}
                                                </Typography>
                                                <Typography fontSize="16px" className="text-white">
                                                    Topup {topUp.transactionNumber} of{" "}
                                                    {topUp.topUp.total_installments} |{" "}
                                                    <span style={{ color: "#4A7AF2" }}>
                                                        {scheduledDate.toLocaleDateString("en-US", {
                                                            weekday: "long",
                                                        })}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            {/* Right Section - Amount */}
                                            <Grid
                                                item
                                                xs={2}
                                                color="#4EDF6D"
                                                textAlign="end"
                                                fontWeight="bold"
                                                sx={{ fontSize: "28px" }}
                                            >
                                                ${topUp.amount.toFixed(2)}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        );
                    })
                ) : (
                    <Typography color="white" textAlign="center">
                        No History found.
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default HistoryTopup;
