import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { formatPrice } from "../../../Utils/utils";

interface TotalFundsProps {
  series: { label: string; percentage: number; value: number }[]; // Updated type for `series`
  labels: string[];
  colors?: string[]; // Optional colors for slices
}

const DonutChart: React.FC<TotalFundsProps> = ({ series }) => {
  const [tooltipData, setTooltipData] = useState<{
    x: number;
    y: number;
    number: number | null;
    percentage: string | null;
    name: string | null;
  }>({ x: 0, y: 0, number: null, name: null, percentage: null });

  const colors = ["#fdbd1f", "#FF6F61", "#F7933D"];
  const data = series.length > 0
    ? series.map((item, index) => ({
      name: item.label,
      value: parseFloat(item.percentage?.toFixed(2)),
      number: parseFloat(item.value?.toFixed(2)) || 0,
      color: colors?.[index] || `hsl(${index * 30}, 70%, 50%)`, // Fallback to dynamic color
    }))
    : [
      {
        name: "No Data",
        value: 100,
        number: 0,
        color: "#ccc", // Gray color for "No Data"
      },
    ];
  const totalNumber = data.reduce((sum, entry) => sum + entry.number, 0); // Calculate total
  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name,
    percent,
    number,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) / 1.2;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    const percentage = `${Math.round(percent * 100)}%`;
    let zIndex = 0;
    const handleMouseEnter = (e: React.MouseEvent<SVGTextElement>) => {
      setTooltipData({
        x: x,
        y: y,
        number: number,
        name: name,
        percentage: percentage,
      });
      zIndex = 100;
    };

    const handleMouseLeave = () => {
      setTooltipData({ x: 0, y: 0, number: null, name: null, percentage: null });
    };

    const circleRadius = 40; // Increase this for a bigger circle
    if (percent === 0) return null;
    if (name == "No Data") return null
    return (
      <g
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ zIndex: zIndex }}
      >
        <circle
          cx={x}
          cy={y}
          r={circleRadius}
          fill="white"
          filter="url(#shadow)"
        />
        <text
          x={x}
          y={y - 5} // Adjust text position above the center of the circle
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="12"
          fontWeight="bold"
          fill="#333"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {percentage}
        </text>
        <text
          x={x}
          y={y + 10} // Adjust text position below the percentage
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="10"
          fill="#555"
          fontWeight="bold"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ width: "50px" }}
        >
          {name.split(" ").map((word, i) => (
            <tspan x={x} dy={i === 0 ? "0em" : "1.2em"} key={i}>
              {word}
            </tspan>
          ))}
        </text>
      </g>
    );
  };


  return (
    <div
      style={{
        width: "100%",
        height: "350px", // Fixed height for consistent rendering
        maxWidth: "400px",
        margin: "auto",
        position: "relative",
      }}
    >
      {tooltipData.number !== null && (
        <div
          style={{
            position: "absolute",
            left: tooltipData.x,
            top: tooltipData.y,
            zIndex: 100,
            backgroundColor: "#fff",
            padding: "8px 12px",
            borderRadius: "4px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
            pointerEvents: "none",
            transform: "translate(-50%, -120%)",
            color: "#333",
          }}
        >
          {tooltipData.name}: <br /> ${formatPrice(tooltipData.number)} ({tooltipData.percentage})
        </div>
      )}
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <defs>
            <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow dx="2" dy="2" stdDeviation="2" floodColor="#888" />
            </filter>
          </defs>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius="85%"
            innerRadius="45%"
            fill="#8884d8"
            label={renderCustomLabel}
            labelLine={false}
            startAngle={90}
            endAngle={-270}
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {/* Add total in the center */}
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="20"
            fontWeight="bold"
            fill="#fff"
          >
            {totalNumber ? `$${formatPrice(totalNumber)}` : "No Data"}
          </text>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DonutChart;
