import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { endLoading, startLoading } from "../../../../Redux/loadingSlice";
import { Constant } from "../../../../commom/Constants";
import { UrlConstant } from "../../../../commom/UrlConstant";
import Axios from "../../../../config/axios";
import Swal from "sweetalert2";
import Successpopup from "../../SharedComponent/Successpopup";
import { truncate } from "fs/promises";

function DocumentUpload() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [investigationList, setInvestigationList] = useState<any[]>([]);
  const [investigationType, setInvestigationType] = useState("");
  const [uploadedDoc, setUplodedDoc] = useState([]);
  const [uploadedDocBackup, setUplodedDocBackup] = useState<any>([]);
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility



  const [invId, setInvId] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const [documentsData, setDocumentsData] = useState<any[]>([]);

  const fileInputRefs = useRef<any>([]);

  useEffect(() => {
    const data = localStorage.getItem("InvList");
    setUplodedDocBackup(data && data !== null ? JSON.parse(data) : []);
    getInvestigation();
  }, []);

  const checkAccountStatus = async () => {
    try {
      const response = await Axios.get(
        UrlConstant.TradingAccount.CheckAccountStatus
      );
      const resData = response.data;
      console.log("AC Status : ", resData);
      if (resData.status === true) {
        const applicationId = resData.data.applicationId;
        if (
          resData.data.status !== "" &&
          resData.data.isDocumentUploadRequired === false &&
          applicationId &&
          applicationId !== ""
        ) {
          // Swal.fire("Document Submitted!")
          setheading('Success')
          setSuccess(true)
          setShowPopup(true)
          setmsg('Document Submitted!')
          // .then(() => {
          navigation("/dashboard");
          // });
        }
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (index) => {
    setLoading(true);
    setDocumentsData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], file: null }; // Clear the file
      return newData;
    });

    const newInvList: any = [];
    investigationList.forEach((inv: any, i) => {
      if (inv.requestedDocuments) {
        const docType = inv.requestedDocuments.find(
          (e: any) => e === documentsData[index]?.documentType && index !== i
        );

        console.log("docType : ", docType);

        if (docType) {
          const newInv = {
            ...investigationList[i],
            isFile: false,
            file: null,
          };
          console.log("i ==> ", investigationList[i]);
          newInvList.push(newInv);
        } else {
          newInvList.push(investigationList[i]);
        }
      }
    });
    console.log("investigationList 2 : ", newInvList);
    setInvestigationList(newInvList);

    setLoading(false);
  };
  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setLoading(true);
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const updatedDocs = [...documentsData];
        updatedDocs[index] = {
          ...updatedDocs[index],
          file: file,
        };
        setDocumentsData(updatedDocs);
      };
      reader.readAsDataURL(file);
    }
    setLoading(false);
  };

  const handleDropdownChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const documentType = event.target.value;
    const updatedDocs = [...documentsData];
    updatedDocs[index] = {
      ...updatedDocs[index],
      documentType: documentType,
    };
    setDocumentsData(updatedDocs);

    investigationList.forEach((inv: any, i) => {
      console.log("INV : ", inv);
      if (inv.requestedDocuments) {
        const docType = inv.requestedDocuments.find(
          (e: any) => e === documentType && index !== i
        );
        console.log("inv : ", inv);
        if (docType) {
          updatedDocs[i] = {
            ...updatedDocs[i],
            documentType: docType,
          };
          setDocumentsData(updatedDocs);
        }
      }
    });
  };

  const getUploadedDocument = (docTypeList, index) => {
    const docList = uploadedDocBackup;
    if (uploadedDoc !== null) {
      const newList: any = [];
      uploadedDoc.forEach((element: any) => {
        const check = docList.find(
          (e) =>
            e.selectedDocType === element.documentName &&
            e.investigationId === index &&
            docTypeList.includes(element.documentName)
        );
        if (check) {
          newList.push(element);
        }
      });
      console.log("NEW List : ", newList);
      return newList;
    }
    console.log("EMPTY List : ");

    return [];
  };

  const handleUploadClick = (index: number) => {
    fileInputRefs.current[index]?.click();
  };

  const getInvestigation = async () => {
    dispatch(startLoading());
    setLoading(true);
    try {
      const response = await Axios.get(
        `${UrlConstant.TradingAccount.GetInvestigation}`
      );
      const resData = response.data;

      if (resData.status === true) {
        if (resData.data.result.Payload.applications !== null) {
          setInvestigationType(Constant.APPLICATION);
          setInvestigationList(
            resData.data.result.Payload.applications[0].appCategories.filter(
              (e: any) => e.requestedDocuments !== null
            )
          );
          setUplodedDoc(
            resData.data.result.Payload.applications[0].uploadedDocuments
          );
          setInvId(resData.data.result.Payload.applications[0].accountHolderId);
          console.log(investigationList);
        } else {
          setInvestigationType(Constant.INVESTIGATIONS);
          setInvestigationList(
            resData.data.result.Payload.investigations[0].cipCategories.filter(
              (e: any) => e.requestedDocuments !== null
            )
          );
          setUplodedDoc(
            resData.data.result.Payload.investigations[0].uploadedDocuments
          );
          setInvId(
            resData.data.result.Payload.investigations[0].investigationId
          );
        }
      }
    } catch (error: any) {
      console.error("Error: ", error);
    } finally {
      dispatch(endLoading());

      checkAccountStatus();
      // setLoading(false);
    }
  };

  const handleUploadFile = async (index: number) => {
    const document = documentsData[index];
    if (!document?.file) {
      return;
    }
    if (document.documentType === undefined || document.documentType === null) {
      // Swal.fire("Select document type");
      setShowPopup(true)
      setmsg('Select document type')

      return;
    }

    const formData = new FormData();
    formData.append("file", document.file);

    formData.append("documentType", document.documentType);
    if (investigationType === Constant.APPLICATION) {
      formData.append("accountHolderId", invId.toString());
    } else {
      formData.append("investigationId", invId.toString());
    }

    try {
      setLoading(true);
      const response = await Axios.post(
        UrlConstant.TradingAccount.SubmitDocument,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Upload Response : ", response);
      if ((await response.status) == 200) {
        if (fileInputRefs.current[index]) {
          fileInputRefs.current[index].value = null; // Reset input value
        }
        investigationList.forEach((element, i) => {
          if (
            element.isResolved === false &&
            element.requestedDocuments.includes(document.documentType)
          ) {
            uploadedDocBackup.push({
              investigationId: i,
              selectedDocType: document.documentType,
            });
          }
        });
        localStorage.setItem("InvList", JSON.stringify(uploadedDocBackup));
      } else {
        // Swal.fire(`File for ${document.documentType} upload failed.`);
        setShowPopup(true)
        setmsg(`File for ${document.documentType} upload failed`)

      }
      await getInvestigation();
    } catch (error: any) {
      console.log("EROR : ", error);
      if (error.response && error.response.status === 413) {
        // Swal.fire(
        //   `File for ${document.documentType} is too large. Please upload a smaller file.`
        // );
        setShowPopup(true)
        setmsg(`File for ${document.documentType} is too large. Please upload a smaller file.`)
      } else {
        // Swal.fire(`File for ${document.documentType} upload failed.`);
        setShowPopup(true)
        setmsg(`File for ${document.documentType} upload failed.`)

      }
    } finally {
      setLoading(false);
    }
  };
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };

  return (
    <Box className="bg-color" display="flex">
      <Container
        maxWidth={false}
        className="bg-color"
        sx={{
          minHeight: "calc(100vh -  71px - 143px)",
          width: "100%",
          padding: "30px !important",
        }}
      >
        <Typography className="text-secondary" sx={{ marginBottom: "20px" }}>
          <span
            onClick={() => navigation(-1)}
            style={{ color: "#4A7AF2", cursor: "pointer" }}
          >
            Dashboard{" "}
          </span>{" "}
          / Document Upload
        </Typography>

        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
            bgcolor="transparent"
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box display="flex" flexWrap="wrap" gap="20px" alignItems="center">
            {investigationList.map((item: any, index: number) => (
              <Card
                key={index}
                className="card"
                sx={{
                  // minWidth: "685px",
                  width: "calc(50% - 10px)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  padding: "20px !important",
                }}
              >
                <CardContent
                  className="text-white"
                  sx={{ padding: "0px !important" }}
                >
                  {!item.isResolved && (
                    <>
                      <Box>
                        <Typography variant="h6" mb="20px">
                          Proof of{" "}
                          {item.name === "DateOfBirth"
                            ? "Date of Birth"
                            : item.name === "TaxId"
                              ? "Tax Identification"
                              : item.name}{" "}
                          is Required
                        </Typography>
                      </Box>

                      <Box>
                        <Typography className="text-secondary" mb="10px">
                          Select Document you plan to upload*
                        </Typography>
                      </Box>

                      <Box>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="text-white"
                          sx={{
                            borderRadius: "50px !important",
                            width: "100%",
                            height: "48px",
                            padding: "0px 10px !important",
                            mb: "10px",
                          }}
                          disabled={item.isFile}
                          value={documentsData[index]?.documentType || ""}
                          onChange={(event: any) =>
                            handleDropdownChange(event, index)
                          }
                        >
                          {item.requestedDocuments.map(
                            (doc: any, index: number) => (
                              <MenuItem key={index} value={doc}>
                                {doc}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </Box>
                    </>
                  )}

                  {item.file && item.isFile && (
                    <Box>
                      <Typography className="text-secondary" mb="10px">
                        Upload Here
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className="text-secondary"
                        mb="10px"
                      >
                        Only .pdf file supported
                      </Typography>
                      <Box
                        width="100%"
                        height="230px"
                        mb="10px"
                        sx={{
                          border: "2px dashed #FFFFFF33",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "16px",
                          backgroundColor: "transparent",
                          color: "#FFFFFF",
                          position: "relative",
                        }}
                      >
                        {/* <Box
                          // onClick={() => handleUploadClick(index)}
                          component="img"
                          src={URL.createObjectURL(item.file)}
                          alt="Selected Preview"
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "16px",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 1,
                          }}
                        /> */}

                        <embed
                          src={URL.createObjectURL(item.file)}
                          type="application/pdf"
                          width="100%"
                          height="100%"
                          style={{
                            borderRadius: "16px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 1,
                          }}
                        />

                        <Typography
                          display="flex"
                          flexDirection="column"
                          gap="10px"
                          alignItems="center"
                        >
                          <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.6367 13.0009L23.7567 7.88086L28.8767 13.0009"
                              stroke="white"
                              stroke-width="3"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M23.7656 28.3605V8.02051"
                              stroke="white"
                              stroke-width="3"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.00195 24C8.00195 32.84 14.002 40 24.002 40C34.0019 40 40.0019 32.84 40.0019 24"
                              stroke="white"
                              stroke-width="3"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <span
                            onClick={() => handleUploadClick(index)}
                            style={{ cursor: "pointer" }}
                          >
                            Upload Here
                          </span>
                          <Typography
                            variant="subtitle2"
                            className="text-secondary"
                            mb="10px"
                          >
                            Only .pdf file supported
                          </Typography>
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        color="primary"
                        className="btn-primary btn "
                        sx={{
                          padding: "13px 54px",
                          fontSize: "16px",
                          marginLeft: "75%",
                        }}
                        // style={{ width: "100px", marginLeft:'auto !important' }}
                        onClick={() => handleUploadFile(index)} // Submit only this file
                      >
                        Submit
                      </Button>
                    </Box>
                  )}
                  {!item.isResolved &&
                    (item.file === undefined || item.file === null) && (
                      <Box>
                        <Typography className="text-secondary" mb="10px">
                          Upload Here
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className="text-secondary"
                          mb="10px"
                        >
                          Only .pdf file supported
                        </Typography>

                        <Box
                          width="100%"
                          height="230px"
                          mb="10px"
                          sx={{
                            border: "2px dashed #FFFFFF33",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "16px",
                            backgroundColor: "transparent",
                            color: "#FFFFFF",
                            position: "relative",
                          }}
                        >
                          {documentsData[index]?.file && (
                            <Box
                              onClick={() => {
                                handleClose(index);

                                if (fileInputRefs.current[index]) {
                                  fileInputRefs.current[index].value = null; // Reset input value
                                }
                              }}
                              sx={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                cursor: "pointer",
                                backgroundColor: "rgb(255, 255, 255)",
                                borderRadius: "50%",
                                padding: "5px",
                                zIndex: 99999,
                              }}
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 1L15 15M15 1L1 15"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Box>
                          )}

                          <input
                            ref={(el) => (fileInputRefs.current[index] = el)}
                            type="file"
                            accept="application/pdf, image/*" // Accept both PDFs and images
                            style={{ display: "none" }}
                            onChange={(event) => handleFileSelect(event, index)}
                          />

                          {documentsData[index]?.file && (
                            // Check the file type and render either an image or PDF preview
                            <>
                              {documentsData[index]?.file.type ===
                                "application/pdf" ? (
                                <embed
                                  src={URL.createObjectURL(
                                    documentsData[index]?.file
                                  )}
                                  type="application/pdf"
                                  width="100%"
                                  height="100%"
                                  style={{
                                    borderRadius: "16px",
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    zIndex: 1,
                                  }}
                                />
                              ) : (
                                <Box
                                  component="img"
                                  src={URL.createObjectURL(
                                    documentsData[index]?.file
                                  )}
                                  alt="Selected Preview"
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "16px",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    zIndex: 1,
                                  }}
                                />
                              )}
                            </>
                          )}

                          <Typography
                            display="flex"
                            flexDirection="column"
                            gap="10px"
                            alignItems="center"
                          >
                            <svg
                              width="48"
                              height="48"
                              viewBox="0 0 48 48"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.6367 13.0009L23.7567 7.88086L28.8767 13.0009"
                                stroke="white"
                                strokeWidth="3"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M23.7656 28.3605V8.02051"
                                stroke="white"
                                strokeWidth="3"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.00195 24C8.00195 32.84 14.002 40 24.002 40C34.0019 40 40.0019 32.84 40.0019 24"
                                stroke="white"
                                strokeWidth="3"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>

                            <span
                              onClick={() => handleUploadClick(index)}
                              style={{ cursor: "pointer" }}
                            >
                              Upload Here
                            </span>
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            className="text-secondary"
                            mb="10px"
                          >
                            Only .pdf and image files supported
                          </Typography>
                        </Box>

                        <Button
                          disabled={item.isFile}
                          variant="contained"
                          color="primary"
                          className="btn-primary btn "
                          sx={{
                            padding: "13px 54px",
                            fontSize: "16px",
                            marginLeft: "75%",
                          }}
                          onClick={() => handleUploadFile(index)}
                        >
                          Submit
                        </Button>
                      </Box>
                    )}
                  <Box>
                    {getUploadedDocument(item.requestedDocuments, index).map(
                      (doc: any, index: number) => (
                        <Box height="500px" padding="30px">
                          <Box key={index}>
                            <Typography
                              variant="h6"
                              mb="30px"
                              className="text-secondary"
                              key={index}
                            >
                              Document Uploaded For:
                              <Typography
                                fontWeight="bold"
                                fontSize="28px"
                                className="text-white"
                              >
                                Proof of{" "}
                                {item.name === "DateOfBirth"
                                  ? "Date of Birth"
                                  : item.name === "TaxId"
                                    ? "Tax Identification"
                                    : item.name}{" "}
                              </Typography>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              mb="30px"
                              className="text-secondary"
                              key={index}
                            >
                              Document Type:
                              <Typography
                                fontWeight="bold"
                                fontSize="28px"
                                className="text-white"
                              >
                                {doc.description}
                              </Typography>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              mb="30px"
                              className="text-secondary"
                              key={index}
                            >
                              Status:
                              <Typography
                                fontWeight="bold"
                                fontSize="28px"
                                className="text-white"
                              >
                                {doc.state}
                              </Typography>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              mb="30px"
                              className="text-secondary"
                              key={index}
                            >
                              Date & Time:
                              <Typography
                                fontWeight="bold"
                                fontSize="28px"
                                className="text-white"
                              >
                                {doc.takenOn}
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                      )
                    )}
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}
      </Container>
      {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

    </Box>
  );
}

export default DocumentUpload;
