import { Avatar, Container, Box, CardContent, Typography, IconButton, Card, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import { CommonDataTable } from "../SharedComponent/CommonDataTable";
import Yourinvestment from "../Cards/Yourinvestment";
import TotalFunds from "../charts/TotalFund";
import { BookmarkAddOutlined, BookmarkBorderOutlined, BookmarkOutlined, Height, Padding } from "@mui/icons-material";
import { formatPrice, getCurrentUserId } from "../../../Utils/utils";
import Swal from "sweetalert2";
import RightSideSection from "../SharedComponent/RightSideSection";
import Successpopup from "../SharedComponent/Successpopup";
function WatchList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const { source } = location.state || {};
  const breadcrumbPath = source === "moreOptions" ? "More Options" : "Dashboard";
  // const [setPage] = useState(1);
  const [page, setPage] = useState(1);
  const [rowperpage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalRows] = useState(10);
  const [setSearch] = useState("");
  const [added, setadded] = useState(false);
  const listitem = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6]
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility



  console.log(page, rowperpage);

  const navigateToDetail = async (data) => {
    //stockdetail
    navigation("/stockdetail", {
      state: {
        symbol: data.symbol,
        title: "Watchlist",
        icon: data.branding?.icon_url,
      },
    });


  };
  const getWatchList = async () => {
    dispatch(startLoading());
    setLoading(true);
    try {
      const response = await Axios.get(UrlConstant.WachList.GetWatchList);
      const resData = response.data;
      if (resData.result === true) {
        const list = resData.data.map((item) => {
          const v1 = item.regularMarketPrice ?? 0;
          const v2 = item.Last ?? 0;
          const diffrence = v2 - v1;
          const div = diffrence / v1;
          const res = div * 100;

          const lastPrice = parseFloat(item?.Last || "0.00");
          const previousClose = parseFloat(item?.PreviousClose || "0.00");
          const diffAmount = lastPrice - previousClose;
          const percentage = (diffAmount * 100.0) / previousClose;



          const itemDetail = {
            displayName: item.displayName,
            stockPrice: item.Last,
            res: res,
            diffrence: diffrence,
            branding: item.branding,
            symbol: item.symbol,
            lastPrice: lastPrice,
            previousClose: previousClose,
            diffAmount: diffAmount,
            percentage: percentage,
            changeAmount: item.changeAmount,
          };

          return itemDetail;
        });
        setRows(list);
        setLoading(false);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
      setLoading(false);
    } finally {
      dispatch(endLoading());
    }
  };



  const RemoveWatchList = async (symbol) => {

    try {
      const response = await Axios.delete(UrlConstant.WachList.RemoveWatchList.replace("{0}", symbol));
      const resData = response.data;
      if (resData.result === true) {
        getWatchList()
        // Swal.fire("Success", resData.msg, "success");
        setheading('Success')
        setSuccess(true)
        setShowPopup(true)
        setmsg(resData.msg)
      }
      console.log("Success:", resData.msg); // Handle success message or response
    } catch (error) {
      console.error("Error while removing from watchlist:", error);
    } finally {
      dispatch(endLoading());
    }
  };



  useEffect(() => {
    getWatchList();
  }, []);
  //  console.log(rows)
  rows.map(item => {
    console.log(item)
  })
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };
  return (
    <Box
      width='100%'
      className="bg-color "

      sx={{
        display: 'flex',
        minHeight: 'calc(100vh - 251px)',
        height: 'fit-content',
        padding: '30px'
      }}
    >
      <Container style={{ left: "0", height: '100%' }}>

        <Typography
          className="text-secondary"
          sx={{ marginBottom: "20px" }}
        >
          {" "}
          <span
            onClick={() => navigation(-1)}
            style={{ color: "#4A7AF2", cursor: "pointer" }}
          >
            {breadcrumbPath}{" "}
          </span>{" "}
          / Watchlist
        </Typography>
        <Box marginBottom='20px'>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2} alignItems="center" mb={1}>
              <Typography
                className="text-white"
                mb={0}
                style={{ fontSize: "1.5rem" }}
              >
                Watchlist
              </Typography>
            </Box>
          </Box>
        </Box>
        <Row className="dark-tbl no-border">
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
              bgcolor="transparent"
            >
              <CircularProgress color="primary" />
            </Box>
          ) : rows.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
              bgcolor="transparent"
            >
              <Typography
                className="text-white"
                sx={{ fontSize: '18px', fontWeight: '700' }}
              >
                Create a personalized watchlist to easily track your favourite content.
              </Typography>
            </Box>
          ) : (
            <Box display="flex" flexWrap="wrap" gap="10px">
              {rows.map((item: any, index: number) => (
                <Card
                  key={index}
                  className="card"
                  sx={{
                    width: '200px', // Responsive widths
                    // height: '116px', // Ensures consistent height
                    display: 'flex',
                    backgroundColor: '#FFFFFF1A !important',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    padding: '15px 20px !important',
                  }}
                >
                  <CardContent className="text-white" sx={{ padding: '0px !important' }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb="10px">
                      <Box display="flex" maxWidth="70%" alignItems="center" gap="10px">
                        <Avatar
                          alt={item?.displayName}
                          src={item?.branding?.icon_url}
                          sx={{ width: 32, height: 32 }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: 'hidden',
                            maxWidth: '90px',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            fontWeight: 'bold',
                          }}
                          fontWeight={600}
                          onClick={() => {
                            navigateToDetail(item);
                          }}
                        >
                          {item?.displayName}
                        </Typography>
                      </Box>
                      <IconButton
                        sx={{
                          borderRadius: '10px',
                          paddingRight: '0px',
                          padding: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          bgcolor: '#4A7AF233',
                          "&:hover": {
                            bgcolor: '#4A7AF233',

                          }
                        }}
                      >
                        <BookmarkOutlined
                          style={{ color: '#4A7AF2' }}
                          onClick={() => {
                            RemoveWatchList(item.symbol);
                          }}
                        />
                      </IconButton>
                    </Box>
                    <Typography variant="body1" fontWeight={600}>
                      ${formatPrice(item?.lastPrice)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: item.diffAmount > 0 ? '#4edf6d' : '#ff2a2a' }}
                    >
                      {item.changeAmount > 0 ? '+$' : '-$'}
                      {Math.abs(Number(formatPrice(item.changeAmount)))}
                      ({formatPrice(item.percentage)}%)
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </Row>
      </Container>

      <Container style={{ width: '440px', padding: '0px' }}>
        <RightSideSection />
      </Container>
      {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

    </Box>
  );
}
export default WatchList;
