import React from 'react'
import { Divider, Typography, Box } from '@mui/material'

function Footer() {
  return (
    <Box height='180px'>
        <Divider sx={{ border: '1px solid #FFFFFF33' }}></Divider>
        <Typography my='20px' fontSize='12px' padding='0px 50px' className='text-white' textAlign='center'>
        DISCLAIMER: This data is for informational purposes only and should not be relied upon for trading or tax-related decisions. Any use of this data to buy or sell stocks or ETFs is at your own risk. The data may not be copied, reproduced, distributed, displayed, sold, or otherwise exploited in any form. It is sourced from third-party providers and may be subject to delays. Wolfpack Financial Inc. makes no guarantees regarding the reliability, accuracy, or completeness of third-party materials and accepts no liability for any errors or omissions in such materials. For detailed information on specific stocks and third-party disclosures, please visit Wolfpack’s website. Wolfpack Financial Inc., along with its subsidiaries, affiliates, partners, directors, officers, and staff, is not responsible for any actions taken based on the information provided through our services. Always consult a qualified professional before making financial decisions.
        </Typography>        
        <Divider sx={{ border: '1px solid #FFFFFF33' }}></Divider>
        <Typography mt='20px' fontSize='12px' className='text-white' textAlign='center'>&#169; 2025 Wolfpack Financial Inc. All rights reserved. &nbsp; | &nbsp; Securities Products and Services are provided by TradingBlock. Member FINRA | SIPC.</Typography>
    </Box>
  )
}

export default Footer
